import React, { memo } from "react";
import { Button } from "../../StyledComponents";

function AvailityLastUpdatation({ PastDataOfAvaility }) {
  // console.log(PastDataOfAvaility, "PastDataOfAvaility");

  function parseFileDescription(description) {
    if (typeof description !== "string") {
      throw new TypeError("The description must be a string");
    }

    // Split the description by new lines
    const lines = description?.split("\n").filter((line) => line.trim() !== "");

    // Create an object to hold the parsed results
    const result = {};

    lines.forEach((line) => {
      // Split each line by the colon and trim whitespace
      const [key, value] = line.split(":").map((part) => part.trim());
      if (key && value) {
        result[key] = value;
      }
    });

    return result;
  }

  // Parse the file_description
  //   const parsedDescription = parseFileDescription(PastDataOfAvaility.file_decription);
  let parsedDescription;
  try {
    parsedDescription = parseFileDescription(
      PastDataOfAvaility.file_decription
    );
    // console.log(parsedDescription);
  } catch (error) {
    console.error(error);
  }

  // Output the parsed result
  // console.log(parsedDescription);

  return (
    <>
      <div className="avail_container avail_firstContainer">
        <div className="avail_row">
          <div className="avail_label">Insurance Type:</div>
          <div className="avail_value">
            {parsedDescription &&
              (parsedDescription["Insurance Type"] === "primary"
                ? "Primary"
                : parsedDescription["Insurance Type"] === "secondary"
                ? "Secondary"
                : "Tertiary")}
          </div>
        </div>

        <div className="avail_row">
          <div className="avail_label">Insurance Payer:</div>
          <div className="avail_value">
            {parsedDescription && parsedDescription["Insurance Provider"]}
          </div>
        </div>

        <div className="avail_row">
          <div className="avail_label">Inquiry Date:</div>
          <div className="avail_value">
            {parsedDescription && parsedDescription["Verified On"]}
          </div>
        </div>

        <div className="avail_row">
          <div className="avail_label">Plan/Coverage Date:</div>
          <div className="avail_value"></div>
        </div>
      </div>
      <div className="avail_container avail_secContainer">
        <div className="avail_row">
          <div className="avail_label">Eligibility Status:</div>
          <div className="avail_value appt_elig_check">Eligible</div>
        </div>

        <div className="avail_row">
          <div className="avail_label">Co-Pay:</div>
          <div className="avail_value"></div>
        </div>

        <div className="avail_row">
          <div className="avail_label">Co-Insurance:</div>
          <div className="avail_value"></div>
        </div>

        <div className="avail_row">
          <Button
            paddingLeft="0px"
            fontSize="12px"
            fontWeight="600"
            background="none"
            color="#2c7be5"
            minWidth="75px"
            textAlign="left"
          >
            View Details
          </Button>
          <div className="avail_value"></div>
        </div>
      </div>
    </>
  );
}

export default memo(AvailityLastUpdatation);
