import { takeLatest } from "redux-saga/effects";

import { GetClaimDatas, GetClaimFormData } from "./BillingWorkers";
import {
  getClaimData,
  getClaimForm,
} from "../../StateManagement/Reducers/BillingState";

export function* ClaimDataSaga() {
  yield takeLatest(getClaimData.type, GetClaimDatas);
}
