import axios from "axios";
import { apiURL, DBHost } from "../../axios/url.js";

var headers = {};

headers.Authorization = `${localStorage.token}`;

var baseURL = apiURL + DBHost + "/api";

export const providerListApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/get/provider/list", data);

export const BookScheduleApi = async (data) =>
  await axios.post(baseURL + "/schedule_widget/book/schedule", data);
