import React, { useState } from "react";
import moment from "moment";
import { Div, Text } from "../../../StyledComponents";
import {
  SearchProvider,
  TnumbComponent,
  Avatar,
  AvatarLetter,
  AvatarImage,
} from "../StyledComponents";
import { UserContainer, UserContent } from "./Styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUnreadCount } from "../../../StateManagement/Reducers/MessageState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import { globalColor, globalFont } from "../../utils/RepeatFunctions/Schedule";
import { fileTypes, generateFileURL } from "../../../axios/url.js";
import { Image } from "../../top_navbar/Notifications/styles.js";

const ProviderList = ({
  list,
  OnChangeSearch,
  sessioVal,
  handleClick,
  search,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  // const File_URL =
  // process.env.REACT_APP_BACKEND_FILE_ACCESS + "/sites/default/documentimg/";

  const dispatch = useDispatch();
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  const generalMsg = useSelector((state) => state.Revision.generalMsg);
  const [imageError, setImageError] = useState(false);

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  // ------------------------------State And Variables End------------------------ //

  return (
    <>
      <Div
        width="37%"
        height="100%"
        padding="0px 0px 0px 0px"
        background="#ffffff"
      >
        <Div display="flex" width="100%" marginLeft="18px">
        <SearchProvider searchVal={OnChangeSearch} />
        </Div>
        <UserContainer>
          <UserContent
            active={-1 === sessioVal}
            onClick={() => {
              if (storedNames.mesg1_viewOnly) {
                alert("You only have view-only access.");
              } else {
                handleClick(-1, { ProviderName: "General" });
              }
            }}
            marginTop="17px"
          >
            <Avatar
              width="40px"
              height="40px"
              marginTop="-5px"
              border="1px solid #ffffff"
            >
              <AvatarLetter top="11px">G</AvatarLetter>
            </Avatar>
            <Div width="60%" marginTop="-7px" background="none">
              <Text
                color="#2E2E2E"
                mb="4px"
                fontSize="16px"
                fontWeight="700"
                textAlign="left"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                fontFamily={globalFont}
              >
                General
              </Text>
              <Div
                color="rgba(46, 46, 46, 0.50)"
                fontSize="13px"
                fontWeight="400"
                textAlign="left"
                whiteSpace="nowrap"
                width="130px"
                textOverflow="ellipsis"
                overflow="hidden"
                background="none"
                marginTop="-12px"
              >
                {generalMsg?.type === "image" ? (
                  <TnumbComponent
                    message={generalMsg?.message}
                    variant="image"
                  />
                ) : generalMsg?.type === "doc" ? (
                  <TnumbComponent message={generalMsg?.message} variant="doc" />
                ) : generalMsg?.type === "audio" ? (
                  <TnumbComponent
                    message={generalMsg?.message}
                    variant="audio"
                  />
                ) : (
                  generalMsg?.message
                )}
              </Div>
            </Div>
            <Div
              display="flex"
              alignItems="end"
              marginLeft="auto"
              background="none"
              marginBottom="18px"
              flexDirection="column"
            >
              <Text
                width="unset"
                fontWeight="400"
                fontSize="12px"
                lineHeight="12px"
                color="#7d8592"
                background="none"
              >
                {generalMsg?.date && moment(generalMsg?.date).format("hh:mm ")}
              </Text>
              {generalMsg?.unreadCount !== "0" && generalMsg !== "" && (
                <Div
                  height="18px"
                  width="18px"
                  backColor={globalColor}
                  borderRadius="50%"
                  marginTop="-5px"
                  marginLeft="auto"
                  padding="3px"
                  zIndex="100"
                  // left="782px"
                >
                  <Text
                    fontFamily={globalFont}
                    fontWeight="600"
                    fontSize="12px"
                    lineHeight="12px"
                    color="#ffffff"
                  >
                    {generalMsg?.unreadCount}
                  </Text>
                </Div>
              )}
            </Div>
          </UserContent>
          {list.length > 0 ? (
            list
              .filter(
                (data) =>
                  data.ProviderName?.toLowerCase().indexOf(
                    search.toLowerCase()
                  ) > -1
              )
              .map((item, index) => (
                <UserContent
                  active={item.id === sessioVal}
                  onClick={() => {
                    const data = {
                      sender_id: user_id,
                      receive_id: item.id,
                      gen: 0,
                    };
                    if (storedNames.mesg1_viewOnly) {
                      alert("You only have view-only access.");
                    } else {
                      dispatch(getUnreadCount(data));
                      handleClick(index, item);
                    }
                  }}
                  key={item.id}
                >
                  {
                    item.img_url && !imageError ? (
                      <Image
                        src={generateFileURL(fileTypes.profileimages, item.img_url)}
                        alt="image-failed"
                        loading="lazy"
                        width="32px"
                        borderRadius="40px"
                        height="32px"
                        mr="8px"
                        onError={() => setImageError(true)} // Handle image error
                      />
                    ) : (
                      <Avatar width="40px" height="40px" marginTop="-5px" border="1px solid #ffffff">
                        <AvatarLetter top="11px">
                          {item.ProviderName?.charAt(0)?.toUpperCase() || "?"}
                        </AvatarLetter>
                      </Avatar>
                    )
                  }

                  <Div width="60%" marginTop="-7px" background="none">
                    <Text
                      color="#2E2E2E"
                      mb="4px"
                      fontSize="16px"
                      fontWeight="700"
                      textAlign="left"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontFamily={globalFont}
                    >
                      {item.ProviderName}
                    </Text>
                    <Div
                      color="rgba(46, 46, 46, 0.50)"
                      fontSize="13px"
                      fontWeight="400"
                      textAlign="left"
                      whiteSpace="nowrap"
                      width="130px"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      marginTop="-12px"
                      background="none"
                    >
                      {item.type === "image" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="image"
                        />
                      ) : item.type === "doc" ? (
                        <TnumbComponent message={item.message} variant="doc" />
                      ) : item.type === "audio" ? (
                        <TnumbComponent
                          message={item.message}
                          variant="audio"
                        />
                      ) : (
                        item.message
                      )}
                    </Div>
                  </Div>
                  <Div
                    display="flex"
                    alignItems="end"
                    marginLeft="auto"
                    background="none"
                    marginBottom="11px"
                    flexDirection="column"
                  >
                    <Text
                      width="unset"
                      fontWeight="400"
                      fontSize="12px"
                      lineHeight="12px"
                      color="#7d8592"
                      background="none"
                    >
                      {item.date && moment(item.date).format("hh:mm ")}
                    </Text>
                    {item.unreadCount !== "0" && (
                      <Div
                        height="18px"
                        width="18px"
                        backColor={globalColor}
                        borderRadius="50%"
                        marginTop="-5px"
                        marginLeft="auto"
                        padding="3px"
                        zIndex="100"
                        // left="782px"
                      >
                        <Text
                          fontFamily={globalFont}
                          fontWeight="600"
                          fontSize="12px"
                          lineHeight="12px"
                          color="#ffffff"
                        >
                          {item.unreadCount}
                        </Text>
                      </Div>
                    )}
                  </Div>
                </UserContent>
              ))
          ) : (
            <Div paddingTop="170px">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="16px"
                color="#000"
              >
                No Providers to Chat
              </Text>
            </Div>
          )}
        </UserContainer>
      </Div>
    </>
  );
};

export default ProviderList;
