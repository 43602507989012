import React, { useState, memo, useEffect } from "react";
import edit_appointment_delete from "../../assets/images/edit_appointment_delete.png";
import edit_appointment from "../../assets/images/edit_appointment.png";
import edit_appointment_cancel from "../../assets/images/edit_appointment_cancel.png";
import axiosInstance from "../../axios/axios";
import axios from "axios";
import {
  Div,
  Span,
  Image,
  SpinnerDiv,
  StyleButton,
} from "../../StyledComponents";
import { Select } from "antd";
import { Button, Spinner } from "react-bootstrap";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { useSelector } from "react-redux";
import moment from "moment";
import { IoVideocamOutline } from "react-icons/io5";

const { Option } = Select;

function EditSchedule({
  closepopupedit,
  dataObj,
  repeatpopupenable,
  getAppointmentData,
  setEditAppointmentPop,
  setshow,
  setBookAgain,
  setappshow,
  setMsgPopup,
  facarray,
  provarray,
  pat_share,
  appFromDate,
  FetchAppointments,
  apptoDate,
  examroom,
  openAppointment,
}) {
  const [appointmentInfo, setAppointmentInfo] = useState({
    patientName: "",
    appointmentType: "",
    date: "",
    time: "",
    facility: "",
    provider: "",
    status: "",
    statusDropDown: [],
  });
  const [isVideoCall, setIsVideoCall] = useState(true);
  const [showVideoCallButton, setShowVideoCallButton] = useState(false);
  var app_id = dataObj.Id;
  const [eventdate, setEventdate] = useState("");
  const [time, setTime] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const [SpinnerVal, setSpinnerVal] = useState(true);

  const repeats_checked = useSelector(
    (state) => state.Schedule.repeats_checked
  );
  const fetchStatusDropdown = async () => {
    try {
      const response = await axiosInstance.get("/vozo/appstatus/category");
      const decryptedData = Decrypt_Value(response.data, "vozo");
      setAppointmentInfo((prev) => ({
        ...prev,
        statusDropDown: [...decryptedData[0], ...decryptedData[1]],
      }));
    } catch (error) {
      console.error("Error fetching status dropdown:", error);
    }
  };

  const fetchAppointmentData = async () => {
    try {
      const popUpData = await getAppointmentData();
      const formattedTime = `${
        moment(popUpData?.pc_startTime, "HH:mm:ss").format("hh:mm a") || ""
      } - ${moment(popUpData?.pc_endTime, "HH:mm:ss").format("hh:mm a") || ""}`;
      setAppointmentInfo((prev) => ({
        ...prev,
        appointmentType: dataObj.title || "",
        patientName: dataObj.pname || "",
        provider: popUpData?.fname + " " + popUpData?.lname,
        facility: popUpData?.name || "",
        date: popUpData?.pc_eventDate || "",
        time: formattedTime,
        status: popUpData?.pc_apptstatus || "",
        date: popUpData?.pc_eventDate || "",
        pcEid: popUpData?.pc_eid || "",
        pc_telehealth: dataObj.pc_telehealth,
      }));

      setStatusVal(popUpData?.pc_apptstatus || "");
      setSpinnerVal(false);
    } catch (error) {
      setSpinnerVal(false);

      console.error("Error fetching appointment data:", error);
    }
  };

  useEffect(() => {
    fetchStatusDropdown();
    fetchAppointmentData();
  }, [dataObj, getAppointmentData]);

  const handleStatusChange = (value) => {
    setStatusVal(value);
  };

  const handleEdit = () => {
    setshow(false);
    setEditAppointmentPop(true);
  };
  const handleBookAgain = () => {
    setshow(false);
    setBookAgain(true);
    setappshow(true);
  };
  const handleUpdate = () => {
    const article = {
      apptstatus: statusVal,
      pcid: appointmentInfo.pcEid,
      time: appointmentInfo.date + " " + appointmentInfo.time,
    };
    let encryptedData = Encrypt_Value(article, "vozo");
    axiosInstance
      .put("/vozo/getappdashboardupdate", encryptedData)
      .then((res) => {
        setMsgPopup(true);
        FetchAppointments(
          provarray,
          facarray,
          appFromDate,
          apptoDate,
          pat_share,
          examroom
        );
        if (statusVal == "@" || statusVal == "doc_completed") {
          openAppointment("Yes");
        }
        setTimeout(() => setMsgPopup(false), 2000);

        setshow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    repeatpopupenable(repeats_checked, dataObj);
  };
  const handleAdd = () => {
    openAppointment("Yes");
    setshow(false);
  };

  useEffect(() => {
    appDatas();
  }, []);

  const appDatas = async () => {
    await axiosInstance
      .get("/vozo/patient/details?app_id=" + app_id)
      .then((response) => {
        let temp_role_pat_det = Decrypt_Value(response.data, "vozo");
        if (
          temp_role_pat_det.telehealth === "1" &&
          temp_role_pat_det.pc_apptstatus === "doc_confirmed" &&
          dataObj?.start_url
        ) {
          setShowVideoCallButton(true);
        }
        setTime(temp_role_pat_det.pc_startTime);
        setEventdate(temp_role_pat_det.pc_eventDate);
      });
  };
  const start_videoset = () => {
    var current = moment();
    var eventMoment = moment(`${eventdate} ${time}`, "YYYY-MM-DD HH:mm:ss");
    if (current.isBefore(eventMoment)) {
      alert(
        `Your Meeting is scheduled at ${eventMoment.format(
          "YYYY-MM-DD HH:mm:ss"
        )}`
      );
      return;
    } else {
      console.log("Meeting time has already passed.");
    }

    window.open(dataObj.start_url, "_blank");
    setIsVideoCall(false);
    axiosInstance
      .post("/vozo/telehealth/start?id=" + app_id)
      .then(() => {})
      .catch((err) => console.error(err));
  };
  const stop_videoset = () => {
    axiosInstance
      .post("/vozo/telehealth/stop?eid=" + app_id)
      .then(() => {
        closepopupedit();
        setIsVideoCall(true);

        // setmar_left("auto");
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      {SpinnerVal ? (
        <SpinnerDiv height="380px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          <Div borderRadius="10px">
            <Div
              display="flex"
              justifyContent="space-between"
              padding="16px"
              paddingBottom="8px"
              borderRadius="10px"
            >
              <Div>
                <Span marginBottom="4px" fontWeight="600" fontSize="16px">
                  {`${appointmentInfo.patientName} ${
                    appointmentInfo.appointmentType
                      ? " - " + appointmentInfo.appointmentType
                      : ""
                  }`}
                </Span>
                <Span fontWeight="400" fontSize="12px">
                  {appointmentInfo.date
                    ? moment(appointmentInfo.date).format("MM-DD-YYYY")
                    : appointmentInfo.date}
                  &nbsp;&nbsp;{appointmentInfo.time}
                </Span>
              </Div>
              <Div display="flex" gap="16px" cursor="pointer">
                <img
                  onClick={handleEdit}
                  src={edit_appointment}
                  width={"20px"}
                  height={"20px"}
                  alt="Edit"
                />
                <img
                  onClick={handleDelete}
                  src={edit_appointment_delete}
                  width={"20px"}
                  height={"20px"}
                  alt="Delete"
                />
                <img
                  onClick={closepopupedit}
                  src={edit_appointment_cancel}
                  width={"20px"}
                  height={"20px"}
                  alt="Cancel"
                  style={{ cursor: "pointer" }}
                />
              </Div>
            </Div>
            <Div
              padding="0px 16px"
              borderTop="1px solid rgba(46, 46, 46, 0.25)"
              borderBottom="1px solid rgba(46, 46, 46, 0.25)"
            >
              <Div padding="16px 0px">
                <Span marginBottom="4px" fontWeight="400" fontSize="12px">
                  Provider
                </Span>
                <Span fontWeight="600" fontSize="14px">
                  {appointmentInfo.provider}
                </Span>
              </Div>
              <Div padding="0px">
                <Span marginBottom="4px" fontWeight="400" fontSize="12px">
                  Facility
                </Span>
                <Span fontWeight="600" fontSize="14px">
                  {appointmentInfo.facility}
                </Span>
              </Div>
              <Div padding="16px 0px" display="flex" gap="16px">
                <Select
                  value={statusVal}
                  onChange={handleStatusChange}
                  className="edit_appointment_dropDown"
                >
                  <>
                    <Option key={"001"} value={""}>
                      Select a status
                    </Option>
                    {appointmentInfo.statusDropDown
                      .filter((x) => {
                        const isPresentNormal = appointmentInfo.statusDropDown
                          .filter((item) => item.value === statusVal)
                          .map((x) => x.check_flag);
                        return statusVal
                          ? x.check_flag === isPresentNormal[0]
                          : Number(appointmentInfo.pc_telehealth) === 0
                          ? x.check_flag === 0
                          : x.check_flag === 1;
                      })
                      .map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                  </>
                </Select>
                {showVideoCallButton && (
                  <>
                    {isVideoCall ? (
                      <StyleButton
                        borderRadius="4px"
                        backgroundColor="rgba(19, 136, 8, 1)"
                        color="white"
                        onClick={start_videoset}
                      >
                        <IoVideocamOutline /> Start Video Call
                      </StyleButton>
                    ) : (
                      <StyleButton
                        borderRadius="4px"
                        backgroundColor="red"
                        color="white"
                        onClick={stop_videoset}
                      >
                        <IoVideocamOutline /> Stop Video Call
                      </StyleButton>
                    )}
                  </>
                )}
              </Div>
            </Div>
            <Div
              display="flex"
              justifyContent="space-between"
              padding="16px"
              borderRadius="10px"
            >
              <Div display="flex" gap="16px" alignItems="center">
                <Span
                  cursor="pointer"
                  color="rgba(44, 123, 229, 1)"
                  fontWeight="600"
                  marginBottom="0px !important"
                  fontSize="14px"
                  onClick={handleAdd}
                  className="editScheduleAddNote"
                >
                  Add Services
                </Span>
                <Span
                  cursor="pointer"
                  color="rgba(44, 123, 229, 1)"
                  fontWeight="600"
                  fontSize="14px"
                  onClick={handleAdd}
                  className="editScheduleAddNote"
                >
                  Add Note
                </Span>
              </Div>
              <Div display="flex" gap="16px">
                <Button className="remove_bg_blue" onClick={handleBookAgain}>
                  Book again
                </Button>
                <Button onClick={handleUpdate}>Update</Button>
              </Div>
            </Div>
          </Div>
        </>
      )}
    </>
  );
}

export default memo(EditSchedule);
