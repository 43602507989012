import React, { useState, useEffect, memo } from "react";
import { Spinner, Modal, Form, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import axiosInstance from "../../axios/axios";
import { useParams } from "react-router-dom";
import { SpinnerDiv } from "../../StyledComponents";
import { AiOutlineSearch } from "react-icons/ai";
import { AutoComplete, Checkbox, Select } from "antd";
import CustomSuffixIcon from "./CustomSuffixIcon";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const AddAllergy = ({ clientShow, dataFetch, id, row, close }) => {
  //-------------------------------------------UseState and Variables Start--------------------------------------------------------//
  const isLoggedIn = id;
  const [allergy_type, setallergy_type] = useState("");
  const [specific_drug, setspecific_drug] = useState("");
  const [reaction, setreaction] = useState("");
  const [begdate, setbegdate] = useState(null);
  const [status, setstatus] = useState("");
  const [severity, setseverity] = useState("");
  const [note, setnote] = useState("");
  const [showerror, setShowerror] = useState(false);
  const [showerrortype, setShowerrortype] = useState(false);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [Show_Val, setShow_Val] = useState(false);
  const [Spec_Aler_Label, setSpec_Aler_Label] = useState("");
  const [AllergyVal, setAllergyVal] = useState([]);
  const [Reaction, setReaction] = useState([]);
  const [Severity, setSeverity] = useState([]);
  const [AllergyType, setAllergyType] = useState([]);
  const [fornoresults, setnoresult] = useState("2");

  const [reactionErr, setReactionErr] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [statusErr, setstatusErr] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const [identifyErr, setIdentifyErr] = useState(false);
  const [selectedDrug, setSelectedDrug] = useState("");

  var params = useParams();
  let Allergy_Val = [];
  const { Option } = Select;
  //-------------------------------------------UseState and Variables End--------------------------------------------------------//

  //-------------------------------------------Function Start-------------------------------------------------------------------//

  const mouseClick = () => setShowOptions(!showOptions);

  const handleMouseLeave = () => setShowOptions(false);

  const ShowHide_Body = (e, data) => {
    setallergy_type(data.value);
    setShowerrortype(false);

    if (data.value === "Specific Drug allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(data.value);
    } else if (data.value === "Drug Class allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(data.value);
    } else if (data.value === "Non-Drug allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(data.value);
    } else if (data.value === "No Known Drug Allergies (NKDA)") {
      setShow_Val(false);
      setSpec_Aler_Label(data.value);
    }
  };

  const OnSubmit = async (e) => {
    e.preventDefault();

    var error = 0;
    if (id === 0) {
      if (Spec_Aler_Label === "No Known Drug Allergies (NKDA)") {
        const article = {
          title: Spec_Aler_Label,
          subtype: Spec_Aler_Label,
          reaction: "",
          activity: "1",
          begdate: begdate,
          severity_al: "",
          comments: "",
          type: allergy_type,
        };
        setDisBtn(true);
        let alle_ins_enc = Encrypt_Value(article, "vozo");
        axiosInstance
          .post(
            "/vozo/patient/allergy/insert?search=" + params.pid,
            alle_ins_enc
          )
          .then((response) => {
            setDisBtn(false);
            setallergy_type("");
            setspecific_drug("");
            setreaction("");
            setbegdate(null);
            setstatus("");
            setseverity("");
            setnote("");
            clientShow(false);

            dataFetch();
          });
      } else if (Spec_Aler_Label !== "No Known Drug Allergies (NKDA)") {
        if (Spec_Aler_Label !== "Non-Drug allergy") {
          if (selectedDrug !== specific_drug) {
            setIdentifyErr(true);
            error = 1;
          }
        }
        if (Spec_Aler_Label === undefined || Spec_Aler_Label === "") {
          setShowerrortype(true);
          error = 1;
        }
        if (
          specific_drug === undefined ||
          Spec_Aler_Label === "" ||
          specific_drug === ""
        ) {
          setShowerror(true);
          error = 1;
        }
        if (reaction === "" || reaction === null || reaction === undefined) {
          setReactionErr(true);
          error = 1;
        }
        if (status === undefined || status === "") {
          setstatusErr(true);
          error = 1;
        }

        if (error > 0) {
          return false;
        }
        const article = {
          title: specific_drug,
          subtype: Spec_Aler_Label,
          reaction: reaction,
          activity: status,
          begdate: begdate,
          severity_al: severity,
          comments: note,
          type: allergy_type,
        };
        setDisBtn(true);
        let alle_ins_enc = Encrypt_Value(article, "vozo");
        axiosInstance
          .post(
            "/vozo/patient/allergy/insert?search=" + params.pid,
            alle_ins_enc
          )
          .then((response) => {
            setDisBtn(false);
            setSpec_Aler_Label("");
            setallergy_type("");
            setspecific_drug("");
            setreaction("");
            setbegdate(null);
            setstatus("");
            setseverity("");
            setnote("");
            clientShow(false);

            dataFetch();
          });
      }
    } else {
      if (Spec_Aler_Label === "No Known Drug Allergies (NKDA)") {
        const article = {
          id: id,
          title: Spec_Aler_Label,
          subtype: Spec_Aler_Label,
          reaction: "",
          activity: "1",
          begdate: begdate,
          severity_al: "",
          comments: "",
          type: allergy_type,
        };
        setDisBtn(true);
        let alle_ins_enc = Encrypt_Value(article, "vozo");
        axiosInstance
          .put(
            "/vozo/patient/allergy/update?pid=" + params.pid + "&aid=" + id,
            alle_ins_enc
          )
          .then((response) => {
            setDisBtn(false);
            clientShow(false);

            dataFetch();
          });
      } else if (Spec_Aler_Label !== "No Known Drug Allergies (NKDA)") {
        if (Spec_Aler_Label !== "Non-Drug allergy") {
          if (selectedDrug !== specific_drug) {
            setIdentifyErr(true);
            error = 1;
          }
        }

        if (Spec_Aler_Label === "") {
          setShowerrortype(true);
          error = 1;
        }

        if (specific_drug === "") {
          setShowerror(true);
          error = 1;
        } else if (
          reaction === "" ||
          reaction === null ||
          reaction === undefined
        ) {
          setReactionErr(true);
          error = 1;
        }

        if (error === 1) {
          return false;
        }

        const article = {
          id: id,
          title: specific_drug,
          subtype: Spec_Aler_Label,
          reaction: reaction,
          activity: status,
          begdate: begdate,
          severity_al: severity,
          comments: note,
          type: allergy_type,
        };
        setDisBtn(false);
        let alle_ins_enc = Encrypt_Value(article, "vozo");
        axiosInstance
          .put(
            "/vozo/patient/allergy/update?pid=" + params.pid + "&aid=" + id,
            alle_ins_enc
          )
          .then((response) => {
            setDisBtn(false);
            clientShow(false);

            dataFetch();
          });
      }
    }
  };

  const OnSaveSubmit = async (e) => {
    setAllergyVal([]);
    e.preventDefault();

    if (Spec_Aler_Label === "No Known Drug Allergies (NKDA)") {
      const article = {
        title: Spec_Aler_Label,
        subtype: Spec_Aler_Label,
        reaction: "",
        activity: "1",
        begdate: begdate,
        severity_al: "",
        comments: "",
        type: allergy_type,
      };
      setDisBtn(true);
      let alle_ins_enc = Encrypt_Value(article, "vozo");
      axiosInstance
        .post("/vozo/patient/allergy/insert?search=" + params.pid, alle_ins_enc)
        .then((response) => {
          setDisBtn(false);
          setSpec_Aler_Label("");
          setallergy_type("");
          setspecific_drug("");
          setreaction("");
          setbegdate(null);
          setstatus("");
          setseverity("");
          setnote("");
          clientShow(true);

          dataFetch();
        });
    } else if (Spec_Aler_Label !== "No Known Drug Allergies (NKDA)") {
      var error = 0;
      if (Spec_Aler_Label !== "Non-Drug allergy") {
        if (selectedDrug !== specific_drug) {
          setIdentifyErr(true);
          error = 1;
        }
      }

      if (Spec_Aler_Label === undefined || Spec_Aler_Label === "") {
        setShowerrortype(true);
        error = 1;
      }
      if (specific_drug === undefined || Spec_Aler_Label === "") {
        setShowerror(true);
        error = 1;
      } else if (
        reaction === "" ||
        reaction === null ||
        reaction === undefined
      ) {
        setReactionErr(true);
        error = 2;
      }

      if (error > 0) {
        return false;
      }

      const article = {
        title: specific_drug,
        subtype: Spec_Aler_Label,
        reaction: reaction,
        activity: status,
        begdate: begdate,
        severity_al: severity,
        comments: note,
        type: allergy_type,
      };
      setDisBtn(true);
      let alle_ins_enc = Encrypt_Value(article, "vozo");
      axiosInstance
        .post("/vozo/patient/allergy/insert?search=" + params.pid, alle_ins_enc)
        .then((response) => {
          setDisBtn(false);
          setSpec_Aler_Label("");
          setallergy_type("");
          setspecific_drug("");
          setreaction("");
          setbegdate(null);
          setstatus("");
          setseverity("");
          setnote("");
          clientShow(true);
          setDisBtn(false);
          dataFetch();
        });
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const specificdrug_Change = (e) => {
    setspecific_drug(e.target.value);
    setShowerror(false);
  };

  const resultSelect = (data) => {
    setSelectedDrug(data);
    setspecific_drug(data);
  };

  const searchChange = (data) => {
    setspecific_drug(data);
    setShowerror(false);
    setnoresult("2");
    setAllergyVal([]);
    setIdentifyErr(false);
  };

  const reactionChange = (e, data) => {
    setreaction(data.value);
    setReactionErr(false);
  };

  const severityChange = (data) => setseverity(data.value);

  const statusChange = (data) => {
    setstatus(data.value);
    setstatusErr(false);
  };

  const noteChange = (e) => setnote(e.target.value);

  //-------------------------------------------Function End----------------------------------------------------------//

  //-------------------------------------------UseEffect Start--------------------------------------------------------//

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (specific_drug !== "") {
          axiosInstance
            .get(
              "https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=" +
                specific_drug +
                "&ef=STRENGTHS_AND_FORMS"
            )
            .then((response) => {
              if (response.data[3].length > 0) {
                setnoresult("2");
              } else {
                setnoresult("");
              }
              for (let i = 0; i < response.data[3].length; i++) {
                for (let j = 0; j < 1; j++) {
                  Allergy_Val.push({ title: response.data[3][i][0] });
                }
              }
              setAllergyVal(Allergy_Val);
            })
            .catch((err) => {
              setAllergyVal([]);
              setnoresult("");
              setSpinnerVal(false);
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [specific_drug]);

  const getData = async () => {
    await axiosInstance
      .get("/vozo/AddAllergy/Reaction")
      .then((response) => {
        let temp_role = Decrypt_Value(response.data, "vozo");
        setReaction(temp_role);
      })
      .catch((err) => {
        setSpinnerVal(false);
        setReaction([]);
      });

    await axiosInstance
      .get("/vozo/AddAllergy/Severity")
      .then((response) => {
        let temp_role = Decrypt_Value(response.data, "vozo");
        setSeverity(temp_role);
      })
      .catch((err) => {
        setSeverity([]);
        setSpinnerVal(false);
      });

    await axiosInstance
      .get("/vozo/AddAllergy/AllergyType")
      .then((response) => {
        let temp_role = Decrypt_Value(response.data, "vozo");
        setAllergyType(temp_role);
        setSpinnerVal(false);
      })
      .catch((err) => {
        setAllergyType([]);
        setSpinnerVal(false);
      });

    if (row.subtype === "Specific Drug allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(row.subtype);
    } else if (row.subtype === "Drug Class allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(row.subtype);
    } else if (row.subtype === "Non-Drug allergy") {
      setShow_Val(true);
      setSpec_Aler_Label(row.subtype);
    } else if (row.subtype === "No Known Drug Allergies (NKDA)") {
      setShow_Val(false);
      setSpec_Aler_Label(row.subtype);
    }
  };

  useEffect(() => {
    setreaction(row.reaction ? row.reaction : "");
    setallergy_type(row.type);
    setspecific_drug(
      row.specific_drug_allergy ? row.specific_drug_allergy : ""
    );

    setbegdate(row.begdate ? row.begdate : null);
    setstatus(row.status ? row.status : "");
    setseverity(row.severity_al ? row.severity_al : "");
    setnote(row.note === null ? "" : row.note);
    setSpec_Aler_Label(row.subtype ? row.subtype : "");
    setSelectedDrug(row.specific_drug_allergy ? row.specific_drug_allergy : "");

    getData();
  }, []);

  //-------------------------------------------UseEffect End----------------------------------------------------------//
  return (
    <div id="add-allergy">
      <Form onSubmit={OnSubmit} className="Allegry-search">
        <Modal.Header closeButton>
          {isLoggedIn ? (
            <>
              <Modal.Title className="top">Edit Allergy</Modal.Title>
            </>
          ) : (
            <>
              <Modal.Title className="top">Add Allergy</Modal.Title>
            </>
          )}
        </Modal.Header>
        <Modal.Body>
          <>
            {SpinnerVal ? (
              <SpinnerDiv height="380px">
                <Spinner animation="border" variant="primary" />
              </SpinnerDiv>
            ) : (
              <>
                <Form.Row className="add_aller_pop_body">
                  <input name="id" type="hidden" value={id}></input>
                  <Form.Group as={Col}>
                    <Form.Label className="alle_ty_alt">
                      Allergy type
                    </Form.Label>
                    <Select
                      placeholder="Select"
                      className={
                        showerrortype
                          ? "w-100 ant-sel-hei34 ant-sel-err-border"
                          : "w-100 ant-sel-hei34"
                      }
                      suffixIcon={<CustomSuffixIcon />}
                      id="adAle_sel-typ"
                      name="allergy_type"
                      value={Spec_Aler_Label}
                      onChange={(e, data) => ShowHide_Body(e, data)}
                    >
                      <Option value="" disabled>
                        ---* Select the Allergy type *---
                      </Option>
                      {AllergyType.map((row, index) => (
                        <Option key={index} value={row.value}>
                          {row.label}
                        </Option>
                      ))}
                    </Select>
                    {showerrortype ? (
                      <p className="errormessage">Please select allergy type</p>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Form.Row>
                {Show_Val ? (
                  <>
                    <Form.Row className="alle_mod_Body_alt">
                      <Form.Group as={Col}>
                        <Form.Label>{Spec_Aler_Label}<span style={{ color: "#E23D28" }}>*</span></Form.Label>
                        {Spec_Aler_Label === "Non-Drug allergy" ? (
                          <Form.Control
                            name="specific_drug"
                            style={
                              showerror
                                ? {
                                    border: "1px solid red",
                                    borderRadius: "5px",
                                    width: "95%",
                                  }
                                : {}
                            }
                            value={specific_drug}
                            onChange={specificdrug_Change}
                          />
                        ) : (
                          <div className="for_autoComplete_bi pl">
                            <AiOutlineSearch />
                            <AutoComplete
                              onClick={mouseClick}
                              // onMouseLeave={handleMouseLeave}
                              className="alle_sea_alt"
                              style={
                                showerror || identifyErr
                                  ? {
                                      border: "1px solid red",
                                      borderRadius: "5px",
                                      width: "164px",
                                    }
                                  : { width: "164px" }
                              }
                              placeholder="Find Drug"
                              dropdownClassName={
                                specific_drug === "" && "for_no_loading"
                              }
                              onSelect={resultSelect}
                              onChange={searchChange}
                              notFoundContent={
                                showOptions
                                  ? fornoresults.length !== 0
                                    ? "loading.."
                                    : "No Result Found"
                                  : ""
                              }
                              value={specific_drug}
                              options={
                                showOptions &&
                                AllergyVal.map((x) => {
                                  return {
                                    label: x.title,
                                    value: x.title,
                                  };
                                })
                              }
                              id="adAle_srch-drg"
                            />
                          </div>
                        )}
                        {showerror ? (
                          <p className="errormessage lib_table">
                            Please Enter your Allergy Drug
                          </p>
                        ) : identifyErr ? (
                          <p className="errormessage lib_table">
                            Please Select any from the Given List
                          </p>
                        ) : (
                          ""
                        )}
                      </Form.Group>

                      <Form.Group as={Col} id="reaction">
                        <Form.Label>Reaction<span style={{ color: "#E23D28" }}>*</span></Form.Label>
                        <Select
                          placeholder="Select"
                          className={
                            reactionErr
                              ? "w-100 ant-sel-hei34 ant-ellipsis-text ant-sel-err-border"
                              : "w-100 ant-sel-hei34 ant-ellipsis-text"
                          }
                          suffixIcon={<CustomSuffixIcon />}
                          id="reaction"
                          name="reaction"
                          value={reaction}
                          onChange={(e, data) => reactionChange(e, data)}
                        >
                          <Option value="" disabled>
                            Select
                          </Option>
                          {Reaction.map((row, index) => (
                            <Option key={index} value={row.value}>
                              {row.label}{" "}
                            </Option>
                          ))}
                        </Select>
                        {reactionErr && (
                          <span className="errormessage">
                            select any type of reaction
                          </span>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="alle_mod_Body_alt">
                      <Form.Group as={Col}>
                        <Form.Label>Severity</Form.Label>
                        <Select
                          placeholder="Select"
                          className="w-100 ant-sel-hei34"
                          suffixIcon={<CustomSuffixIcon />}
                          id="adAle_sel-svrt"
                          name="severity"
                          value={severity}
                          onChange={(e, data) => severityChange(data)}
                        >
                          <Option value="" disabled>
                            ---------
                          </Option>
                          {Severity.map((row, index) => (
                            <Option key={index} value={row.value}>
                              {row.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Status<span style={{ color: "#E23D28" }}>*</span></Form.Label>
                        <Select
                          placeholder="Select"
                          className={
                            statusErr
                              ? "arrow-adj w-100 ant-sel-hei34 ant-sel-err-border"
                              : "arrow-adj w-100 ant-sel-hei34"
                          }
                          suffixIcon={<CustomSuffixIcon />}
                          name="status"
                          value={status}
                          onChange={(e, data) => statusChange(data)}
                          id="adAle_sel-sats"
                        >
                          <Option value="" disabled>
                            ---------
                          </Option>
                          <Option value="1">Active</Option>
                          <Option value="0">InActive</Option>
                        </Select>
                        {statusErr && (
                          <p className="errormessage m-l-11">
                            Please Select any Status
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>

                    <Form.Row className="alle_mod_Body_alt">
                      <Form.Group as={Col}>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="note"
                          value={note}
                          onChange={noteChange}
                          id="adAle_tare-not"
                        />
                      </Form.Group>
                    </Form.Row>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        </Modal.Body>
        <div className="border_bw_bodfoot alle_bot_bor_foot_alt_"></div>
        <Modal.Footer className="add_alle_foot_alt">
          {isLoggedIn ? (
            <>
              <Button
                className="close_active1 close_active"
                variant="light"
                onClick={close}
              >
                Close
              </Button>

              <Button
                className="save_active save-btn"
                type="submit"
                variant="primary"
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button
                className="close_active close_active1"
                variant="light"
                onClick={close}
              >
                Close
              </Button>
              <Button
                className="save_active save-btn"
                type="submit"
                variant="primary"
                disabled={disBtn}
              >
                Save
              </Button>
              <Button
                className="close_active close_active2 fill-btn m-l-au white-color"
                variant="outline-primary"
                onClick={OnSaveSubmit}
                disabled={disBtn}
              >
                Save and Add Another
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(AddAllergy);
