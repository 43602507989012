import React, { useEffect } from "react";
import { SubNavItem, ListItem, UL, Div } from "./styles";
import UnLoadScripts from "../popups/unLoadScript";
import { GetNav } from "../../StateManagement/Reducers/ScheduleState";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};
const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};
const ScheduleNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const handleInputChange = (iconName) => (e) => {
    dispatch(GetNav(iconName));
  };
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  useEffect(() => {
    // axiosInstance
    //   .get("/vozo/getprovidernamewithid?provid=" + provid)
    //   .then((response) => {
    //     let DecodeVal = Decrypt_Value(response.data, "vozo");
    //     setprovname(DecodeVal.username);
    //   });
    // dispatch(GetCurrency());
    UnLoadScripts();
  }, []);

  return (
    <Div width="100%" paddingLeft="0.86em">
      <UL
        textAlign="left"
        width="100%"
        listStyleType="none"
        padding="0"
        margin="0"
        position="relative"
      >
        <ListItem
          padding="8px"
          margin="8px 0 0 0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem
            to="/schedule/calender"
            exact
            className={
              (splitLocation[1] === "schedule" && splitLocation[2] === "nav") ||
              splitLocation[2] === "calender" ||
              location.pathname === "/"
                ? "active"
                : ""
            }
          >
            Calendar
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/schedule/practicedashboard" exact>
            Practice Dashboard
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/schedule/appdashboard" exact>
            Appointment Dashboard
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/schedule/apptemplate" exact>
            Provider Availability
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/schedule/appprofiles" exact>
            Appointment Profiles
          </SubNavItem>
        </ListItem>
        <ListItem
          padding="8px"
          margin="0"
          onClick={handleInputChange("iconopen")}
        >
          <SubNavItem to="/schedule/billingprofiles" exact>
            Billing Profiles
          </SubNavItem>
        </ListItem>
        {(owner_id === null || owner_id === " ") && (
          <ListItem
            padding="8px"
            margin="0"
            onClick={handleInputChange("iconopen")}
          >
            <SubNavItem to="/schedule/auditlog" exact>
              Audit Log
            </SubNavItem>
          </ListItem>
        )}
        {/* <ListItem padding="8px" margin="0" onClick={handleInputChange("iconclose")}>
          <SubNavItem to="/schedule/encryptDecrypt" exact>
            Encrypt and Decrypt
          </SubNavItem>
        </ListItem> */}
      </UL>
    </Div>
  );
};

export default ScheduleNav;
