import { createSlice } from "@reduxjs/toolkit";

export const BillingSlice = createSlice({
  name: "Billing",
  initialState: {
    ClaimData: [],
  },
  reducers: {
    getClaimData: (data) => {
      return data;
    },
    setClaimData: (state, action) => {
      state.ClaimData = action.payload;
    }, 
  },
});

export const { getClaimData, setClaimData, getClaimForm, setClaimForm } =
  BillingSlice.actions;

export default BillingSlice.reducer;
