import { call, put } from "redux-saga/effects";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";
import { ClaimApi, ClaimFormApi } from "../Apis/BillingApi";
import {
  setClaimData,
  setClaimForm,
} from "../../StateManagement/Reducers/BillingState";

export function* GetClaimDatas({ payload }) {
  const data = payload;

  if (Object.keys(data).length === 0) {
    yield put(setClaimData([]));
  } else {
    yield put(setClaimData([]));
    let credential = Encrypt_Value(data, "vozo");
    try {
      const res = yield call(ClaimApi, data);
      if (res.status === 200) {
        const Data = Decrypt_Value(res.data, "vozo");
        yield put(setClaimData(Data));

      }
    } catch (e) {
      yield put(setClaimData([]));
      console.log(e.message);
    }
  }
}
