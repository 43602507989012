import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import RevisionReducer from "./Reducers/PatientState";
import DemographicsHomeworkReducer from "./Reducers/PatientDemoHomeworkState";
import ScheduleReducer from "./Reducers/ScheduleState";
import ReportReducer from "./Reducers/ReportState";
import SettingReducer from "./Reducers/SettingState";
import MessageReducer from "./Reducers/MessageState";
import SubscriptionRenewalReducer from "./Reducers/SubscriptionRenewalState";
import PreLoginReducer from "./Reducers/PreLoginState";
import ScheduleWidgetReducer from "./Reducers/ScheduleWidgetState";
import BillingReducer from "./Reducers/BillingState";
import RootSaga from "../MiddleWare";

const Saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    Billing: BillingReducer,
    Revision: RevisionReducer,
    DemographicsHomeWork: DemographicsHomeworkReducer,
    Schedule: ScheduleReducer,
    Report: ReportReducer,
    Setting: SettingReducer,
    Message: MessageReducer,
    SubsRenewal: SubscriptionRenewalReducer,
    PreLogin: PreLoginReducer,
    ScheduleWidget: ScheduleWidgetReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(Saga),
});

Saga.run(RootSaga);

export default store;
