import React, { useState, memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { Modal, Button, Form } from "react-bootstrap";
import { DatePicker, Radio, Space, Select } from "antd";
import axiosInstance from "../../axios/axios";
import StripeAlert from "../../StyledComponents/StripeAlert/stripealert";
import axios from "axios";
import moment from "moment";
import suffixIcon from "../popups/CustomSuffixIcon";
import PaymentError from "../../StyledComponents/PaymentError";
import UpgradeRequired from "./UpgradeRequired";
import { fileurl, DBHost } from "../../axios/url.js";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const AddPayment = (props) => {
  const { Option } = Select;
  var invototal = props.invototal !== "no" ? props.invototal : "no";
  var invobalance = props.invobalance !== "no" ? props.invobalance : "no";
  var reload_inv = props.reload_inv !== "no" ? props.reload_inv : "no";
  var invoname = props.invoname !== "no" ? props.invoname : "no";
  var disRadio = props.disRadio ? props.disRadio : false;
  const [radiopaymentcheck, setradiopaymentcheck] = useState(3);

  const [stylerefer, setstylerefer] = useState("none");
  const [refervalue, setrefervalue] = useState("");
  const [amountval, setamountval] = useState("");
  const [ddate, setddate] = useState("");
  const [erroramount, seterroramount] = useState(false);
  const [errorrefer, seterrorrefer] = useState(false);
  const [errorpostdate, seterrorpostdate] = useState(false);
  const [paymentmethod, setpaymethod] = useState("cash");
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [stripeAllowed, setStripeAllowed] = useState(false);
  const [stripeAlert, setStripeAlert] = useState(false);
  const [stripeFail, setStripeFail] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const accountType = Decrypt_Value(
      localStorage.getItem("account_type"),
      "vozo"
    ),
    trial = Decrypt_Value(localStorage.getItem("trial_period"), "vozo");
  const dtFormat = localStorage.getItem("dateFormat");
  const currency = useSelector((state) => state.Report.Currency);

  const Site = DBHost;
  var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const radiochangepayment = (e) => {
    seterroramount(false);
    setamountval("");
    seterrorpostdate(false);
    seterrorrefer(false);
    setradiopaymentcheck(e.target.value);
  };

  const chkstat = (value) => {
    if (accountType === "1" && value === "stripe") {
      setUpgrade(true);
    } else {
      setpaymethod(value);
      value === "check" ? setstylerefer("block") : setstylerefer("none");
      if (stripeAllowed === false && value === "stripe") {
        setStripeAlert(true);
      } else {
        setStripeAlert(false);
      }
    }
  };
  const handleAlertClose = () => {
    setStripeAlert(false);
  };
  const handleStripeAlertClose = () => {
    setStripeFail(false);
  };

  const Paywithstripe = (body) => {
    // return;
    var res = axios
      .get(fileurl + "/stripe-custom/card_pay.php", { params: body })
      .then((res) => {
        return JSON.stringify(res.data);
      })
      .catch((err) => {
        console.log("failed");
      });
    return res;
  };

  const addpaymentinvoice = async (e) => {
    e.preventDefault();

    if (isSubmitted) {
      var amounttype = 0;
      var paymethod = paymentmethod,
        amount = amountval,
        refer = refervalue,
        postdate =
          ddate !== "" ? moment(ddate, dtFormat).format("YYYY-MM-DD") : "";

      if (radiopaymentcheck === 3 && (amount === "" || amount <= 0)) {
        seterroramount(true);
        var error = 1;
      }

      if (postdate === "") {
        seterrorpostdate(true);
        error = 1;
      }
      if (refer === "" && paymethod === "check") {
        seterrorrefer(true);
        error = 1;
      }
      if (error === 1) {
        return false;
      }
      setIsSubmitted(false);
      if (radiopaymentcheck === 1) amounttype = invototal;

      if (radiopaymentcheck === 2) amounttype = invobalance;

      if (radiopaymentcheck === 3) amounttype = amount;

      if (paymethod === "stripe") {
        let body = Encrypt_Value(
          {
            pid: props.pid,
            site: Site,
            amount: amounttype,
            card_Id: "",
            receipt_id: invoname,
          },
          "vozo"
        );

        var final = await Paywithstripe(body);
        let statusmsg = JSON.parse(final);

        if (statusmsg !== "succeeded") {
          setIsSubmitted(true);

          if (statusmsg.includes("No such customer")) {
            setStripeFail(true);

            return false;
          } else {
            if (statusmsg.includes("Invalid source object")) {
              props.setAlertMsg1(
                "Payment failed please  check for default card "
              );
            } else {
              props.setAlertMsg1("Payment failed  ");
            }
            props.setModalAlerShow1(true);
            setTimeout(() => props.setModalAlerShow1(false), 3000);
            return false;
          }
        }
        // else {
        //   props.setModalAlerShow1(true);
        //   props.setAlertMsg1("Paid Successfully");
        //   setTimeout(() => props.setModalAlerShow1(false), 3000);
        // }
      }

      const article = {
        invoice_id: invoname,
        amount: amounttype,
        paymethod: paymethod,
        refer: refer,
        postdate: postdate,
        userid: provider,
        receipt_id: invoname,
      };

      var add_app_inv_arr_encryptedData = Encrypt_Value(article, "vozo");

      if (invobalance !== "no" && props.eid !== "no") {
        if (radiopaymentcheck === 1) {
          axiosInstance
            .put(
              "/vozo/addappointmentinvoicearray?num=0&eid=" + props.eid,
              add_app_inv_arr_encryptedData
            )
            .then((response) => {
              props.showstatus();
              axiosInstance
                .get("/vozo/pat_total?eid=" + props.eid)
                .then((response) => {
                  var temp_role_patient_total = Decrypt_Value(
                    response.data,
                    "vozo"
                  );
                  props.settotal_balance(temp_role_patient_total.total_balance);
                })
                .catch((err) => console.log(err));
              setIsSubmitted(true);
            })
            .catch((err) => {
              console.log(err);
              setIsSubmitted(true);
            });
        } else if (radiopaymentcheck === 2) {
          axiosInstance
            .put(
              "/vozo/addappointmentinvoicearray/invoice?num=0&eid=" + props.eid,
              add_app_inv_arr_encryptedData
            )
            .then((response) => {
              props.showstatus();
              axiosInstance
                .get("/vozo/pat_total?eid=" + props.eid)
                .then((response) => {
                  var temp_role_patient_total = Decrypt_Value(
                    response.data,
                    "vozo"
                  );
                  props.settotal_balance(temp_role_patient_total.total_balance);
                })
                .catch((err) => console.log(err));
              setIsSubmitted(true);
            })
            .catch((err) => {
              console.log(err);
              setIsSubmitted(true);
            });
        } else if (radiopaymentcheck === 3) {
          axiosInstance
            .put(
              "/vozo/addappointmentinvoicearray/other?num=0&eid=" + props.eid,
              add_app_inv_arr_encryptedData
            )
            .then((response) => {
              props.showstatus();
              axiosInstance
                .get("/vozo/pat_total?eid=" + props.eid)
                .then((response) => {
                  var temp_role_patient_total = Decrypt_Value(
                    response.data,
                    "vozo"
                  );
                  props.settotal_balance(temp_role_patient_total.total_balance);
                })
                .catch((err) => console.log(err));
              setIsSubmitted(true);
            })
            .catch((err) => {
              console.log(err);
              setIsSubmitted(true);
            });
        }

        props.showfullpop(false);
        props.handleClose();
        props.setreload_inv(!reload_inv);
        props.Handleinvprop();
        props.showstatus();
        props.setModalAlerShow(true);
        props.setAlertMsg("amount paid successfully");
        setTimeout(() => props.setModalAlerShow(false), 1500);
        props.checkamount_pay();
      } else {
        if (radiopaymentcheck === 1) {
          if (props.payInvAppt) {
            axiosInstance
              .put(
                "/vozo/encounter/addappointmentinvoicearray?eid=" +
                  props.eidval +
                  "&pid=" +
                  props.pid,
                add_app_inv_arr_encryptedData
              )
              .then((response) => {
                props.data_ref();
                props.ModalShowHide();
                setIsSubmitted(true);
              })
              .catch((err) => {
                console.log(err);
                setIsSubmitted(true);
              });
          } else {
            axiosInstance
              .put(
                "/vozo/dashboard/addappointmentinvoicearray?pid=" + props.pid,
                add_app_inv_arr_encryptedData
              )
              .then((response) => {
                axiosInstance
                  .get("/vozo/showtotalbillpatient?pid=" + props.pid)
                  .then((response) => {
                    //let DecodeVal = Decrypt_Value(response.data, "vozo");
                    setIsSubmitted(true);
                  })
                  .catch();
                if (props.data_ref) {
                  props.data_ref();
                }
                props.ModalShowHide();
                props.setreload_func();
              })
              .catch((err) => {
                console.log(err);
                setIsSubmitted(true);
              });
          }
        } else if (radiopaymentcheck === 3) {
          axiosInstance
            .put(
              "/vozo/dashboard/other/addappointmentinvoicearray/other?pid=" +
                props.pid,
              add_app_inv_arr_encryptedData
            )
            .then((response) => {
              props.ModalShowHide();
              axiosInstance
                .get("/vozo/showtotalbillpatient?pid=" + props.pid)
                .then((response) => {
                  //let DecodeVal = Decrypt_Value(response.data, "vozo");

                  props.setreload_func();
                })
                .catch((err) => {
                  console.log(err);
                });
              if (props.data_ref()) {
                props.data_ref();
              }
              setIsSubmitted(true);
            })
            .catch((err) => {
              console.log(err);
              setIsSubmitted(true);
            });
        }
      }

      setrefervalue("");
      setpaymethod("cash");
      setamountval("");
      setddate("");

      props.handleClose();

      if (props.insurance === "insurance") {
        setTimeout(() => {
          props.chceckamountpay();
        }, 700);
      }
    }
    // props.setdisabledsave_pat_balance("");
    // props.setdisabledsave(false);
  };

  const amountChange = (e) => {
    let value = e.target.value;
    const isValidNumber = /^\d*\.?\d*$/.test(value);
    if (isValidNumber) {
      setamountval(value);
      seterroramount(false);
    } else {
      seterroramount(true);
    }
  };
  const onBlur = () => {
    if (!isNaN(amountval) && amountval !== "") {
      const roundedValue = parseFloat(amountval).toFixed(2);
      setamountval(roundedValue);
    }
  };
  const disabledDate = (current) => {
    const currentDate = moment().startOf("day");
    return current && current.startOf("day").isAfter(currentDate);
  };

  const handlecloseUpgrade = () => {
    setUpgrade(false);
  };

  useEffect(() => {
    if (props.chekBal)
      props.chekBal === true
        ? setradiopaymentcheck(1)
        : setradiopaymentcheck(3);

    axiosInstance
      .get("/vozo/users/stripecheck")
      .then((response) => {
        var Data = Decrypt_Value(response.data, "vozo");
        if (Data.length > 0) {
          if (Data[0] === "Both gl_values are present.") {
            setStripeAllowed(true);
          } else {
            setStripeAllowed(false);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="">
      <Form
        onSubmit={addpaymentinvoice}
        style={{
          display: stripeAlert ? "none" : stripeFail ? "none" : "block",
        }}
      >
        <Modal.Header
          closeButton
          onHide={() => {
            if (props.handledisBtn) props.handledisBtn();
          }}
          style={{
            backgroundColor: "#ffffff",
            borderTopRightRadius: "8px",
            borderTopLeftRadius: "8px",
            alignItems: "center",
          }}
        >
          <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div id="add_payment">
            <div className="radio_group_add_pay">
              <Radio.Group
                style={{ width: "200px" }}
                onChange={radiochangepayment}
                value={radiopaymentcheck}
              >
                <Space direction="vertical">
                  <Radio
                    value={1}
                    type="radio"
                    name="checkpay"
                    id="balance"
                    disabled={disRadio}
                  >
                    <label
                      htmlFor="balance"
                      className="for_balance"
                      style={{ marginLeft: "2%", width: "320px" }}
                    >
                      <h3 className="upg-req-margin">
                        {invototal === null
                          ? `${currency} 0.00`
                          : `${currency} ${invototal}`}
                        {props.chekBal === true ? (
                          <span> Appointment Balance</span>
                        ) : (
                          <span> Patient Balance</span>
                        )}
                      </h3>
                    </label>
                  </Radio>

                  {invobalance !== "no" ? (
                    <Radio value={2} type="radio" name="checkpay" id="invoice">
                      <label
                        htmlFor="invoice"
                        className="for_inv_balance"
                        style={{ marginLeft: "2%", width: "320px" }}
                      >
                        <h3>
                          {invobalance === null
                            ? `${currency} 0.00`
                            : `${currency} ${invobalance}`}{" "}
                          Invoice Balance
                        </h3>
                      </label>
                    </Radio>
                  ) : (
                    ""
                  )}
                  {props.payInvAppt === false ? (
                    <div className="othersamnt_val">
                      <Radio
                        value={3}
                        type="radio"
                        name="checkpay"
                        id="otheramount"
                      >
                        <label
                          htmlFor="otheramount"
                          className="for_other_balance"
                          style={{ marginLeft: "2%" }}
                        >
                          <h3 className="for_font_size_h3 upg-req-margin">
                            Other Amount
                          </h3>
                        </label>
                      </Radio>

                      {radiopaymentcheck === 3 ? (
                        <Form.Control
                          id="amountvalue"
                          value={amountval}
                          onChange={amountChange}
                          onBlur={onBlur}
                          placeholder="Enter Your Amount"
                          style={{
                            width: "180px",
                            outline: "none",
                            borderRadius: "3px",
                          }}
                        />
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </Space>
              </Radio.Group>
              {erroramount ? (
                <p className="errormessage post_payment">
                  Please enter valid amount
                </p>
              ) : (
                ""
              )}
            </div>

            <Form.Row
              style={{
                paddingLeft: "20px",
                marginBottom: "16px",
              }}
            >
              <Select
                placeholder="Select"
                style={{ width: "40%" }}
                id="paymethod"
                suffixIcon={suffixIcon}
                onChange={(e) => chkstat(e)}
                value={paymentmethod ? paymentmethod : "cash"}
              >
                <Option value="cash">Cash</Option>
                <Option value="credit Card">Credit Card</Option>
                <Option value="check">Cheque</Option>
                <Option value="stripe">Stripe</Option>
              </Select>
              <DatePicker
                // name="begdate"
                // type="date"
                // id="postdate"
                format={dtFormat}
                value={ddate ? moment(ddate, dtFormat) : null}
                disabledDate={disabledDate}
                // max={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  seterrorpostdate(false);
                  setddate(e);
                }}
              />
              {errorpostdate ? (
                <p className="errormessage add_pay_dateerr">
                  Please enter valid date
                </p>
              ) : (
                ""
              )}
              <Form.Control
                style={{ marginTop: "15px", width: "94%", display: stylerefer }}
                placeholder="# Check"
                id="refer"
                name="Appointment"
                onChange={(e) => {
                  seterrorrefer(false);
                  setrefervalue(e.target.value);
                }}
              />
              {errorrefer ? (
                <p className="errormessage refer_no">
                  Please enter valid refer no
                </p>
              ) : (
                ""
              )}
            </Form.Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="for-add-pay-mod-foot">
          <Button
            className="close_active1 close_active "
            variant="light"
            style={{ marginLeft: "156px" }}
            onClick={() => {
              props.handleClose();
              if (props.handledisBtn) {
                props.handledisBtn();
              }
              // props.setdisabledsave_pat_balance("");
              // props.setdisabledsave(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="save_active save-btn"
            type="submit"
            variant="primary"
            style={{ width: "120px" }}
            id="invoice_addpayment_btn"
            disabled={
              stripeAllowed === false && paymentmethod === "stripe"
                ? true
                : false
            }
          >
            Add Payment
          </Button>
        </Modal.Footer>
      </Form>
      <Modal
        className="client-popup pat-pay-tab w-100 topcreate_popup_centre"
        centered
        show={stripeAlert}
        onHide={handleAlertClose}
      >
        <StripeAlert />
      </Modal>
      <Modal
        className="client-popup pat-pay-tab w-100 topcreate_popup_centre striper"
        centered
        show={stripeFail}
        onHide={handleStripeAlertClose}
      >
        <PaymentError />
      </Modal>
      {upgrade && (
        <UpgradeRequired
          title="Stripe Payment Method"
          description="Add provider's stripe key in billing settings to make use of stripe payment."
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
          handlecloseUpgrade={handlecloseUpgrade}
          bgChange={true}
        />
      )}
    </div>
  );
};

export default memo(AddPayment);
