import React, { useRef, useState, useEffect } from "react";
import { Div } from "../../billing/CardTransactions/styles";
import EditDocument from "../../popups/EditDocument";
import { Button, Image } from "react-bootstrap";
import moment from "moment";
import { ServDocURL } from "../../../axios/url.js";
import {
  ProfileFileURL,
  fileTypes,
  generateFileURL,
} from "../../../axios/url.js";
import {
  EditIcon,
  FormHeaderInput,
  FormHeaderInputCloseBtn,
  FormHeaderInputCloseBtnIcon,
  FormHeaderInputSaveBtn,
} from "../../patient/styles";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";
import { Select } from "antd";
import CustomSuffixIcon from "../../popups/CustomSuffixIcon";
import { Modal } from "react-bootstrap";
import { AiOutlineSearch, AiOutlineMore } from "react-icons/ai";
import {
  Div1,
  Dropdown,
  DropDown,
  DropDownDiv,
  DropdownItem,
  Input1,
  PatientName,
  TitleLabel,
  TxtSpan,
  DropdownList,
  DropdownContent,
} from "./Styles";
import axiosInstance from "../../../axios/axios";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import CloseDoc from "../../../assets/images/CloseDoc.png";

const TemplateWorkAreas = ({
  closeTemplate,
  savetemplate,
  EditDocName,
  content,
  fromTemplate,
  CategoryValue,
  speciality,
  appt_endTime,
  patientdetails,
  eid,
  NoteFromtype,
  app_id,
}) => {
  //-----------------------------State and Variable Start----------------------------------------------- //

  const [ExtraContent, setExtraContent] = useState("");
  const [ChangedContent, setChangedContent] = useState(content);
  const [templateTitle, setTemplateTitle] = useState(
    EditDocName !== "" || EditDocName !== undefined ? EditDocName : ""
  );
  const [inputValues, setInputValues] = useState({});
  const [DocNameErr, setDocNameErr] = useState(false);
  const [SpecialityErr, setSpecialityErr] = useState(false);
  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  const [FormDataErr, setFormDataErr] = useState(false);
  const [disBtn, setDisBtn] = useState(false);
  const [Category, setCategory] = useState(
    CategoryValue !== "" && CategoryValue !== undefined
      ? CategoryValue
      : fromTemplate == "Dashboard"
      ? "Intake"
      : "Encounter"
  );
  const [specialty, setSpecial] = useState(
    speciality !== "" ? speciality : "Select Specialty"
  );
  const [App_id, setApp_id] = useState(0);
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [DataForUseTemplate, setDataForUseTemplate] = useState("");
  const [TemplateAppId, setTemplateAppId] = useState(0);

  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateEdit, setTemplateEdit] = useState(false);
  const [Mode, setMode] = useState("Editor");
  const [templateName, setTemplateName] = useState(
    EditDocName !== "" || EditDocName !== undefined ? EditDocName : ""
  );
  const [openCard, setopenCard] = useState(false);
  const isDisabled = eid !== "";
  const fref = useRef();
  const { Option } = Select;

  const specialtyList = [
    "Plastic Surgery",
    "Dermatology",
    "Sleep Medicine",
    "Allergy & Immunology",
    "Concierge Medicine",
    "Oncology",
    "Palliative Care",
    "Endocrinology",
    "Sports Medicine",
    "Hospice",
    "Podiatry",
    "Pediatrics",
    "Pathology",
    "Obstetrics and Gynecology",
    "Hand Surgery",
    "Hematology",
    "Emergency Medicine",
    "Surgery",
    "Cardiology",
    "Vascular Surgery",
    "Pulmonology",
    "Austism",
    "Radiology",
    "ECG Management",
    "Physical Medicine & Rehabilitation",
    "Opthalmology",
    "Geriatric Medicine",
    "Neurology",
    "Infectious Disease",
    "Nephrology",
    "Ambulatory Care & Surgery",
    "Otolaryngology (ENT)",
    "Rheumatology",
    "Dental Health",
    "Chiropractic",
    "Psychiatry",
    "Urology",
    "Wound Care",
    "Orthopedic Surgery",
    "Behaviour Health",
    "Gastroenterology",
    "Anesthesiology",
    "Family Medicine",
    "Behavioral health therapy",
    "Acupuncture",
    "Lactation consulting",
    "Massage therapy",
    "Counseling",
    "Marriage and family therapy",
    "Psychology",
    "Social work",
    "Dietetics or nutrition counseling",
    "Chiropractic",
    "Applied behavior analysis",
    "Occupational therapy",
    "Physical therapy",
    "Speech-language pathology",
    "Substance use counseling",
    "Other",
  ];
  //-----------------------------State and Variable End----------------------------------------------- //

  //-----------------------------Functions Start------------------------------------------------------ //
  const filedownload = async (fileurl, id) => {
    var article = {
      file: patientdetails.note_file_url,
      id: patientdetails.note_add_edit,
      testurl: ServDocURL,
    };
    let encrypted = Encrypt_Value(article, "vozo");
    axiosInstance
      .put("vozo/PreviewDownloadNotes", encrypted)
      .then((response) => {
        var tag = document.createElement("a");
        tag.href = response.data.file;
        tag.download = response.data.filename;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);

        var articledelete = { id: id };
        let encrypteddelete = Encrypt_Value(articledelete, "vozo");
        axiosInstance
          .put("vozo/DeleteTempDocumentDataNotes", encrypteddelete)
          .then((response) => {});
      });
  };
  const handlesetSpecial = (data) => {
    setSpecial(data.value);
    if (data.value !== "") {
      setSpecialityErr(false);
    } else {
      setSpecialityErr(true);
    }
  };

  const OpenCard = () => {
    setopenCard(!openCard);
  };

  const setContentToParent = (value) => {
    setChangedContent(value);
  };

  const DeleteNote = () => {
    axiosInstance
      .get("/vozo/DeleteAppointment/Notes?app_id=" + App_id)
      .then((response) => {
        let temp_role_count_ser = Decrypt_Value(response.data, "vozo");
      })
      .catch((err) => console.error(err));
    closeTemplate();
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const formEdit = () => {
    setTemplateEdit(!templateEdit);
  };
  const saveFormHeader = () => {
    setTemplateName(templateTitle);
    formEdit();
  };
  const printpage = () => {
    var data = fref.current.setFromOutside();
    return data;
  };
  const savedocument = (e) => {
    e.preventDefault();

    var err = 0;
    if (e.target.name === "UnLock") {
      var temp = "";
    } else {
      var temp = printpage();
    }

    if (temp === undefined) {
      temp = "";
    }
    const stringWithoutSpacesAndNbsp = temp.replace(/(\s|&nbsp;)/g, "");

    if (templateName === "") {
      setDocNameErr(true);
      setTemplateEdit(true);
      err = 2;
    } else {
      setTemplateEdit(false);
    }

    if (
      specialty === "Select Specialty" ||
      specialty === "" ||
      specialty === undefined
    ) {
      setSpecialityErr(true);
      err = 2;
    } else {
      setSpecialityErr(false);
    }
    if (e.target.name !== "UnLock") {
      if (
        temp === "" ||
        temp === "<p></p>" ||
        temp === "<p><br></p>" ||
        stringWithoutSpacesAndNbsp.length === 7
      ) {
        setFormDataErr(true);

        err = 2;
      } else {
        setFormDataErr(false);
      }
    }
    if (err > 0) {
      setDisBtn(false);
      return false;
    }
    setDisBtn(true);
    let ForType;
    if (e.target.name) {
      if (e.target.name === "SignLock") {
        ForType = "SignLock";
      } else if (e.target.name === "UnLock") {
        ForType = "UnLock";
      }
    } else {
      ForType = null;
    }

    savetemplate(temp, templateName, Category, specialty, ForType);
    setTemplateName("");
    setTemplateTitle("");
    setDisBtn(true);
  };

  const setUseTemplate = () => {
    if (DataForUseTemplate !== "") {
      var article = { path: DataForUseTemplate, id: TemplateAppId };
      let encrypted = Encrypt_Value(article, "vozo");
      axiosInstance
        .put("/vozo/patient/get/Document/one", encrypted)
        .then((response) => {
          let data = Decrypt_Value(response.data, "vozo");
          if (data) {
            //   <button
            //   style="color: red; background: none; border: none; font-size: 16px; cursor: pointer; margin-left: 10px;"
            //   onclick="removeItem('${searchVal}')">
            //   ✖ Remove
            // </button>

            let Usecontent = `<!-- ~${searchVal}~ -->
              <hr>
              <p style="text-align: center;">
                <strong style="font-size: 18px;">${searchVal}</strong>
             
              </p>
              <br>
              ${data}
              <hr>`;

            setExtraContent(Usecontent);

            setOpenNewTemplate(false);
          } else {
          }
        });
    }
  };
  const setCategoryValue = (val) => {
    setCategory(val);
  };

  const disabledremove = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const inputElements = doc.querySelectorAll("input, select,img");
    inputElements.forEach((input) => {
      input.removeAttribute("disabled");
    });

    return doc.documentElement.outerHTML;
  };

  const addDisabled = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const inputElements = doc.querySelectorAll("input, select, img");

    inputElements.forEach((element) => {
      element.setAttribute("disabled", ""); // Set disabled normally
    });

    // Convert back to string and remove `disabled=""` with regex
    let modifiedHTML = doc.documentElement.outerHTML.replace(
      /\sdisabled=""/g,
      " disabled"
    );

    return modifiedHTML;
  };

  const changeMode = (value) => {
    if (value === "Editor") {
      var ChangedC = setStringreplace();

      setChangedContent(addDisabled(ChangedC));
    } else {
      var changedC = printpage();

      setChangedContent(disabledremove(changedC));
    }

    setMode(value);
  };

  const setStringreplace = () => {
    const inputValues = {};

    const inputElements = document.querySelectorAll("input, select");

    inputElements.forEach((inputElement) => {
      const inputId = inputElement.getAttribute("data-input-id");
      let inputValue = inputElement.value;

      if (inputElement.type === "radio" && inputElement.checked === true) {
        inputValue = inputElement.value;
        inputValues[inputId] = inputValue;
      } else if (inputElement.tagName === "SELECT" && inputElement.multiple) {
        const selectedValues = Array.from(inputElement.selectedOptions).map(
          (option) => option.value
        );
        inputValues[inputId] = selectedValues;
      } else if (inputElement.tagName === "SELECT") {
        inputValues[inputId] = inputValue;
      }

      if (inputElement.type !== "radio" && inputElement.tagName !== "SELECT") {
        inputValues[inputId] = inputValue;
      }
    });

    // Initialize an object to store the group indexes
    const modifiedStringEditSave = ChangedContent.replace(
      /<input[^>]+data-input-id="(\d+)"[^>]*>/g,
      (match, id) => {
        const inputValue = inputValues[id] || "";
        const isRadiobox = /class="radiobox"/.test(match);
        const isRadioboxGroup = /class="radioboxGroup"/.test(match);
        let isChecked = false;
        if (isRadiobox) {
          const radioMatch = match.match(/value="([^"]*)"/);
          if (radioMatch) {
            const radioValue = radioMatch[1];
            isChecked = inputValue === radioValue;
          }

          return match
            .replace(/checked="checked"/g, "")
            .replace(/>/, isChecked ? ' checked="checked">' : ">");
        }

        if (isRadioboxGroup) {
          const radioMatch = match.match(/data-input-id="([^"]*)"/);

          if (radioMatch !== null) {
            const radioDataInputId = radioMatch[1];

            if (inputValues[radioDataInputId] != null) {
              const radioValueMatch = match.match(/value="([^"]*)"/);
              const radioValue =
                radioValueMatch && radioValueMatch[1] !== ""
                  ? radioValueMatch[1]
                  : inputValues[radioDataInputId];

              isChecked = inputValues[radioDataInputId] === radioValue;
              return match
                .replace(/checked="checked"/g, "")
                .replace(
                  /value="[^"]*"/,
                  isChecked ? `value="${radioValue}"` : ""
                )
                .replace(/>/, isChecked ? ' checked="checked" >' : " >");
            }
          }
        }

        return match.replace(/value="[^"]*"/, `value="${inputValue}"`);
      }
    );

    const updatedStringWithSelect = modifiedStringEditSave.replace(
      /<select[^>]+id=["'](\d+)["'][^>]*>[\s\S]*?<\/select>/g,
      (match, id) => {
        const inputValue = inputValues[id]; // Get value by ID

        const selectedValues = Array.isArray(inputValue)
          ? inputValue
          : inputValue
          ? [inputValue]
          : []; // Handle empty value gracefully

        const updatedSelect = match.replace(
          /<option[^>]+value="([^"]+)"[^>]*>/g,
          (optionMatch, optionValue) => {
            const isSelected = selectedValues.includes(optionValue);
            return optionMatch
              .replace(/selected="selected"/g, "") // Remove existing selection
              .replace(">", isSelected ? ' selected="selected">' : ">");
          }
        );

        return updatedSelect;
      }
    );
    const targetContainer = document.querySelector(".target-container");
    if (targetContainer) {
      targetContainer.innerHTML = updatedStringWithSelect;
    }

    return updatedStringWithSelect;
  };

  //-----------------------------Functions End------------------------------------------------------ //

  //-----------------------------UseEffect Start--------------------------------------------------- //

  useEffect(() => {
    const handleInputChange = (event) => {
      const input = event.target;
      const id = input.getAttribute("data-input-id");
      const value = input.value;

      if (input.tagName === "SELECT" && input.type !== "radio") {
        if (input.multiple) {
          const selectedValues = Array.from(input.selectedOptions).map(
            (option) => option.value
          );

          setInputValues((prevValues) => ({
            ...prevValues,
            [id]: selectedValues,
          }));
        } else {
          const selectedValue = input.value;
          setInputValues((prevValues) => ({
            ...prevValues,
            [id]: selectedValue,
          }));
        }
      } else if (
        input.type === "radio" &&
        input.checked &&
        input.tagName !== "SELECT"
      ) {
        const radioGroupName = input.name;
        const radioButtons = document.querySelectorAll(
          `input[name="${radioGroupName}"]`
        );
        radioButtons.forEach((radio) => {
          if (radio !== input) {
            radio.removeAttribute("checked");
          }

          input.setAttribute("checked", "checked");
          if (radio.classList.contains("radioboxGroup")) {
            radio.setAttribute("value", "yes");
          }
        });
        setInputValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }));
      }
    };

    const attachEventListeners = () => {
      const selectInputs = document.querySelectorAll("select[data-input-id]");

      selectInputs.forEach((select) => {
        select.addEventListener("change", handleInputChange);
      });

      const radioBoxInputs = document.querySelectorAll(
        'input[type="radio"].radiobox[data-input-id]'
      );
      const radioBoxGroupInputs = document.querySelectorAll(
        'input[type="radio"].radioboxGroup[data-input-id]'
      );

      radioBoxInputs.forEach((input) => {
        input.addEventListener("change", handleInputChange);
      });

      radioBoxGroupInputs.forEach((input) => {
        input.addEventListener("change", handleInputChange);
      });

      const signatureImage = document.querySelector(
        "img.signature[data-input-id]"
      );

      return () => {
        selectInputs.forEach((select) => {
          select.removeEventListener("change", handleInputChange);
        });
        radioBoxInputs.forEach((input) => {
          input.removeEventListener("change", handleInputChange);
        });
        radioBoxGroupInputs.forEach((input) => {
          input.removeEventListener("change", handleInputChange);
        });
      };
    };

    const cleanup = attachEventListeners();

    const observer = new MutationObserver(() => {
      cleanup();
      attachEventListeners();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      cleanup();
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (eid !== "") {
      if (patientdetails.note_add_edit !== 0) {
        setChangedContent(content);
      } else {
        axiosInstance
          .get("/vozo/getNotes/DefaultData")
          .then((response) => {
            let temp_role_pat_det = Decrypt_Value(response.data, "vozo");

            if (
              Array.isArray(temp_role_pat_det) &&
              temp_role_pat_det.length === 0
            ) {
              setChangedContent("");
            } else {
              setChangedContent(temp_role_pat_det);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        setChangedContent(content);
      }
    } else {
      setChangedContent(content);
    }
  }, [content]);

  useEffect(() => {
    if (eid !== "") {
      if (patientdetails.note_add_edit !== 0) {
        axiosInstance
          .get("/vozo/patient/note/details?id=" + patientdetails.note_add_edit)
          .then((response) => {
            let temp_role_pat_det = Decrypt_Value(response.data, "vozo");

            setApp_id(temp_role_pat_det.app_id);
            setSpecial(temp_role_pat_det.speciality);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      await timeout(1000);

      if (!isCancelled) {
        let article = {
          name: searchVal,
          type: eid !== "" ? "Appointment" : "",
        };
        const encrypt = Encrypt_Value(article, "vozo");
        let apiUrl = "/vozo/search/documentdata?search=" + encrypt;
        await axiosInstance
          .get(apiUrl)
          .then((res) => {
            let decrypt_res = Decrypt_Value(res.data, "vozo");
            setSearchedData(decrypt_res);
          })
          .catch((err) => {
            setSearchedData([]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [searchVal]);

  useEffect(() => {
    if (EditDocName === undefined) {
      setTemplateName("");
      setTemplateTitle("");
    } else {
      setTemplateName(EditDocName);
      setTemplateTitle(EditDocName);
    }
  }, [EditDocName]);

  //-----------------------------UseEffect End---------------------------------------------------- //

  return (
    <>
      <Div
        height="48px"
        backgroundColor={globalColor}
        color="#FFFFFF"
        dis="flex"
        justifyContent="center"
        padding="0px 10px"
      >
        {!templateEdit ? (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <span className="consent-form-titlename">
                  {templateName === "" ? "New Document Template" : templateName}
                </span>
                {patientdetails.sign_lock !== 1 && (
                  <EditIcon onClick={formEdit} />
                )}
              </div>
              <img
                className="template_img_dropdown_close"
                src={CloseDoc}
                height="24px"
                width="24px"
                onClick={closeTemplate}
              />
            </div>
          </>
        ) : (
          <>
            <FormHeaderInput
              border={DocNameErr}
              onChange={(e) => {
                setTemplateTitle(e.target.value);
                setDocNameErr(false);
              }}
              value={templateTitle}
              style={{
                width: "90%",
                borderRadius: "4px",
                color: "black",
                outline: "none",
                border: "none",
              }}
            />
            {DocNameErr ? (
              <p className="errormessage Rev_count forTemp_nameErr">
                Please Fill Document Name
              </p>
            ) : (
              ""
            )}
            {templateTitle != "" && (
              <FormHeaderInputSaveBtn
                onClick={saveFormHeader}
                style={{
                  marginLeft: "32px",
                  padding: "3px 26px",
                  background: "#57bf7f",
                  borderRadius: "4px",
                  border: "0px",
                }}
              >
                Save
              </FormHeaderInputSaveBtn>
            )}

            <FormHeaderInputCloseBtn
              onClick={() => {
                formEdit();
              }}
              style={{
                height: "28.5px",
                border: "1px solid #dbdbdb",
                color: "#718096",
                borderRadius: "4px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
                marginLeft: "32px",
                paddingRight: "10px",
              }}
            >
              <FormHeaderInputCloseBtnIcon />
            </FormHeaderInputCloseBtn>
          </>
        )}
      </Div>
      <Div
        height="48px"
        color="#FFFFFF"
        dis="flex"
        justifyContent="space-between"
        padding="0px 10px"
      >
        {fromTemplate == "Dashboard" && (
          <Div dis="flex">
            {patientdetails.img_url !== null ? (
              <>
                <Image
                  src={generateFileURL(
                    fileTypes.profileimages,
                    patientdetails.img_url
                  )}
                  className="img-pat-bts_note"
                />
              </>
            ) : (
              <div className="patname_first img-pat-bts_note bts-pat-text">
                {patientdetails.fname.charAt(0).toUpperCase()}
              </div>
            )}
            <>
              <Div marginleft="10px">
                <Div color="#2E2E2E">
                  {patientdetails.fname}
                  {patientdetails.lname}
                </Div>
              </Div>
            </>
          </Div>
        )}

        {eid !== "" && (
          <Div dis="flex">
            {patientdetails.img_url !== null ? (
              <>
                <Image
                  src={generateFileURL(
                    fileTypes.profileimages,
                    patientdetails.img_url
                  )}
                  className="img-pat-bts_note"
                />
              </>
            ) : (
              <div className="patname_first img-pat-bts_note bts-pat-text">
                {patientdetails.uname.charAt(0).toUpperCase()}
              </div>
            )}
            <>
              <Div marginleft="10px">
                <Div color="#2E2E2E">{patientdetails.uname}</Div>
                <Div size="12px" color="#2E2E2E">
                  {moment(patientdetails.pc_eventDate).format("MMMM DD, YYYY")}{" "}
                  ,{" "}
                  {moment(patientdetails.pc_startTime, "HH:mm").format(
                    "h:mm a"
                  )}{" "}
                  - {moment(appt_endTime, "HH:mm").format("h:mm a")}
                </Div>
              </Div>
            </>
          </Div>
        )}
        {patientdetails.sign_lock === 1 ||
          (Mode !== "Viewer" && (
            <Select
              suffixIcon={<CustomSuffixIcon />}
              className={
                SpecialityErr
                  ? "ant_select_dropdown_alt style_for_category_appt ant-sel-err-border"
                  : "ant_select_dropdown_alt style_for_category_appt"
              }
              // defaultValue=""
              value={specialty}
              onChange={(e, data) => {
                handlesetSpecial(data);
              }}
              placeholder="Select Speciality"
            >
              <Option value="" title="Select Speciality">
                Select Speciality
              </Option>
              {specialtyList.map((item) => (
                <Option value={item} title={item}>
                  {item}
                </Option>
              ))}
            </Select>
          ))}
        {!isDisabled && (
          <Select
            suffixIcon={<CustomSuffixIcon />}
            className="ant_select_dropdown_alt "
            defaultValue="Encounter"
            value={Category}
            onSelect={(value) => {
              setCategoryValue(value);
            }}
            placeholder="Select Category"
            disabled={isDisabled}
          >
            <Option
              value="Encounter"
              disabled={fromTemplate == "Dashboard" ? true : false}
            >
              Encounter
            </Option>
            <Option value="Intake" disabled={isDisabled}>
              Intake
            </Option>
            <Option value="Assessment" disabled={isDisabled}>
              Assessment
            </Option>
            <Option value="Other" disabled={isDisabled}>
              Other
            </Option>
          </Select>
        )}
        <Div dis="flex" alignItems="center">
          <Select
            suffixIcon={<CustomSuffixIcon />}
            className="ant_select_dropdown_alt texteditore_button_color style_for_edit_viewer"
            placeholder="Editor"
            // disabled={true}
            disabled={
              patientdetails.sign_lock === 1 ||
              (fromTemplate !== "Dashboard" && eid === "")
                ? true
                : false
            }
            onChange={(value) => changeMode(value)}
          >
            <Option value="Editor">Editor</Option>
            {(fromTemplate === "Dashboard" || eid !== "") && (
              <Option value="Viewer">Viewer</Option>
            )}
          </Select>

          {patientdetails.sign_lock === 1 || Mode === "Viewer" ? (
            <></>
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setOpenNewTemplate(true);
              }}
            >
              Use Template
            </Button>
          )}
          {(fromTemplate === "Dashboard" || eid !== "") && content !== "" && (
            <>
              <Div cursor="pointer" onClick={(e) => OpenCard()}>
                <AiOutlineMore className="more_action_addd_note" />
              </Div>
              <DropdownContent
                marginLeft="-160px"
                width="179px"
                left="96%"
                top="10%"
                marginRight="16px"
                display={openCard}
              >
                <DropdownList
                  color="#2E2E2E"
                  id="list2"
                  onClick={() => {
                    filedownload();
                  }}
                  padding="0px 0px 3px 20px"
                >
                  Download
                </DropdownList>
                <DropdownList
                  onClick={() => DeleteNote()}
                  color="#E23D28"
                  id="list2"
                  padding="0px 0px 3px 20px"
                >
                  Delete
                </DropdownList>
              </DropdownContent>
            </>
          )}
        </Div>
      </Div>
      {FormDataErr ? (
        <p className="errormessage Rev_count m-l-10">
          Please Fill Form Details
        </p>
      ) : (
        ""
      )}
      <Div
        overflow={patientdetails.sign_lock === 1 ? "auto" : "none"}
        className="template_list_jodit_editor"
      >
        <Div height="450px" borderBottom=" 1px solid rgba(46, 46, 46, 0.25)">
          {patientdetails.sign_lock === 1 ? (
            <Div padding="20px" borderTop="1px solid rgba(46, 46, 46, 0.25)">
              <p
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </Div>
          ) : (
            <>
              {Mode === "Editor" ? (
                <EditDocument
                  ExtraContent={ExtraContent}
                  data={Mode === "Editor" ? ChangedContent : content}
                  fromWhere={
                    fromTemplate === "Notes"
                      ? "Notes"
                      : eid !== ""
                      ? "Appointment"
                      : ""
                  }
                  ref={fref}
                  Mode={Mode}
                  setContentToParent={setContentToParent}
                />
              ) : (
                <Div
                  padding="20px"
                  borderTop="1px solid rgba(46, 46, 46, 0.25)"
                  style={{
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                >
                  <p
                    style={{
                      maxWidth: "100%",
                      wordBreak: "break-word",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: ChangedContent,
                    }}
                  />
                </Div>
              )}
            </>
          )}
        </Div>
      </Div>
      <Div
        border={
          patientdetails.sign_lock === 1
            ? "1px solid #cbd5e0 !important"
            : "none"
        }
        alignItems="end"
        dis="flex"
        justifyContent="space-between"
        padding={
          patientdetails.sign_lock === 1
            ? "10px 10px 20px 20px"
            : "5px 10px 20px 20px"
        }
      >
        <>
          {patientdetails.sign_lock === 1 ? (
            <Div>
              {eid !== "" && (
                <Button
                  className="save_active  for_sign_lock_btn"
                  variant="primary"
                  name="UnLock"
                  onClick={savedocument}
                  disabled={disBtn || storedNames.notes_lock1_viewOnly}
                >
                  Unlock Note
                </Button>
              )}
            </Div>
          ) : (
            <>
              <Div>
                {eid !== "" && content !== "" && (
                  <Button
                    className="save_active  for_sign_lock_btn"
                    variant="primary"
                    name="SignLock"
                    onClick={savedocument}
                    disabled={disBtn || storedNames.notes_lock1_viewOnly}
                  >
                    Sign & Lock
                  </Button>
                )}
              </Div>

              {Mode === "Viewer" ? (
                <></>
              ) : (
                <Div marginleft="auto">
                  <Button
                    className="close_active add_edit_con_form_cancel_btn m-r-20"
                    variant="light"
                    onClick={() => {
                      closeTemplate();
                      setTemplateName("");
                      setTemplateTitle("");
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="save_active add_edit_con_form_cancel_btn"
                    variant="primary"
                    onClick={savedocument}
                    disabled={disBtn}
                  >
                    Save
                  </Button>
                </Div>
              )}
            </>
          )}
        </>
      </Div>
      <Modal
        className=" popup_centre edit-consent-editor-popup doctemp_bg_blur "
        centered
        show={openNewTemplate}
        onHide={() => {
          setOpenNewTemplate(false);
        }}
      >
        <Modal.Body
          className="ed-doc-mod-body  "
          id="cnst_frm-mod_bdy"
          style={{ padding: 0 }}
        >
          <div className={`dropdown-container blur-active `}>
            <TitleLabel className="no-blur">
              Choose a document template to continue
            </TitleLabel>

            <Div1
              dis="flex"
              alignItems="flex-start"
              margin="0 40px 0 0"
              cursor="pointer"
              background="#FFF"
              className="m-r-10-not-take no-blur"
            >
              <DropDown>
                <DropDownDiv
                  dis="flex"
                  color="#000000 !important"
                  id="setting_portal_value"
                  // onClick={patValChange}
                >
                  <AiOutlineSearch />
                  <TxtSpan nowrap width="200px" className="sel_not_take_span">
                    <Input1
                      className="note_search"
                      width="240px"
                      type="text"
                      placeholder="Search Template "
                      border="0px solid #cbd5e0"
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                      }}
                      value={searchVal}
                    />
                    {loading && (
                      <Dropdown>
                        <DropdownItem>
                          <PatientName>Loading...</PatientName>
                        </DropdownItem>
                      </Dropdown>
                    )}

                    <Dropdown>
                      {!loading ? (
                        searchedData.length > 0 ? (
                          searchedData.map((item, idx) => (
                            <DropdownItem
                              key={item.id}
                              onClick={() => {
                                setSearchVal(item.form_name);
                                setTemplateAppId(item.id);
                                setDataForUseTemplate(item.file_url);
                              }}
                            >
                              <PatientName>{item.form_name}</PatientName>
                            </DropdownItem>
                          ))
                        ) : (
                          <div>No Results Found</div>
                        )
                      ) : (
                        <div>Loading...</div>
                      )}
                    </Dropdown>
                  </TxtSpan>
                </DropDownDiv>
              </DropDown>
            </Div1>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-end" }}>
          <Button
            className="close_active"
            variant="light"
            onClick={() => {
              setOpenNewTemplate(false);
              setSearchVal("");
            }}
          >
            Cancel
          </Button>
          <Button
            className="save_active"
            variant="primary"
            onClick={setUseTemplate}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateWorkAreas;
