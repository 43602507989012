import "./App.css";
import "./Responsive.css";
import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "./axios/axios";
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import "antd/dist/antd.css";
import TopNav from "./components/top_navbar/TopNavbar";
import moment from "moment";
import AccountManagement from "./Modules/SubscriptionRenewal/AccountManagement";
import AccountVerification from "./Modules/SubscriptionRenewal/AccountVerification";
import VerificationDone from "./Modules/SubscriptionRenewal/VerificationDone";
import OtpVerification from "./Modules/SubscriptionRenewal/OtpVerification";
import TwoStepVerification from "./Modules/PreLogin/TwoStepVerification";
import Loader from "./components/popups/Loading";
import useAutoLogout from "./useAutoLogout";
import "./index.css";
import "./components/login/Login.css";
import "./components/schedule/styles.css";
import "./components/schedule/Schedule.css";
import "./components/patient/Patient.css";
import "./components/patient/stylesForm.css";
import "./components/billing/Billing.css";
import "./components/popups/Popups.css";
import "./components/report/report.css";
import "./components/setting/setting.css";
import { Decrypt_Value, Encrypt_Value } from "./MiddleWare/EncryptDecrypt";
import EncryptDecryptComp from "./components/schedule/EncryptDecrypt";
import WenoSupport from "./components/popups/WenoSupport";
import { fileTypes, generateFileURL } from "./axios/url.js";
import Aging from "./components/report/Aging.js";
import logout from "./logout.js";
const PriorAuthorization = lazy(() =>
  import("./components/report/PriorAuthorization")
);

const EligibilityReport = lazy(() =>
  import("./components/report/EligibilityReport")
);

const AppTemplate = lazy(() => import("./components/schedule/AppTemplate"));
const AppDashboard = lazy(() => import("./components/schedule/AppDashboard"));
const PatientFlag = lazy(() => import("./components/patient/PatientFlag"));
const SendErx = lazy(() => import("./components/patient/SendErx"));
const SendErxEdit = lazy(() => import("./components/patient/SendErxEdit"));
const AppProfiles = lazy(() => import("./components/schedule/AppProfiles"));
const BillingProfiles = lazy(() =>
  import("./components/schedule/BillingProfiles")
);
const ReferralManagement = lazy(() =>
  import("./Modules/Patient/ReferralManagement")
);

const PastMedicalHistory = lazy(() =>
  import("./Modules/Patient/PastMedicalHistory")
);

const BillingProfilesHistory = lazy(() =>
  import("./components/schedule/BillingProfilesHistory")
);
const AuditLog = lazy(() => import("./Modules/Schedule/AuditLog"));
const PracticeDashboard = lazy(() =>
  import("./Modules/Schedule/PracticeDashboard")
);
const Tasks = lazy(() => import("./components/popups/Tasks"));
const PatientDashboard = lazy(() =>
  import("./components/patient/PatientDashboard")
);
const CardTransactions = lazy(() =>
  import("./components/billing/CardTransactions")
);
const PatientPortalSetting = lazy(() =>
  import("./components/setting/PatientPortalSettings")
);
const PatientList = lazy(() => import("./components/patient/PatientList"));
const Demographics = lazy(() => import("./components/patient/Demographics"));
const Calender = lazy(() => import("./components/schedule/Calender"));

const Appointment = lazy(() => import("./components/patient/Appointment"));
const Documents = lazy(() => import("./components/patient/Documents"));
const Problems = lazy(() => import("./components/patient/Problems"));
// const FormLibrary = lazy(() => import("./components/patient/FormLibrary"));
const NoteTaking = lazy(() => import("./Modules/Patient/NoteTaking"));

const Incomingfax = lazy(() =>
  import("./components/patient/IncomingFax/index")
);
const OutgoingFax = lazy(() =>
  import("./components/patient/OutgoingFax/index")
);
const IncomingFaxindex = lazy(() =>
  import("./components/patient/IncomingFax/IncomingFaxindex")
);
const OutgoingFaxindex = lazy(() =>
  import("./components/patient/OutgoingFax/OutgoingFaxindex")
);
const PatientEducation = lazy(() =>
  import("./components/patient/PatientEducation")
);
const Allergies = lazy(() => import("./components/patient/Allergies"));
const Medication = lazy(() => import("./components/patient/Medication"));
const ClientPortalAccess = lazy(() =>
  import("./components/patient/ClientAccess")
);
const Lab = lazy(() => import("./components/patient/Lab"));
const FamilyHistory = lazy(() => import("./components/patient/FamilyHistory"));
const Immunization = lazy(() => import("./components/patient/Immunization"));
const EditNotes = lazy(() => import("./components/patient/EditNotes"));
const MedEditNote = lazy(() => import("./components/patient/MedEditNote"));
const ReviewEditNote = lazy(() =>
  import("./components/patient/ReviewEditNote")
);
const MedAllEditNote = lazy(() =>
  import("./components/patient/MedAllEditNote")
);
const PhysicalEditNote = lazy(() =>
  import("./components/patient/PhysicalEditNote")
);
const PlanEditNote = lazy(() => import("./components/patient/PlanEditNote"));
const AssessmentEditNote = lazy(() =>
  import("./components/patient/AssessmentEditNote")
);
const SubjectEditNote = lazy(() =>
  import("./components/patient/SubjectEditNote")
);
const ObjectEditNote = lazy(() =>
  import("./components/patient/ObjectEditNote")
);
const ManageMacroButton = lazy(() =>
  import("./components/patient/ManageMacroButtons")
);
// const DocumentTemplates = lazy(() =>
//   import("./components/patient/DocumentTemplates")
// );
const ElectronicReports = lazy(() =>
  import("./components/patient/ElectronicReports")
);
const ProcedureOrder = lazy(() =>
  import("./components/patient/ProcedureOrder")
);

const ClinicalReport = lazy(() => import("./Modules/Report/ClinicalReport"));

const NotificationSettings = lazy(() =>
  import("./components/setting/NotificationSettings")
);

// const FormB = lazy(() => import("./components/patient/FormBuilder"));

const PatientBillInsurance = lazy(() =>
  import("./components/patient/BillandInsurance")
);
const Previewnote = lazy(() => import("./components/patient/Previewnote"));
// const FormArchive = lazy(() => import("./components/patient/FormArchive"));

const Eob = lazy(() => import("./components/billing/Eob"));

const Era = lazy(() => import("./components/billing/Era"));
const ViewEratable = lazy(() => import("./components/billing/ViewEratable"));

const EobInsurance = lazy(() => import("./components/billing/EobInsurance"));

const PatientPaymentInvoice = lazy(() =>
  import("./components/billing/PatientPaymentInvoice")
);

const EobTableEdit = lazy(() => import("./components/billing/EobTableEdit"));

const ProductProcedure = lazy(() =>
  import("./components/report/ProductProcedure")
);

const ProductProcedures = lazy(() =>
  import("./components/report/ProductProcedures")
);

const Remittance = lazy(() => import("./components/report/Remittance"));

const UnderPaid = lazy(() => import("./components/report/UnderPaid"));

const Invoice = lazy(() => import("./components/report/Invoice"));

const Appointments = lazy(() =>
  import("./components/report/AppointmentReport")
);

const User = lazy(() => import("./components/report/User"));

const Reminder = lazy(() => import("./components/report/Reminder"));

const PatientReport = lazy(() => import("./components/report/PatientReport"));

const DaySheet = lazy(() => import("./components/report/DaySheet"));

const BillingSummary = lazy(() => import("./components/report/BillingSummary"));

const ProductivityReport = lazy(() =>
  import("./components/report/ProductivityReport")
);

const OutgoingPrescription = lazy(() =>
  import("./components/report/OutgoingPrescription")
);

const InsuranceClaim = lazy(() =>
  import("./components/billing/InsuranceClaim")
);

const GenerateForm = lazy(() =>
  import("./components/billing/GenerateClaimProcess")
);

const Dataimport = lazy(() => import("./components/setting/Dataimport"));

const PatientPayment = lazy(() =>
  import("./components/billing/PatientPayment")
);

const PatientStatement = lazy(() =>
  import("./components/billing/PatientStatement")
);

const FeeSchedule = lazy(() => import("./components/billing/FeeSchedule"));

const PatientBalance = lazy(() =>
  import("./components/billing/PatientBalance")
);

// const DenialAnalysis = lazy(() =>
//   import("./components/billing/DenialAnalysis")
// );

const SettingsMyProfile = lazy(() =>
  import("./components/setting/SettingsMyProfile")
);

const SettingPracticeInfo = lazy(() =>
  import("./components/setting/SettingPracticeInfo")
);

const Users = lazy(() => import("./components/setting/Users"));

const RolesMain = lazy(() => import("./components/setting/RolesMain"));

const Security = lazy(() => import("./components/setting/Security"));

const MyPlan = lazy(() => import("./components/setting/MyPlanNew"));

const SecurityDocument = lazy(() =>
  import("./components/setting/SecurityDocument")
);

const SettingBilling = lazy(() =>
  import("./components/setting/SettingBilling")
);

const SettingService = lazy(() =>
  import("./components/setting/SettingService")
);

const PayerSearch = lazy(() => import("./components/setting/PayerSearch"));

const CalenderSettings = lazy(() =>
  import("./components/setting/CalenderSettings")
);

const TimeZoneSettings = lazy(() =>
  import("./components/setting/TimeZoneSetting")
);

const AppointmentReminder = lazy(() =>
  import("./components/setting/AppointmentReminder")
);

const ERXSetting = lazy(() => import("./components/setting/ERXSetting"));

const Inventory = lazy(() => import("./components/setting/Inventory"));

const Templates = lazy(() => import("./components/setting/Templates"));

const LabSettings = lazy(() => import("./components/setting/Lab"));

const Login = lazy(() => import("./components/login/Login"));

const AccessDenied = lazy(() => import("./components/setting/AccessDenied"));

const Acknowledgement = lazy(() =>
  import("./components/setting/Acknowledgement")
);

const SubscriptionExpired = lazy(() =>
  import("./components/popups/SubscriptionExpired")
);

const DeleteAccount = lazy(() => import("./components/setting/DeleteAccount"));

const SecureData = lazy(() => import("./components/setting/SecureData"));

const UpgradeRequired = lazy(() =>
  import("./components/popups/UpgradeRequired")
);

const ForgotPassword = lazy(() => import("./components/login/ForgotPassword"));

const CheckYourEmail = lazy(() => import("./components/login/CheckYourEmail"));

const SetNewPassword = lazy(() => import("./components/login/SetNewPassword"));

const ResetSuccessfull = lazy(() =>
  import("./components/login/ResetSuccessfull")
);
// const useAutoLogout = lazy(() => import("./useAutoLogout"));

// const About = lazy(() => import('./routes/About'));
const App = () => {
  const [base64, setBase64] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [extension, setExtension] = useState("");
  let location_path = useLocation();
  let history = useHistory();
  const [idletime, setIdletime] = useState("");
  const token = localStorage.getItem("token");
  var storedNames = localStorage.getItem("roles");
  var expired_days =
    localStorage.getItem("expired_days") &&
    Decrypt_Value(localStorage.getItem("expired_days"), "vozo");

  var temp_user =
    localStorage.getItem("temp_user") &&
    JSON.parse(localStorage.getItem("temp_user").toLowerCase());
  var twoStepVerify =
    localStorage.getItem("twoStepVerify") &&
    Decrypt_Value(localStorage.getItem("twoStepVerify"), "vozo");

  if (storedNames) {
    storedNames = JSON.parse(
      JSON.stringify(Decrypt_Value(storedNames, "vozo"))
    );
  } else {
    storedNames = [];
  }
  // console.log(storedNames, "storedss");

  const idleTimer = useSelector((state) => state.Setting.idleTimer);

  useEffect(() => {
    setIdletime(localStorage.getItem("idle"));
  }, [idleTimer]);

  const idleinMin = parseInt(idletime) / 60;
  const timer = useAutoLogout(parseInt(idleinMin));
  const [client, setClient] = useState("");
  const [s_role, sets_role] = useState([]);
  const [account, setAccount] = useState("");
  const [trial, setTrial] = useState("");

  const [iframesrc, setIframeSrc] = useState("");
  const [noteLock, setNoteLock] = useState("");
  const [notedate, setNoteDate] = useState("");
  const [notedata, setNoteData] = useState([]);
  const [notedata1, setNoteData1] = useState([]);
  const [noteid, setNoteid] = useState("");
  const [nottime, setNoteTime] = useState("");
  const [noteinfo, setNoteInfo] = useState("");
  const ConfToken = localStorage.getItem("invoname")
    ? localStorage.getItem("invoname")
    : undefined;

  const SettingRef = useRef();
  const userid =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const [TasksRef, setTaskRef] = useState(0);

  const TopNavTasks = () => {
    setTaskRef((pre) => pre + 1);
  };

  const TopNavProps = () => {
    SettingRef.current.dataFetch();
  };

  const [mailAddress, setMailAddress] = useState("");
  const [reqDrop, setReqDrop] = useState(false);
  const [calRefresh, setCalRefresh] = useState(false);
  const [refid, setRefId] = useState("");
  var rememberMe =
    localStorage.getItem("auto_login") &&
    Decrypt_Value(localStorage.getItem("auto_login"), "vozo");

  const handleDropReq = () => {
    if (!reqDrop) {
      setRefId("");
    }
    setReqDrop(!reqDrop);
  };

  const handleRefCalls = () => {
    setCalRefresh(!calRefresh);
  };

  const handleframesrc = (a) => {
    setIframeSrc(a);
  };
  const handleNotesData = (b, c, d, e, f, g) => {
    setNoteDate(b);
    setNoteData(c);
    setNoteData1(d);
    setNoteid(e);
    setNoteTime(f);
    setNoteInfo(g);
  };

  const [refreshList, setRefreshList] = useState(true);

  const hideTopNavbar = useSelector(
    (state) => state.ScheduleWidget.inputValue.hideTopNavbar
  );
  const refreshPatient = () => {
    setRefreshList(!refreshList);
  };

  const handleNotesLock = (x) => {
    setNoteLock(x);
  };

  useEffect(() => {
    if (rememberMe && location_path.pathname !== "/") {
      let path_encrypted = Encrypt_Value(location_path.pathname, "vozo");
      localStorage.setItem("last_location", path_encrypted);
    }
  }, [location_path]);

  useEffect(() => {
    let last_path =
      localStorage.getItem("last_location") &&
      Decrypt_Value(localStorage.getItem("last_location"), "vozo");

    if (rememberMe && last_path !== null && last_path !== "/null") {
      history.push(`${last_path}`);
    }

    var uid =
      localStorage.getItem("user_id") &&
      Decrypt_Value(localStorage.getItem("user_id"), "vozo");
    // if (token) {
    //   axiosInstance
    //     .get("/vozo/getinvoice/id?uid=" + uid)
    //     .then((response) => {
    //       setConfToken(response.data);
    //       localStorage.setItem("invoname", response.data);
    //     })
    //     .catch((err) => setConfToken(err.message));
    // }
    // schedule
    var appt1 = false;
    var viewAuditLog = false;
    var accessPracticeDashboard = false;
    var setProviderAvailability = false;
    var manageAppointment_BillingProfile = false;

    // settings
    var manageAccountSettings = false;
    var configureBillingServices = false;
    var setSchedulingPreferences = false;
    var manageAdministrationSettings = false;

    // patient
    var managePatientEducation = false;
    let processCardTransactions = false;
    //reports
    var rep1 = false;
    var rep2 = false;
    var rep3 = false;

    var demo = false;
    var appt = false;
    var mesg = false;
    var notes = false;
    var drugs = false;
    var bill = false;
    var coding = false;
    var rep = false;
    var bill_a = false;
    var pat_bal = false;

    var users = false;
    var users_s = false;
    var bill_admin = false;
    var acl = false;
    var pat_statement = false;
    var bill_led = false;
    var notes_lock = false;
    var appt_a = false;
    var ipad = false;
    var erx = false;

    var contacts = false;
    var pat_g = false;
    var pat_analytics = false;
    var gadd = false;
    var pat_share = false;
    var pat_emergency = false;
    var m_templete = false;
    var ins_claims = false;
    var gm = false;

    var bill_s = false;
    var finance_reports = false;
    var ref_source = false;
    var practice_groups = false;
    var patient_referral = false;
    var show_notification = false;
    var fax = false;

    // viewOnly variable

    let managePatientEducation_viewOnly = false;
    let accessPracticeDashboard_viewOnly = false;
    let setProviderAvailability_viewOnly = false;
    let manageAppointment_BillingProfile_viewOnly = false;
    let postPayments_viewOnly = false;
    let postPayments = false;

    let processCardTransactions_viewOnly = false;
    let rep2_viewOnly = false;
    let rep3_viewOnly = false;
    let manageAccountSettings_viewOnly = false;
    let configureBillingServices_viewOnly = false;
    let viewAuditLog_viewOnly = false;

    let setSchedulingPreferences_viewOnly = false;
    let manageAdministrationSettings_viewOnly = false;
    let fax_perm_viewOnly = false;
    let patientRef_viewOnly = false;
    let show_noti_viewOnly = false;
    let m_templete1_viewOnly = false;
    let pat_g1_viewOnly = false;
    let contacts1_viewOnly = false;
    let appt_a1_viewOnly = false;
    let notes_lock1_viewOnly = false;
    let bill_led1_viewOnly = false;
    let pat_statement1_viewOnly = false;
    let bill_a1_viewOnly = false;
    let rep1_viewOnly = false;
    let coding1_viewOnly = false;

    let ins_claims1_viewOnly = false;
    let notes1_viewOnly = false;
    let mesg1_viewOnly = false;
    let appt1_viewOnly = false;
    let demo1_viewOnly = false;

    if (storedNames != null) {
      for (let i = 0; i < storedNames.length; i++) {
        //new start

        //viewOnly
        //schedule

        // schedule module
        if (
          storedNames[i].value === "appt_v" ||
          storedNames[i].value === "appt_v_viewOnly"
        ) {
          appt = true;
        }
        if (
          storedNames[i].value === "accessPracticeDashboard" ||
          storedNames[i].value === "accessPracticeDashboard_viewOnly"
        ) {
          accessPracticeDashboard = true;
        }
        if (
          storedNames[i].value === "setProviderAvailability" ||
          storedNames[i].value === "setProviderAvailability_viewOnly"
        ) {
          setProviderAvailability = true;
        }
        if (
          storedNames[i].value === "manageAppointment_BillingProfile" ||
          storedNames[i].value === "manageAppointment_BillingProfile_viewOnly"
        ) {
          manageAppointment_BillingProfile = true;
        }
        if (
          storedNames[i].value === "viewAuditLog" ||
          storedNames[i].value === "viewAuditLog_viewOnly"
        ) {
          viewAuditLog = true;
        }

        if (storedNames[i].value === "appt_v_viewOnly") {
          appt1_viewOnly = true;
        }
        if (storedNames[i].value === "accessPracticeDashboard_viewOnly") {
          accessPracticeDashboard_viewOnly = true;
        }
        if (storedNames[i].value === "setProviderAvailability_viewOnly") {
          setProviderAvailability_viewOnly = true;
        }
        if (
          storedNames[i].value === "manageAppointment_BillingProfile_viewOnly"
        ) {
          manageAppointment_BillingProfile_viewOnly = true;
        }
        if (storedNames[i].value === "viewAuditLog_viewOnly") {
          viewAuditLog_viewOnly = true;
        }

        //reports
        if (storedNames[i].value === "rep_v_viewOnly") {
          rep1_viewOnly = true;
        }
        if (storedNames[i].value === "rep_v_2_viewOnly") {
          rep2_viewOnly = true;
        }
        if (storedNames[i].value === "rep_v_3_viewOnly") {
          rep3_viewOnly = true;
        }

        //billing

        //other functionality
        if (storedNames[i].value === "pat_g_viewOnly") {
          pat_g1_viewOnly = true;
        }
        if (storedNames[i].value === "appt_a_viewOnly") {
          appt_a1_viewOnly = true;
        }
        if (storedNames[i].value === "fax_viewOnly") {
          fax_perm_viewOnly = true;
        }
        if (storedNames[i].value === "show_notification_viewOnly") {
          show_noti_viewOnly = true;
        }
        if (storedNames[i].value === "mesg_viewOnly") {
          mesg1_viewOnly = true;
        }

        //fullAccess

        // settings
        if (
          storedNames[i].value === "manageAccountSettings" ||
          storedNames[i].value === "manageAccountSettings_viewOnly"
        ) {
          manageAccountSettings = true;
        }
        if (
          storedNames[i].value === "configureBillingServices" ||
          storedNames[i].value === "configureBillingServices_viewOnly"
        ) {
          configureBillingServices = true;
        }
        if (
          storedNames[i].value === "setSchedulingPreferences" ||
          storedNames[i].value === "setSchedulingPreferences_viewOnly"
        ) {
          setSchedulingPreferences = true;
        }
        if (
          storedNames[i].value === "manageAdministrationSettings" ||
          storedNames[i].value === "manageAdministrationSettings_viewOnly"
        ) {
          manageAdministrationSettings = true;
        }
        // settings
        if (storedNames[i].value === "manageAccountSettings_viewOnly") {
          manageAccountSettings_viewOnly = true;
        }
        if (storedNames[i].value === "configureBillingServices_viewOnly") {
          configureBillingServices_viewOnly = true;
        }
        if (storedNames[i].value === "setSchedulingPreferences_viewOnly") {
          setSchedulingPreferences_viewOnly = true;
        }
        if (storedNames[i].value === "manageAdministrationSettings_viewOnly") {
          manageAdministrationSettings_viewOnly = true;
        }

        //patient
        if (
          storedNames[i].value === "managePatientEducation" ||
          storedNames[i].value === "managePatientEducation_viewOnly"
        ) {
          managePatientEducation = true;
        }
        //patient
        if (storedNames[i].value === "demo_v_viewOnly") {
          demo1_viewOnly = true;
        }
        if (storedNames[i].value === "contacts_viewOnly") {
          contacts1_viewOnly = true;
        }
        if (storedNames[i].value === "managePatientEducation_viewOnly") {
          managePatientEducation_viewOnly = true;
        }
        if (storedNames[i].value === "notes_viewOnly") {
          notes1_viewOnly = true;
        }
        if (storedNames[i].value === "notes_lock_viewOnly") {
          notes_lock1_viewOnly = true;
        }
        if (storedNames[i].value === "m_templete_viewOnly") {
          m_templete1_viewOnly = true;
        }
        if (storedNames[i].value === "patient_referral_viewOnly") {
          patientRef_viewOnly = true;
        }

        //reports
        if (storedNames[i].value === "rep_v") {
          rep1 = true;
        }
        if (storedNames[i].value === "rep_v_2") {
          rep2 = true;
        }
        if (storedNames[i].value === "rep_v_3") {
          rep3 = true;
        }
        //billing

        if (storedNames[i].value === "bill_a_viewOnly") {
          bill_a1_viewOnly = true;
        }
        if (
          storedNames[i].value === "processCardTransactions" ||
          storedNames[i].value === "processCardTransactions_viewOnly"
        ) {
          processCardTransactions = true;
        }
        if (storedNames[i].value === "processCardTransactions_viewOnly") {
          processCardTransactions_viewOnly = true;
        }
        if (
          storedNames[i].value === "postPayments" ||
          storedNames[i].value === "postPayments_viewOnly"
        ) {
          postPayments = true;
        }
        if (storedNames[i].value === "postPayments_viewOnly") {
          postPayments_viewOnly = true;
        }
        if (storedNames[i].value === "bill_led_viewOnly") {
          bill_led1_viewOnly = true;
        }
        if (storedNames[i].value === "pat_statement_viewOnly") {
          pat_statement1_viewOnly = true;
        }
        if (storedNames[i].value === "coding_v_viewOnly") {
          coding1_viewOnly = true;
        }
        if (storedNames[i].value === "ins_claims_viewOnly") {
          ins_claims1_viewOnly = true;
        }
        //new start end

        if (
          storedNames[i].value === "demo_v" ||
          storedNames[i].value === "demo_v_viewOnly"
        ) {
          demo = true;
        }

        if (storedNames[i].value === "appt_v") {
          appt1 = true;
        }
        if (
          storedNames[i].value === "mesg" ||
          storedNames[i].value === "mesg_viewOnly"
        ) {
          mesg = true;
        }
        if (
          storedNames[i].value === "notes_v" ||
          storedNames[i].value === "notes_viewOnly"
        ) {
          notes = true;
        }
        if (storedNames[i].value === "drugs_v") {
          drugs = true;
        }
        if (storedNames[i].value === "bill_v") {
          var bill = true;
        }
        if (
          storedNames[i].value === "coding_v" ||
          storedNames[i].value === "coding_v_viewOnly"
        ) {
          var coding = true;
        }
        if (storedNames[i].value === "rep_v") {
          var rep = true;
        }
        if (
          storedNames[i].value === "bill_a" ||
          storedNames[i].value === "bill_a_viewOnly"
        ) {
          bill_a = true;
        }
        if (storedNames[i].value === "bill_s") {
          var bill_s = true;
        }
        if (storedNames[i].value === "pat_bal") {
          var pat_bal = true;
        }
        if (storedNames[i].value === "users_v") {
          var users = true;
        }
        if (storedNames[i].value === "users_s") {
          var users_s = true;
        }
        if (storedNames[i].value === "bill_admin") {
          var bill_admin = true;
        }
        if (storedNames[i].value === "acl_v") {
          var acl = true;
        }
        if (
          storedNames[i].value === "pat_statement" ||
          storedNames[i].value === "pat_statement_viewOnly"
        ) {
          var pat_statement = true;
        }
        if (
          storedNames[i].value === "bill_led" ||
          storedNames[i].value === "bill_led_viewOnly"
        ) {
          var bill_led = true;
        }
        if (
          storedNames[i].value === "notes_lock" ||
          storedNames[i].value === "notes_lock_viewOnly"
        ) {
          notes_lock = true;
        }
        if (
          storedNames[i].value === "appt_a" ||
          storedNames[i].value === "appt_a_viewOnly"
        ) {
          var appt_a = true;
        }
        if (storedNames[i].value === "ipad") {
          var ipad = true;
        }
        if (storedNames[i].value === "erx") {
          var erx = true;
        }
        if (
          storedNames[i].value === "contacts" ||
          storedNames[i].value === "contacts_viewOnly"
        ) {
          contacts = true;
        }
        if (
          storedNames[i].value === "pat_g" ||
          storedNames[i].value === "pat_g_viewOnly"
        ) {
          var pat_g = true;
        }
        if (storedNames[i].value === "pat_analytics") {
          var pat_analytics = true;
        }
        if (storedNames[i].value === "gadd_v") {
          var gadd = true;
        }
        if (storedNames[i].value === "pat_share") {
          var pat_share = true;
        }
        if (storedNames[i].value === "pat_emergency") {
          var pat_emergency = true;
        }
        if (
          storedNames[i].value === "m_templete" ||
          storedNames[i].value === "m_templete_viewOnly"
        ) {
          m_templete = true;
        }

        if (
          storedNames[i].value === "ins_claims" ||
          storedNames[i].value === "ins_claims_viewOnly"
        ) {
          var ins_claims = true;
        }
        if (storedNames[i].value === "gm_v") {
          var gm = true;
        }
        if (storedNames[i].value === "finance_reports") {
          var finance_reports = true;
        }
        if (storedNames[i].value === "ref_source") {
          var ref_source = true;
        }
        if (storedNames[i].value === "practice_groups") {
          var practice_groups = true;
        }

        if (
          storedNames[i].value === "patient_referral" ||
          storedNames[i].value === "patient_referral_viewOnly"
        ) {
          patient_referral = true;
        }
        if (
          storedNames[i].value === "show_notification" ||
          storedNames[i].value === "show_notification_viewOnly"
        ) {
          var show_notification = true;
        }
        if (
          storedNames[i].value === "fax" ||
          storedNames[i].value === "fax_viewOnly"
        ) {
          var fax = true;
        }
      }
    }

    var permis = {
      appt1: appt1,
      viewAuditLog: viewAuditLog,
      manageAppointment_BillingProfile: manageAppointment_BillingProfile,
      setProviderAvailability: setProviderAvailability,
      accessPracticeDashboard: accessPracticeDashboard,

      demo: demo,
      appt: appt,
      mesg: mesg,
      notes: notes,
      drugs: drugs,
      bill: bill,
      coding: coding,
      rep: rep,
      bill_a: bill_a,
      pat_bal: pat_bal,
      users: users,
      users_s: users_s,
      bill_admin: bill_admin,
      acl: acl,
      pat_statement: pat_statement,
      bill_led: bill_led,
      notes_lock: notes_lock,
      appt_a: appt_a,
      ipad: ipad,
      erx: erx,
      contacts: contacts,
      pat_g: pat_g,
      pat_analytics: pat_analytics,
      gadd: gadd,
      pat_share: pat_share,
      pat_emergency: pat_emergency,
      m_templete: m_templete,
      ins_claims: ins_claims,
      gm: gm,
      bill_s: bill_s,
      finance_reports: finance_reports,
      ref_source: ref_source,
      practice_groups: practice_groups,
      show_notification: show_notification,
      patient_referral: patient_referral,
      fax: fax,

      rep3: rep3,
      rep2: rep2,
      rep1: rep1,
      managePatientEducation: managePatientEducation,
      manageAdministrationSettings: manageAdministrationSettings,
      setSchedulingPreferences: setSchedulingPreferences,
      configureBillingServices: configureBillingServices,
      manageAccountSettings: manageAccountSettings,

      //viewOnly
      viewAuditLog_viewOnly: viewAuditLog_viewOnly,
      configureBillingServices_viewOnly: configureBillingServices_viewOnly,
      manageAccountSettings_viewOnly: manageAccountSettings_viewOnly,
      rep3_viewOnly: rep3_viewOnly,
      rep2_viewOnly: rep2_viewOnly,
      processCardTransactions: processCardTransactions,
      processCardTransactions_viewOnly: processCardTransactions_viewOnly,
      postPayments_viewOnly: postPayments_viewOnly,
      postPayments: postPayments,
      manageAppointment_BillingProfile_viewOnly:
        manageAppointment_BillingProfile_viewOnly,
      setProviderAvailability_viewOnly: setProviderAvailability_viewOnly,
      accessPracticeDashboard_viewOnly: accessPracticeDashboard_viewOnly,
      managePatientEducation_viewOnly: managePatientEducation_viewOnly,

      coding1_viewOnly: coding1_viewOnly,
      rep1_viewOnly: rep1_viewOnly,
      bill_a1_viewOnly: bill_a1_viewOnly,
      pat_statement1_viewOnly: pat_statement1_viewOnly,
      bill_led1_viewOnly: bill_led1_viewOnly,
      notes_lock1_viewOnly: notes_lock1_viewOnly,
      appt_a1_viewOnly: appt_a1_viewOnly,
      contacts1_viewOnly: contacts1_viewOnly,
      pat_g1_viewOnly: pat_g1_viewOnly,
      m_templete1_viewOnly: m_templete1_viewOnly,
      show_noti_viewOnly: show_noti_viewOnly,
      patientRef_viewOnly: patientRef_viewOnly,
      fax_perm_viewOnly: fax_perm_viewOnly,
      manageAdministrationSettings_viewOnly:
        manageAdministrationSettings_viewOnly,
      setSchedulingPreferences_viewOnly: setSchedulingPreferences_viewOnly,

      demo1_viewOnly: demo1_viewOnly,
      appt1_viewOnly: appt1_viewOnly,
      mesg1_viewOnly: mesg1_viewOnly,
      notes1_viewOnly: notes1_viewOnly,
      ins_claims1_viewOnly: ins_claims1_viewOnly,
    };

    let permis_encrypt = Encrypt_Value(permis, "vozo");
    localStorage.setItem("setroles", permis_encrypt);
    sets_role(permis);
    if (token) {
      axiosInstance
        .get("/vozo/provider/accounttype?uid=" + uid)
        .then((res) => {
          let data = Decrypt_Value(res.data, "vozo");
          setAccount(data.account_type);
          setTrial(data.valid_upto_date);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  useEffect(() => {
    let logo = "";
    let imgfile = "";
    const fetchNotesData = async () => {
      try {
        const res = await axiosInstance.post("/vozo/settingClinicalNotes");
        let temp_role = Decrypt_Value(res.data, "vozo");
        logo = temp_role[3]["gl_value"];
        let logoExtension = logo.split(".");
        let getExtension = logoExtension[1];
        setExtension(logo);
        setNotesData(temp_role);

        if (logo) {
          imgfile = {
            url: generateFileURL(fileTypes.profileimages, logo),
          };
          await fetchImageBase64(logo);
        }
      } catch (error) {
        setNotesData([]);
      }
    };
    if (token) {
      fetchNotesData();
    }

    const fetchImageBase64 = async () => {
      try {
        const res = await axiosInstance.post(
          "/vozo/document/img/base64",
          imgfile
        );
        setBase64(res.data);
      } catch (error) {
        setBase64("");
      }
    };
  }, [localStorage.getItem("NewEncounter")]);

  let subPayment =
    localStorage.getItem("subpaid_status") &&
    Decrypt_Value(localStorage.getItem("subpaid_status"), "vozo");

  if (timer == 0) {
    logout(userid);
  } else {
    ////////////////////session check code starts here//////////////////////////////////////////////////
    const CurrTime = moment().utc();
    const [sendToContinue, setSendToContinue] = useState("");
    const [callLogin, setCallLogin] = useState(0);

    const token_exp_time = moment(localStorage.getItem("login_time"));
    if (token_exp_time.isBefore(CurrTime)) {
      logout(userid);
    }
    ////////////////////session check code ends here//////////////////////////////////////////////////
    // if (!token && client === "loading" && !hideTopNavbar) {
    //   return <Loader />;
    // } else if (client === "fail" && !hideTopNavbar) {
    //   return (
    //     <Switch>
    //       <Route exact path="/" render={() => <ClientAccessDenied />} />
    //     </Switch>
    //   );
    // } else {
    if (
      !token ||
      ConfToken === "Request failed with status code 401" ||
      hideTopNavbar
    ) {
      return (
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route
              exact
              path="/login/ForgotPassword"
              render={() => (
                <ForgotPassword
                  saveMail={(x) => {
                    setMailAddress(x);
                    history.push("/login/checkyouremail");
                  }}
                />
              )}
            />
            <Route
              exact
              path="/login/checkyouremail"
              render={() => <CheckYourEmail mailID={mailAddress} />}
            />
            <Route
              exact
              path="/login/SetNewPassword/:code/:id"
              render={() => (
                <SetNewPassword sendDetails={(x) => setSendToContinue(x)} />
              )}
            />
            <Route
              exact
              path="/login/ResetSuccessfull"
              render={() => (
                <ResetSuccessfull
                  sendData={sendToContinue}
                  runLogin={() => setCallLogin((pre) => pre + 1)}
                />
              )}
            />

            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      );
    } else if (twoStepVerify === "1") {
      return (
        <Switch>
          <Route
            exact
            path="/twostepverify"
            render={() => <TwoStepVerification />}
          />
        </Switch>
      );
    } else if (token && expired_days < -120 && !temp_user) {
      return (
        <Switch>
          <Route exact path="/" render={() => <AccountManagement />} />
          <Route
            exact
            path="/accountverify"
            render={() => <AccountVerification />}
          />
          <Route
            exact
            path="/otpverification"
            render={() => <OtpVerification />}
          />
          <Route
            exact
            path="/verificationdone"
            render={() => <VerificationDone />}
          />
        </Switch>
      );
    } else if (
      token &&
      (ConfToken !== undefined ||
        ConfToken !== "Request failed with status code 401") &&
      subPayment.status === "paid"
    )
      return (
        <div className="App">
          {!hideTopNavbar && (
            <TopNav
              ref={SettingRef}
              tref={TasksRef}
              calRefHandle={handleRefCalls}
              reqDrop={reqDrop}
              DropReq={handleDropReq}
              refid={reqDrop ? refid : ""}
              refreshPatient={refreshPatient}
            />
          )}
          <div className="app-body">
            <Suspense fallback={<Loader />}>
              <Switch>
                {s_role.notes ? (
                  <Route
                    exact
                    path="/clinicalnotes"
                    render={() => (
                      <EditNotes
                        setSrc={(a) => {
                          handleframesrc(a);
                        }}
                        notelock={noteLock}
                        handledata={(b, c, d, e, f, g) => {
                          handleNotesData(b, c, d, e, f, g);
                        }}
                        source={iframesrc}
                        lock={noteLock}
                        setLock={(x) => {
                          handleNotesLock(x);
                        }}
                        rows={noteinfo}
                        encounter={noteid}
                        data={notedata}
                        data1={notedata1}
                        st={nottime}
                        date={notedate}
                        base64={base64}
                        notesData={notesData}
                        extension={extension}
                      />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path="/clinicalnotes"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.notes ? (
                  <Route
                    exact
                    path="/clinicalnotes/previewnote"
                    render={() => (
                      <Previewnote
                        base64={base64}
                        notesData={notesData}
                        extension={extension}
                        source={iframesrc}
                        lock={noteLock}
                        setLock={(x) => {
                          handleNotesLock(x);
                        }}
                        setSrc={(x) => {
                          handleframesrc(x);
                        }}
                        rows={noteinfo}
                        encounter={noteid}
                        data={notedata}
                        data1={notedata1}
                        st={nottime}
                        date={notedate}
                      />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path="/clinicalnotes/previewnote"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.configureBillingServices ? (
                  <Route
                    exact
                    path="/settings/payersearch"
                    render={() => <PayerSearch />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/payersearch"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/securitydocument"
                    render={() => <SecurityDocument />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/securitydocument"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.managePatientEducation ? (
                  <Route
                    exact
                    path="/patient/patienteducation"
                    render={() => <PatientEducation />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/patienteducation"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.accessPracticeDashboard ? (
                  <Route
                    exact
                    path="/schedule/appdashboard"
                    render={() => <AppDashboard />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/appdashboard"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* saravanana 31.3.22 8:00 start */}
                {(account != "0" && account <= "3") ||
                (account == "0" && trial == true) ? (
                  <Route
                    exact
                    path="/popups/Tasks"
                    render={() => <Tasks TaskRef={TopNavTasks} />}
                  />
                ) : (
                  <Route
                    exact
                    path="/popups/Tasks"
                    render={() => <UpgradeRequired />}
                  />
                )}
                {/* saravanana 31.3.22 8:00 end */}

                {/* ----------------------------Common Pages End---------------------------------------------------- */}

                {/* --------------------------------------Demo Start------------------------------ */}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/patient/nav/:nav"
                    render={() => <PatientList refreshList={refreshList} />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/nav/:nav"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/patient/patientlist"
                    render={() => <PatientList refreshList={refreshList} />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/patientlist"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* saravanana 31.3.22 8:00 start */}
                {/* { ? ( */}
                <Route
                  exact
                  path="/patient/patientflag"
                  render={() => <PatientFlag />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/patientflag"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* saravanana 31.3.22 8:00 end */}

                {/* {s_role.m_templete ? (
                  <Route
                    exact
                    path="/patient/DocumentTemplates"
                    render={() => <DocumentTemplates />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/DocumentTemplates"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                {/* <Route
                  exact
                  path="/patient/formarchive"
                  render={() => <FormArchive />}
                /> */}
                {/* ) : (
                  <Route
                    exact
                    path="/patient/formarchive"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/demographics/:pid/"
                  render={() => <Demographics />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/demographics/:pid/"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/appointment/:pid"
                  render={() => <Appointment />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/appointment/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/billandInsurance/:pid"
                  render={() => <PatientBillInsurance />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/billandInsurance/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/problems/:pid"
                  render={() => <Problems />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/problems/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/allergies/:pid"
                  render={() => <Allergies />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/allergies/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/medication/:pid"
                  render={() => <Medication />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/medication/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route exact path="/patient/lab/:pid" render={() => <Lab />} />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/lab/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/familyhistory/:pid"
                  render={() => <FamilyHistory />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/familyhistory/:pid"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/pastmedicalhistory/:pid"
                  render={() => <PastMedicalHistory />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/pastmedicalhistory/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* {s_role.demo ||
                  ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? (
                    <Route
                      exact
                      path="/patient/pastmedicalhistory/:pid"
                      render={() => <PastMedicalHistory />}
                    />
                  ) : (
                    <Route
                      exact
                      path="/patient/pastmedicalhistory/:pid"
                      render={() => <AccessDenied />}
                    />
                  )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/immunization/:pid"
                  render={() => <Immunization />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/immunization/:pid"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/documents/:pid"
                  render={() => <Documents />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/documents/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* {s_role.demo && s_role.pat_emergency ? ( */}
                <Route
                  exact
                  path="/patient/managemacrobutton"
                  render={() => <ManageMacroButton />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/managemacrobutton"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* saravanana 31.3.22 8:00 start */}
                {/* {s_role.demo || (s_role.demo && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/electronicreports"
                  render={() => <ElectronicReports />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/electronicreports"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/procedureorder/:pid"
                  render={() => <ProcedureOrder />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/procedureorder/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* saravanana 31.3.22 8:00 end */}

                {/* {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/patient/clientportalaccess/:pid"
                  render={() => <ClientPortalAccess />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/clientportalaccess/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* {s_role.m_templete ? (
                  <Route
                    exact
                    path="/patient/formlibrary"
                    render={() => <FormLibrary />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/formlibrary"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {s_role.notes ? (
                  <Route
                    exact
                    path="/patient/notetaking"
                    render={() => <NoteTaking />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/notetaking"
                    render={() => <AccessDenied />}
                  />
                )}
                {/*                 
                {s_role.demo ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ||
                s_role.pat_bal ? ( */}
                <Route
                  exact
                  path="/patient/patientdashboard/:pid"
                  render={() => <PatientDashboard />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/patient/patientdashboard/:pid"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {s_role.demo ? (
                  <Route
                    exact
                    path="/mednotes"
                    render={() => <MedEditNote />}
                  />
                ) : (
                  <Route
                    exact
                    path="/mednotes"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/review"
                    render={() => <ReviewEditNote />}
                  />
                ) : (
                  <Route exact path="/review" render={() => <AccessDenied />} />
                )}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/medall"
                    render={() => <MedAllEditNote />}
                  />
                ) : (
                  <Route exact path="/medall" render={() => <AccessDenied />} />
                )}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/physical"
                    render={() => <PhysicalEditNote />}
                  />
                ) : (
                  <Route
                    exact
                    path="/physical"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.demo ? (
                  <Route exact path="/plan" render={() => <PlanEditNote />} />
                ) : (
                  <Route exact path="/plan" render={() => <AccessDenied />} />
                )}
                {s_role.demo ? (
                  <Route
                    exact
                    path="/assessment"
                    render={() => <AssessmentEditNote />}
                  />
                ) : (
                  <Route
                    exact
                    path="/assessment"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.demo ? (
                  <Route
                    exact
                    path="/subject"
                    render={() => <SubjectEditNote />}
                  />
                ) : (
                  <Route
                    exact
                    path="/subject"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.demo ? (
                  <Route
                    exact
                    path="/object"
                    render={() => <ObjectEditNote />}
                  />
                ) : (
                  <Route exact path="/object" render={() => <AccessDenied />} />
                )}

                {s_role.fax ? (
                  <Route
                    exact
                    path="/patient/IncomingFax"
                    render={() => <Incomingfax />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/IncomingFax"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.fax ? (
                  <Route
                    exact
                    path="/patient/OutgoingFax"
                    render={() => <OutgoingFax />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/OutgoingFax"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.fax ? (
                  <Route
                    exact
                    path="/patient/IncomingFax/IncomingFaxindex/:To"
                    render={() => <IncomingFaxindex />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/IncomingFax/IncomingFaxindex/:To"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.fax ? (
                  <Route
                    exact
                    path="/patient/OutgoingFax/OutgoingFaxindex/:To"
                    render={() => <OutgoingFaxindex />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/OutgoingFax/OutgoingFaxindex/:To"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.patient_referral ? (
                  <Route
                    exact
                    path="/patient/referralmanageMent"
                    render={() => <ReferralManagement />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/referralmanageMent"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* --------------------------------------Demo End------------------------------ */}

                {/* --------------------------------------Appt Start------------------------------ */}
                {/* {s_role.appt ? ( */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
                {/* ) : s_role.demo ? ( */}
                {/* <Route
                    exact
                    path="/"
                    render={() => <PatientList refreshList={refreshList} />}
                  />
                ) : s_role.bill ? (
                  <Route exact path="/" render={() => <InsuranceClaim />} />
                ) : s_role.rep ? (
                  <Route exact path="/" render={() => <BillingSummary />} />
                ) : (
                  <Route exact path="/" component={AccessDenied} /> */}
                {/* )} */}

                {/* {s_role.appt ? ( */}
                <Route
                  exact
                  path="/schedule/nav/:nav"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/schedule/nav/:nav"
                    component={AccessDenied}
                  />
                )} */}
                {/* {s_role.appt ? ( */}
                <Route
                  exact
                  path="/schedule/calender"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/schedule/calender"
                    component={AccessDenied}
                  />
                )} */}

                {/* {s_role.appt ||
                ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? ( */}
                <Route
                  exact
                  path="/schedule/billingprofileshistory"
                  render={() => <BillingProfilesHistory />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/schedule/billingprofileshistory"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* {s_role.appt || s_role.pat_statement || s_role.bill_led || s_role.notes_lock || ((s_role.demo || s_role.appt) &&  s_role.pat_emergency)
?      <Route exact path="/schedule/appdashboard"  render={() => <AppDashboard   />} /> 
:   <Route exact path="/schedule/appdashboard" render={() => <AccessDenied   />} />
} */}
                {s_role.manageAppointment_BillingProfile ? (
                  <Route
                    exact
                    path="/schedule/appprofiles"
                    render={() => <AppProfiles />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/appprofiles"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAppointment_BillingProfile ? (
                  <Route
                    exact
                    path="/schedule/billingprofiles"
                    render={() => <BillingProfiles />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/billingprofiles"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.accessPracticeDashboard ? (
                  <Route
                    exact
                    path="/schedule/practicedashboard"
                    render={() => <PracticeDashboard />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/practicedashboard"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.viewAuditLog ? (
                  <Route
                    exact
                    path="/schedule/auditlog"
                    render={() => <AuditLog />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/auditlog"
                    render={() => <AccessDenied />}
                  />
                )}
                <Route
                  exact
                  path="/schedule/encryptDecrypt"
                  render={() => <EncryptDecryptComp />}
                />
                {s_role.setProviderAvailability ? (
                  <Route
                    exact
                    path="/schedule/apptemplate"
                    render={() => <AppTemplate />}
                  />
                ) : (
                  <Route
                    exact
                    path="/schedule/apptemplate"
                    render={() => <AccessDenied />}
                  />
                )}

                <Route
                  exact
                  path="/settings/acknowledgement/"
                  render={() => <Acknowledgement />}
                />

                {/* updated by saravanan start */}
                {/* --------------------------------------Appt End------------------------------ */}

                {/* --------------------------------------Bill start----------------------------------- */}

                {s_role.ins_claims ? (
                  <Route
                    exact
                    path="/billing/nav/:nav"
                    render={() => <InsuranceClaim />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/nav/:nav"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* {s_role.bill ? (
                  <Route
                    exact
                    path="/billing/GenerateClaimProcess"
                    render={() => <GenerateForm />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/GenerateClaimProcess"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {s_role.pat_statement ? (
                  <Route
                    exact
                    path="/billing/patientstatement"
                    render={() => <PatientStatement />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/patientstatement"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* saravanana 31.3.22 8:00 end */}

                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/productprocedure"
                    render={() => <ProductProcedure />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/productprocedure"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep ? (
                  <Route
                    exact
                    path="/report/productprocedures/:code_type/:code"
                    render={() => <ProductProcedures />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/productprocedures/:code_type/:code"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* {s_role.bill_admin || s_role.pat_emergency || s_role.bill ? ( */}
                <Route
                  exact
                  path="/billing/feeschedule"
                  render={() => <FeeSchedule />}
                />
                {/* ) : (
                  <Route
                    exact
                    path="/billing/feeschedule"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {s_role.ins_claims ? (
                  <Route
                    exact
                    path="/billing/insuranceclaim"
                    render={() => <InsuranceClaim />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/insuranceclaim"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.ins_claims ? (
                  <Route
                    exact
                    path="/billing/GenerateClaimProcess"
                    render={() => <GenerateForm />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/GenerateClaimProcess"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.bill_led ? (
                  <Route
                    exact
                    path="/billing/patientbalance"
                    render={() => <PatientBalance />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/patientbalance"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* {s_role.bill ? (
                  <Route
                    exact
                    path="/billing/DenialAnalysis"
                    render={() => <DenialAnalysis />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/DenialAnalysis"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {s_role.postPayments ? (
                  <Route exact path="/billing/eob" render={() => <Eob />} />
                ) : (
                  <Route
                    exact
                    path="/billing/eob"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.processCardTransactions ? (
                  <Route
                    exact
                    path="/billing/cardtransactions"
                    render={() => <CardTransactions />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/cardtransactions"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.postPayments ? (
                  <Route exact path="/billing/era" render={() => <Era />} />
                ) : (
                  <Route
                    exact
                    path="/billing/era"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.postPayments ? (
                  <Route
                    exact
                    path="/billing/era/vieweratable"
                    render={() => <ViewEratable />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/era/vieweratable"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.coding ? (
                  <Route
                    exact
                    path="/billing/EobTableEdit/:eid"
                    render={() => <EobTableEdit />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/EobTableEdit/:ei"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.coding ? (
                  <Route
                    exact
                    path="/billing/EobTableEdit"
                    render={() => <EobTableEdit />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/EobTableEdit"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.coding ? (
                  <Route
                    exact
                    path="/billing/EobInsurance/:eid"
                    render={() => <EobInsurance />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/EobInsurance/:eid"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.coding ? (
                  <Route
                    exact
                    path="/billing/PatientPaymentInvoice"
                    render={() => <PatientPaymentInvoice />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/PatientPaymentInvoice"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* --------------------------------------Bill end----------------------------------- */}

                {/* --------------------------------------Coding start----------------------------------- */}
                {s_role.coding ? (
                  <Route
                    exact
                    path="/billing/patientpayment"
                    render={() => <PatientPayment />}
                  />
                ) : (
                  <Route
                    exact
                    path="/billing/patientpayment"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* --------------------------------------Coding end----------------------------------- */}

                {/* --------------------------------------rep start----------------------------------- */}

                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/nav/:nav"
                    render={() => <BillingSummary />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/nav/:nav"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/BillingSummary"
                    render={() => <BillingSummary />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/BillingSummary"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep1 ? (
                  <Route
                    exact
                    path="/report/PriorAuthorization"
                    render={() => <PriorAuthorization />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/PriorAuthorization"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep1 ? (
                  <Route
                    exact
                    path="/report/EligibilityReport"
                    render={() => <EligibilityReport />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/EligibilityReport"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep1 ? (
                  <Route
                    exact
                    path="/report/OutgoingPrescription"
                    render={() => <OutgoingPrescription />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/OutgoingPrescription"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep3 ? (
                  <Route
                    exact
                    path="/report/appointments"
                    render={() => <Appointments />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/appointments"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep2 ? (
                  <Route exact path="/report/aging" render={() => <Aging />} />
                ) : (
                  <Route
                    exact
                    path="/report/aging"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep3 ? (
                  <Route exact path="/report/User" render={() => <User />} />
                ) : (
                  <Route
                    exact
                    path="/report/User"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/Invoice"
                    render={() => <Invoice />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/Invoice"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep3 ? (
                  <Route
                    exact
                    path="/report/Reminder"
                    render={() => <Reminder />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/Reminder"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep3 ? (
                  <Route
                    exact
                    path="/report/ProductivityReport"
                    render={() => <ProductivityReport />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/ProductivityReport"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep1 ? (
                  <Route
                    exact
                    path="/report/PatientReport"
                    render={() => <PatientReport />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/PatientReport"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/daySheet"
                    render={() => <DaySheet />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/daySheet"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/Remittance"
                    render={() => <Remittance />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/Remittance"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep ? (
                  <Route
                    exact
                    path="/report/UnderPaid"
                    render={() => <UnderPaid />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/UnderPaid"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep ? (
                  <Route
                    exact
                    path="/report/unmatchedera"
                    render={() => <unmatchedera />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/unmatchedera"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.rep2 ? (
                  <Route
                    exact
                    path="/report/clinicalreport"
                    render={() => <ClinicalReport />}
                  />
                ) : (
                  <Route
                    exact
                    path="/report/clinicalreport"
                    render={() => <AccessDenied />}
                  />
                )}

                {/* --------------------------------------rep end----------------------------------- */}

                {/* ---------------------------------------users start---------------------------------------- */}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/nav/:nav"
                    render={() => <SettingsMyProfile TopNavRef={TopNavProps} />}
                  />
                ) : (
                  //   : s_role.demo || s_role.bill || s_role.mesg || s_role.coding || s_role.notes
                  //   ?   <Route exact path="/settings/nav/:nav"  render={() => <SettingService   />} />
                  //   : s_role.appt || s_role.bill_a || s_role.bill_s ||  s_role.bill_led || s_role.m_templete || s_role.acl ||  s_role.rep || s_role.pat_bal ||  s_role.bill_admin || s_role.pat_statement || s_role.notes_lock || s_role.appt_a || s_role.ipad || s_role.erx || s_role.contacts || s_role.pat_g || s_role.pat_analytics || s_role.gadd || s_role.pat_share || ((s_role.demo || s_role.appt) &&  s_role.pat_emergency) ||  s_role.m_templete || s_role.pat_export || s_role.ins_claims || s_role.gm || s_role.finance_reports || s_role.ref_source || s_role.practice_groups || s_role.drugs
                  // ?     <Route exact path="/settings/nav/:nav"  render={() => <PayerSearch   />} />
                  <Route
                    exact
                    path="/settings/nav/:nav"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.configureBillingServices ? (
                  <Route
                    exact
                    path="/settings/lab"
                    render={() => <LabSettings />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/lab"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/settingsmyprofile"
                    render={() => <SettingsMyProfile TopNavRef={TopNavProps} />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/settingsmyprofile"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.configureBillingServices ? (
                  <Route
                    exact
                    path="/settings/settingservice"
                    render={() => <SettingService />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/settingservice"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/security"
                    render={() => <Security />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/security"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/myplan"
                    render={() => <MyPlan />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/myplan"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/settingpracticeinfo"
                    render={() => <SettingPracticeInfo />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/settingpracticeinfo"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.configureBillingServices ? (
                  <Route
                    exact
                    path="/settings/settingbilling"
                    render={() => <SettingBilling />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/settingbilling"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.setSchedulingPreferences ? (
                  <Route
                    exact
                    path="/settings/calendersettings"
                    render={() => <CalenderSettings />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/calendersettings"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/timezonesettings"
                    render={() => <TimeZoneSettings />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/timezonesettings"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.setSchedulingPreferences ? (
                  <Route
                    exact
                    path="/settings/appointmentreminder"
                    render={() => <AppointmentReminder />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/appointmentreminder"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.setSchedulingPreferences ? (
                  <Route
                    exact
                    path="/settings/patientportal/:mark"
                    render={() => <PatientPortalSetting />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/patientportal/:mark"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.setSchedulingPreferences ? (
                  <Route
                    exact
                    path="/settings/notificationsettings"
                    render={() => <NotificationSettings />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/notificationsettings"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* {console.log(s_role.manageAccountSettings,"aa11",s_role)} */}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/Users"
                    render={() => <Users />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/Users"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* ---------------------------------------users end------------------------------------------- */}

                {/* ------------------------------------------acl Start---------------------------------------- */}
                {s_role.manageAccountSettings ? (
                  <Route
                    exact
                    path="/settings/Roles"
                    render={() => <RolesMain />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/Roles"
                    render={() => <AccessDenied />}
                  />
                )}
                {/* ------------------------------------------acl End---------------------------------------- */}

                {/* -------------------------------------------erx start------------------------------------------- */}

                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/patient/senderx/:pid/:ch"
                    render={() => <SendErx />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/senderx/:pid/:ch"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/patient/senderxedit/:pid"
                    render={() => <SendErxEdit />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/senderxedit/:pid"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/settings/Templates"
                    render={() => <Templates />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/Templates"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/settings/Inventory"
                    render={() => <Inventory />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/Inventory"
                    render={() => <AccessDenied />}
                  />
                )}

                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/settings/ERXsettings"
                    render={() => <ERXSetting />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/ERXsettings"
                    render={() => <AccessDenied />}
                  />
                )}
                {s_role.manageAdministrationSettings ? (
                  <Route
                    exact
                    path="/settings/Dataimport/"
                    render={() => <Dataimport />}
                  />
                ) : (
                  <Route
                    exact
                    path="/settings/Dataimport/"
                    render={() => <AccessDenied />}
                  />
                )}
                <Route
                  exact
                  path="/settings/SubscriptionExpired/"
                  render={() => <SubscriptionExpired />}
                />

                <Route
                  exact
                  path="/settings/deleteaccount/:seperator"
                  render={() => <DeleteAccount />}
                />

                <Route
                  exact
                  path="/settings/SecureData"
                  render={() => <SecureData />}
                />
                <Route
                  exact
                  path="/settings/acknowledgement/"
                  render={() => <Acknowledgement />}
                />

                {/* {s_role.m_templete ? (
                  <Route
                    exact
                    path="/patient/formbuilder"
                    render={() => <FormB />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/formbuilder"
                    render={() => <AccessDenied />}
                  />
                )} */}
                {/* 
                {s_role.m_templete ? (
                  <Route
                    exact
                    path="/patient/formbuilder/:form_id"
                    render={() => <FormB />}
                  />
                ) : (
                  <Route
                    exact
                    path="/patient/formbuilder/:form_id"
                    render={() => <AccessDenied />}
                  />
                )} */}

                {/* -------------------------------------------erx end------------------------------------------- */}
              </Switch>
            </Suspense>
          </div>
          <WenoSupport />
        </div>
      );
    else if (
      ((subPayment.status === null || subPayment.status === "open") &&
        ConfToken !== undefined) ||
      (token && expired_days < 0)
    )
      return (
        <div className="App">
          <TopNav
            ref={SettingRef}
            tref={TasksRef}
            calRefHandle={handleRefCalls}
            reqDrop={reqDrop}
            DropReq={handleDropReq}
            refid={reqDrop ? refid : ""}
          />
          <div className="app-body">
            <Suspense fallback={<Loader />}>
              <Switch>
                {/* {s_role.appt ? ( */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
                {/* ) : (
                  <Route exact path="/" component={AccessDenied} />
                )} */}
                {/* // : (
              //   <Route exact path="/" component={AccessDenied} />
              // )} */}

                {/* {s_role.appt ? (
                <Route
                  exact
                  path="/schedule/nav/:nav"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
              ) : (
                <Route
                  exact
                  path="/schedule/nav/:nav"
                  component={AccessDenied}
                />
              )}

              {s_role.appt ||
              ((s_role.demo || s_role.appt) && s_role.pat_emergency) ? (
                <Route
                  exact
                  path="/schedule/calender"
                  render={() => (
                    <Calender
                      calRefresh={calRefresh}
                      Drop={() => {
                        setReqDrop(!reqDrop);
                      }}
                      setRef={(x) => {
                        setRefId(x);
                      }}
                    />
                  )}
                />
              ) : (
                <Route
                  exact
                  path="/schedule/calender"
                  component={AccessDenied}
                />
              )} */}

                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Suspense>
          </div>
          <WenoSupport />
        </div>
      );
  }
};

export default App;
