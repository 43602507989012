import styled from "styled-components";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { HiOutlinePrinter } from "react-icons/hi";
import { BiEditAlt, BiDotsVerticalRounded } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileAdd } from "react-icons/ai";
import { Select } from "antd";
import { Calendar } from "react-calendar";
import { AlertModal } from "../setting/Acknowledgement/styles";
import { Div, Modal } from "../../StyledComponents";
import { globalColor, globalFont } from "../utils/RepeatFunctions/Schedule";

export const CalenderDiv = styled.div``;

export const CalAvailText = styled.label`
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  margin-left: ${(p) => p.ml && p.ml};
  margin-right: ${(p) => p.mr && p.mr};
`;

export const ToolBarContainer = styled.div`
  margin: 17px 24px;
  display: flex;
`;
export const ToolBarContainerLeftGroup = styled.div``;
export const ToolBarContainerCenterGroup = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
`;
export const ToolBarContainerRightGroup = styled.div`
  display: flex;
`;
export const ViewSelector = styled.div`
  display: flex;
`;
export const MonthViewBtn = styled.button`
  color: #2662f0;
  background: #fafbfd;
  border-radius: 4px 0px 0px 4px;
  border: 0px;
  padding: 6px 15px;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? "#ffffff" : "#fafbfd")};
  box-shadow: ${(props) =>
    props.active ? "0px 1px 8px rgba(20, 46, 110, 0.1)" : "unset"};
`;
export const WeekViewBtn = styled.button`
  background: #fafbfd;
  border-radius: 4px 0px 0px 4px;
  border: 0px;
  padding: 6px 15px;
  cursor: pointer;
  color: #2662f0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? "#ffffff" : "#fafbfd")};
  box-shadow: ${(props) =>
    props.active ? "0px 1px 8px rgba(20, 46, 110, 0.1)" : "unset"};
`;
export const DayViewBtn = styled.button`
  background: #fafbfd;
  border-radius: 4px 0px 0px 4px;
  border: 0px;
  padding: 6px 15px;
  cursor: pointer;
  color: #2662f0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) =>
    props.disableBtn ? "not-allowed !important" : "pointer !important"};
  background: ${(props) => (props.active ? "#ffffff" : "#fafbfd")};
  box-shadow: ${(props) =>
    props.active ? "0px 1px 8px rgba(20, 46, 110, 0.1)" : "unset"};
`;
export const LeftNavBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #ffffff;
  padding: 4px 6px;
  height: 35px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
`;

export const CenterNavBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #ffffff;
  padding: 4px 14px 4px 14px;
  height: 35px;
  font-size: 14px;
  border-left: 0px;
  border-radius: 0px;
  border-right: 0px;
  cursor: pointer;
  color: #748194;
  @media (max-width: 1089px) {
    padding: 0px;
    font-size: 12px;
  }
`;
export const RightNavBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #ffffff;
  padding: 4px 6px;
  height: 35px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
`;
export const RightNavArrowIcon = styled(BsChevronRight)`
  color: #748194;
`;

export const LeftNavArrowIcon = styled(BsChevronLeft)`
  color: #748194;
`;
export const PrintBtn = styled.button`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #ffffff;
  padding: 4px 6px;
  height: 35px;
  color: #748194;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
`;
export const PrintBtnIcon = styled(HiOutlinePrinter)``;

export const TodayBtn = styled.button`
  border-radius: 4px 0px 0px 4px;
  border: 0px;
  padding: 6px 15px;
  cursor: pointer;
  color: ${globalColor};
  background: #ffffff;
  margin-right: 20px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FacilitySlot = styled.div`
  display: flex;
  border: 1px solid #e7ebeb;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
`;

export const DayName = styled.div`
  width: 79px;
  text-align: center;
  border-right: 1px solid #e7ebeb;
  padding: 10px;
  color: ${globalColor};
  font-weight: 500;
  text-transform: uppercase;
`;
export const FacilityName = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: ${globalColor};
  font-weight: 500;
  font-family: ${globalFont};
`;
export const TimeText = styled.div`
  color: #718096;
  font-size: 12px;
  padding: 10px;
  text-align: center;
`;
export const ProviderName = styled.div`
  color: #2e2e2e;
  padding-top: 10px;
  padding-bottom: 10px;
  width: ${(props) => (props.exam ? "unset" : "unset")};
`;
export const ProviderListDiv = styled.div`
  display: flex;
  flex: 1;
`;
export const SelectProvider = styled.div`
  border: 1px solid #e7ebeb;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  display: flex;
`;
export const SelectFacility = styled.div`
  border: 1px solid #e7ebeb;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  display: flex;
`;
export const SelectProviderTest = styled.div`
  color: #718096;
  padding-left: 24px;
  padding-right: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  width: 150px;
  text-align: left;
  width: ${(props) => (props.examroom ? "190px" : "150px")};
`;
export const ProviderSlot = styled.div`
  display: flex;
  border: 1px solid #e7ebeb;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 0px;
`;
export const ProviderImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  margin-right: 12px;
`;

export const CloseBtn = styled.button`
  margin-left: auto;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  background: #f1f2f5;
  margin-top: auto;
  border: 0px;
  margin-bottom: auto;
  margin-right: 5px;
  cursor: pointer;
`;

export const CloseBtnIcon = styled(IoIosClose)`
  margin-left: -3px;
  margin-bottom: 0px;
  margin-top: -7px;
`;

export const ProviderDiv = styled.div`
  display: flex;
  flex: 1;
  border-right: ${(props) => (props.rightborder ? "1px solid #eceff0" : "0px")};
`;
export const AntMultipleSelect = styled(Select)`
  margin-top: auto;
  margin-bottom: auto;
  border: 0px;
`;
export const EventDivOne = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "3px")};
  font-size: 13px;
  font-weight: 600;
  display: ${(props) => props.dis && props.dis};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "18px")};
  font-family: ${globalFont};
  font-size: ${(props) => props.fontSize && props.fontSize};
  display: ${(props) => props.display && props.display};
  align-items: ${(props) => props.alignItems && props.alignItems};
`;
export const EventDivOneMonth = styled.div`
  margin-top: 3px;
  font-size: 11px;
  font-weight: 500;
  width: 100px;
  padding-left: 5px;
  padding-right: 5px;
`;
export const EventDivTwo = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "3px")};
  font-size: 13px;
  font-weight: 600;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "18px")};
  font-family: ${globalFont};
  font-size: ${(props) => props.fontSize && props.fontSize};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
`;

export const CenterDate = styled.span``;
export const WeekDateFormat = styled.div`
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
`;
export const RepeatPopupBlock = styled.div`
  padding: 20px;
  width: 400px;
`;
export const RepeatPopupHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: black;
`;
export const RepeatPopupBodyText = styled.div`
  font-size: 14px;
  padding-top: 10px;
  color: ${(p) => (p.color ? p.color : "#2d3748")};
`;
export const RepeatPopupBodyBtn = styled.div`
  padding-top: 10px;
`;
export const RepeatBtn = styled.button`
  margin-right: 15px;

  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 14px;
  padding-right: 14px;

  border: 1px solid transparent;

  // background-color: ${(props) => (props.confirm ? "#ff76762b" : "#f6f7f9")};

  // color: ${(props) => (props.confirm ? " #EF5F5F" : " #414d55")};

  color: ${(props) => (props.active ? "  white" : " #414d55")};
  border: ${(props) =>
    props.active ? "  1px solid " + globalColor : "1px solid transparent"};
  background-color: ${(props) => (props.active ? globalColor : " #f6f7f9")};

  ${(props) => props.errorBorder && "border: 1px solid red !important"};

  &:hover {
    color: white;
    border: 1px solid ${globalColor};
    background-color: ${globalColor};
  }
  &.close_active:hover {
    background-color: rgba(244, 246, 249, 0.75) !important;
    border: 1px solid ${globalColor};
    color: ${globalColor} !important;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1) !important;
    border: 1px solid ${globalColor} !important;
    color: ${globalColor} !important;
  }
  &.close_active {
    border: 1px solid ${globalColor};
    background: #fff;
    color: ${globalColor};
  }
`;

export const RepeatBtnConfirm = styled.button`
  margin-right: 15px;

  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 14px;
  padding-right: 14px;

  border: 1px solid ${globalColor};

  background-color: ${globalColor};

  color: #fff;

  &:hover {
    color: #fff !important;
    border: 1px solid ${globalColor};
    background-color: #005fb2 !important;
  }
  &:active {
    color: #fff !important;
    background-color: rgba(44, 123, 229, 0.75) !important;
    border: rgba(44, 123, 229, 0.75) !important;
  }
`;
export const RepeatFooter = styled.div`
  padding-top: 20px;
  text-align: right;
`;

export const Smallcalender = styled(Calendar)`
  display: ${(props) => (props.active ? "block" : "none")};
  z-index: 10000;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 244px;
  font-size: 14px;
  margin-top: 37px;
  border: 1px solid #cbd5e0;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 5px 5px 8px rgb(20 46 110 / 15%);
  border-radius: 8px;
`;
export const Provider = styled.div`
  margin: ${(p) => p.margin && p.margin};
  position: relative;
  @media (max-width: 1129px) {
    margin-left: 0px;
  }
`;

export const Examroom = styled.div`
  margin: ${(p) => p.margin && p.margin};
  position: relative;
  @media (max-width: 1129px) {
    margin-left: 0px;
  }
`;
export const Item = styled.div`
  padding: 10px;
  background-color: ${({ selected }) => (selected ? "#ddd" : "#fff")};
  cursor: pointer;
`;

export const SelectButton = styled.button`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};

  font-size: 14px;
  // font-family: "Open Sans", "Inter", sans-serif;
  font-family: ${globalFont};
  // font-style: normal;
  line-height: 19px;
  // margin: 15px 0px 0 0;
  background-color: ${({ selected }) => (selected ? "#ddd" : "#fff")};
  color: ${globalColor};
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: left;
  padding: 7px 0 7px 9px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @media (max-width: 1129px) {
    width: 120px !important;
  }
`;

export const SelectOptions = styled.div`
  width: ${(p) => p.width && p.width};
  position: absolute;
  top: 112%;
  // left: 245px;
  // right: 150px;
  z-index: 10;
  // width: 200px;
  max-height: 270px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
`;
export const SelectOptions1 = styled.div`
  width: ${(p) => p.width && p.width};
  position: absolute;
  margin: 4px 0px 0px 0;
  z-index: 12;
  max-height: 270px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
`;

export const Option = styled.div`
  height: 37px;
  // width: 198px;
  // padding: 10px;
  padding: ${(p) => p.padding && p.padding};
  background-color: ${({ selected }) => (selected ? globalColor : "#fff")};
  color: ${({ selected }) => (selected ? "#fff" : "black")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;
  text-align: left;
`;

export const NoDataMessage = styled.div`
  height: 37px;
  padding: ${(p) => p.padding && p.padding};
  text-align: left;
`;
export const NoteTakingEditBox = styled.div`
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => (props.fcolor ? "#000" : "#FFF")};
  text-align: end;
  left: 0px;
  right: 0px;
  padding-bottom: 3px;
  // bottom: 0;
`;

export const EventContentBox = styled.div`
  height: 100%;
  z-index: 10005;
  color: ${(props) => (props.fcolor ? "#000" : "#FFF")};
  ${(props) => props.flexDisplay && "display:  flex"};
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;
export const NoteTakingEdit = styled(BiEditAlt)`
  //display: ${(props) => (props.block ? "block" : "none")};
  color: ${(p) => p.color && p.color};
  height: 20px;
  margin-left: ${(p) => p.ml && p.ml};
  text-align: -webkit-right;
`;
export const NoteTakingEditTele = styled(BiDotsVerticalRounded)`
  //display: ${(props) => (props.block ? "block" : "none")};
  // color: white;
  height: 20px;
  width: 20px;
  text-align: -webkit-right;
`;
export const NoteTakingDelete = styled(RiDeleteBin6Line)`
  // display: ${(props) => (props.block ? "block" : "none")};
  color: ${(p) => p.color && p.color};
  height: 20px;
  text-align: -webkit-right;
  margin-left: 10px;
  margin-right: ${(p) => p.marginAdjust && "10px"};
`;
export const NoteTakingAdd = styled(AiOutlineFileAdd)`
  //display: ${(props) => (props.block ? "block" : "none")};
  // color: white;
  height: 20px;
  text-align: -webkit-right;
  margin-left: 10px;
  margin-right: 10px;
`;
export const ModalFooter = styled.div`
  width: 512px;
  height: 20px;
  background-color: #ffff;
`;
export const ModalBody = styled.div`
  // height: 250px;
`;
export const Modal1 = styled.div`
  position: fixed;
  top: 0;
  left: 0px;
  z-index: 9999;
  // left: 0;
  // right: 0;
  // top: 50px;
  // bottom: 0;
  transition: all ease 10s;
  animation: fade 0.5s linear;
  background-color: rgb(203, 209, 217, 0.5);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: ${(p) => p.width && p.width};
  margin: 1.75rem auto !important;
  // align-items: top;
  // justify-content: right;
  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const ModalHead = styled.div`
  width: 224px;
  height: 71px;
  margin-top: -12px;
  // border-bottom: 1px solid #cbd5e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Modclosebtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  padding-bottom: 1px;
  line-height: 1.15px;
  margin-right: 20px;
  padding: ${(p) => p.padding && p.padding};
  z-index: ${(p) => p.zIndex && p.zIndex};
`;
// export const font1 = styled.div`
//   font-family: "inter", sans-serif;
// `;

export const ModalTitle = styled.div`
  // text-align: center;
  margin-left: 70px;
  font-family: ${globalFont};
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;
export const Container = styled.div`
  position: relative;
  // position: absolute;
  // top: ${({ top }) => top}px;
  // left: ${({ left }) => left}px;
  font-family: ${globalFont};
  width: 224px;
  height: 200px;
  background-color: #fff;
  margin-right: 10px;
  border-color: #cbd1d9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow-x: scroll;
`;
export const Content = styled.div`
  position: absolute;
  width: 206px;
  height: 51px;
  margin: -13px 0px 0 8px;
  padding: 2px 0px 0px 5px;
  text-align: left;
  background: ${globalColor};
  border-radius: 4px;
  color: white;
`;

export const SpinArrow = styled.div`
  height: 24px;
  width: 20px;
  margin-left: 16px;
  margin-top: 2px;

  & svg {
    width: 100%;
    color: ${globalColor};
    height: 100%;
    cursor: pointer;
    ${({ open }) =>
      open &&
      `
transform: rotate(180deg);  
`}
  }
`;
export const ProvDiv = styled.div`
  display: ${(p) => p.display && p.display};
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 200px;
  height: ${(p) => (p.height ? p.height : "180px")};
  overflow: auto;
  margin-left: -22px;
  margin-top: 39px;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;
export const FilterrowDiv = styled.div`
  padding: ${(p) => p.padding && p.padding};
  opacity: ${(p) => p.opacity && p.opacity};
  pointer-events: ${(p) => p.pointerEvents && p.pointerEvents};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 180px;
  text-align: left;
`;
export const FilterallDiv = styled.div`
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => p.alignitems && p.alignitems};
  cursor: ${(p) => p.cursor && p.cursor};
  padding: ${(p) => p.padding && p.padding};
  &:hover {
    color: #2e2e2e !important;
  }
`;
export const Button = styled.button`
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "4px")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  background: ${(p) => p.background && p.background};
  color: ${(p) => (p.color ? p.color : "#ffffff")};
  border: ${(p) => (p.border ? p.border : "none")};
  padding: ${(p) => p.padding && p.padding};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
`;
export const ErrorMessage = styled.p`
  width: ${(p) => (p.width ? p.width : "100%")};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};

  font-size: 80%;
  color: #dc3545;
  text-align: left;
`;
export const EmptyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
`;

export const AlertModalExpired = styled(AlertModal)`
  z-index: 1001;
`;

export const DivAlerModal = styled(Div)`
  background-color: #f1f5f8;
  border-radius: 2px;
`;
export const ModalSub = styled(Modal)``;

export const IconSpan = styled.span`
  font-size: 16px;
  margin-right: 2px;
`;
export const SelectInput = styled.input`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};

  font-size: 14px;
  // font-family: "Open Sans", "Inter", sans-serif;
  font-family: "Open Sans";
  // font-style: normal;
  line-height: 19px;
  // margin: 15px 0px 0 0;
  background-color: ${({ selected }) => (selected ? "#ddd" : "#fff")};
  color: #2c7be5;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: left;
  padding: 7px 0 7px 9px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  @media (max-width: 1129px) {
    width: 120px !important;
  }
`;
