import axiosInstance from "../../axios/axios";

export const ClaimApi = async (data) =>
  await axiosInstance.get(
    `/vozo/billing/generate-claim-data?encounter=` +
      data.encounter +
      `&payerid=` +
      data.payerid +
      `&pid=` +
      data.pid +
      "&type=" +
      data.type
  );

export const ClaimFormApi = async (data) =>
  await axiosInstance.get(
    `/vozo/billing/get-claimform-data?encounter=` +
      data.encounter +
      `&type=` +
      data.insuranceType +
      `&pid=` +
      data.pid
  );
