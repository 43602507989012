import React, { useState, useEffect, memo } from "react";
import { useSelector } from "react-redux";
import { Spinner, Button, Form, Collapse, Col } from "react-bootstrap";
import axiosInstance from "../../axios/axios";
import { Search } from "semantic-ui-react";
import { AutoComplete, Select } from "antd";
import { useDispatch } from "react-redux";

import {
  RiDeleteBin5Line,
  AiOutlinePlus,
  FaCheckCircle,
  AiOutlineSearch,
} from "react-icons/all";
import { BsPencilSquare } from "react-icons/bs";
import { SpinnerDiv } from "../../StyledComponents";
import {
  Table,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  TableHead,
} from "../setting/PatientPortalSettings/StyledComponents";
import Emp_data_pic from "../../assets/images/Emp_data_pic.png";
import { Image } from "react-bootstrap";
import { Tablerow } from "../patient/StyledComponent";
import UpgradeRequired from "./UpgradeRequired";
import SuffixIcon from "../popups/CustomSuffixIcon";
import AppointmentDetailsAddCodes from "./AppointmentDetailsAddCodes";
import { changeAppointmentValues } from "../../StateManagement/Reducers/ScheduleState";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const ServicesAddTable = (props) => {
  const { Option } = Select;
  // ---------------------------------------- State and Variables Start--------------------------------------------------- //
  const dispatch = useDispatch();

  var provider = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var tabsdisabled = props.tabstatus;
  let accountType = Decrypt_Value(localStorage.getItem("account_type"), "vozo");
  const [upgrade, setUpgrade] = useState(false);
  const [ModifiersCodeDropdown, setModifiersCodeDropdown] = useState([]);
  const [ccsearch, setccsearch] = useState("");
  const [showcode, setshowcode] = useState([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [icdcode, seticdcode] = useState([]);
  const [hcpccode, sethcpccode] = useState([]);
  const [icdnine, seticdnine] = useState([]);
  const [filteredCode, setFilteredCode] = useState([]);
  const [rows, setfutureId] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [feetotal, setfeetotal] = useState(0);
  const [allcustomecode, setallcustomecode] = useState([]);
  const [customcodeshow, setcustomcodeshow] = useState([]);
  const [fornoresults, setnoresult] = useState("1");
  const [NDC_Code, setNDC_Code] = useState([]);
  const [SpinnerVal, setSpinnerVal] = useState(true);
  const [CPT_4_NDC, setCPT_4_NDC] = useState([]);
  const [NDC_CPT_4SearchVal, setNDC_CPT_4SearchVal] = useState("");
  const [NDC_CPT_4Result, setNDC_CPT_4Result] = useState([]);
  const [HCPCS_NDC, setHCPCS_NDC] = useState([]);
  const [NDC_HCPCSSearchVal, setNDC_HCPCSSearchVal] = useState("");
  const [NDC_HCPCSResult, setNDC_HCPCSResult] = useState([]);
  const [Cust_Code_NDC, setCust_Code_NDC] = useState([]);
  const [NDC_Cust_CodeSearchVal, setNDC_Cust_CodeSearchVal] = useState("");
  const [NDC_Cust_CodeResult, setNDC_Cust_CodeResult] = useState([]);
  // const [hcpcsValval, setHcpcsValval] = useState("");
  // const [hcpcsIndind, setHcpcsIndind] = useState("");
  const [feeLoad, setFeeLoader] = useState(false);

  const [addTrue, setAddTrue] = useState(true);
  const [editTrue, setEditTrue] = useState(true);
  const [delTrue, setDelTrue] = useState(true);

  const [addErr, setAddErr] = useState(false);

  var txt = [];
  const [scode, setscode] = useState("");
  const [title, settitle] = useState([]);

  var hcarr = [];
  const [hcsearch, sethcsearch] = useState("");
  const [hctitle, sethctitle] = useState([]);

  var icdnarr = [];
  const [icdnsearch, seticdnsearch] = useState("");
  const [icdntitle, seticdntitle] = useState([]);

  const [toggle, setToggle] = useState("none");
  const [toggle1, setToggle1] = useState("flex");
  const [l, setL] = useState("none");
  const [l1, setL1] = useState("flex");
  const [l2, setL2] = useState("none");
  const [l3, setL3] = useState("flex");

  const [rowId, setRowId] = useState(0);
  const [rowId1, setRowId1] = useState(0);
  const [rowId2, setRowId2] = useState(0);

  const [codeErr, setCodeErr] = useState(false);
  const [feesErr, setFeeErr] = useState(false);
  const [codeVal, setCodeval] = useState("");
  const [fees, setFees] = useState("");
  const [codeType, setCodeType] = useState("");
  const [codeTypeErr, setCodeTypeErr] = useState(false);

  const currency = useSelector((state) => state.Report.Currency);
  const [showService, setShowService] = useState(true);
  const [selectedOption, setSelectedOption] = useState("icd10");

  const [showOptions, setShowOptions] = useState(false);
  const [caretClr, setCaretClr] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [feeHolder, setFeeHolder] = useState({});
  const [dummy, setDummy] = useState("");
  const [cpt4Select, setCpt4Select] = useState([
    {
      select1: "",
      select2: "",
      select3: "",
      select4: "",
    },
  ]);

  const [addedICD, setAddedICD] = useState([]);
  const [addedCPT, setAddedCPT] = useState([]);
  const [addedHCPCS, setAddedHCPCS] = useState([]);
  const [addedCustom, setAddedCustom] = useState([]);
  const [hcpcsSelect, setHcpcsSelect] = useState([
    {
      select1: "",
      select2: "",
      select3: "",
      select4: "",
    },
  ]);
  const [custCodeSelect, setCustCodeSelect] = useState([
    {
      select1: "",
      select2: "",
      select3: "",
      select4: "",
    },
  ]);

  const mouseClick = (type = "none") => {
    if (accountType === "1" && type === "custom") {
      setUpgrade(true);
    } else {
      setShowOptions(!showOptions);
      setCaretClr("");
    }
  };

  const handleMouseLeave = () => {
    // setShowOptions(false);
    setCaretClr("caret_Color_transp");
  };

  let encounter = props.start;
  // ---------------------------------------- State and Variables End--------------------------------------------------- //

  // ---------------------------------------- Functions Start----------------------------------------------------------- //

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const HCPCSSerachElementChange = (ind, val, row_id) => {
    if (editTrue) {
      document.getElementById(`HSPSC_NDC_Code_Search${ind}${row_id}`).value =
        "";
      Billing_NDC_update(row_id, ind, "HCPCS", "", "", "");
      setNDC_HCPCSSearchVal(val);
      setRowId(ind);
      setToggle("block");
      setToggle1("none");
      // setHcpcsIndind("0");
    }
  };

  const CPT_4SerachElementChange = (ind, val, row_id) => {
    if (editTrue) {
      document.getElementById(`CPT_4_NDC_Code_search${ind}`).value = "";
      Billing_NDC_update(row_id, ind, "CPT4", "", "", "");
      setNDC_CPT_4SearchVal(val);
      setRowId1(ind);
      setL2("block");
      setL3("none");
    }
  };

  const Cust_CodeSerachElementChange = (ind, val, row_id) => {
    if (editTrue) {
      document.getElementById(
        `Cust_Code_NDC_Code_Search${ind}${row_id}`
      ).value = "";
      Billing_NDC_update(row_id, ind, "Custom_Code", "", "", "");
      setNDC_Cust_CodeSearchVal(val);
      setRowId2(ind);
      setL("block");
      setL1("none");
    }
  };

  const refreshdata = (type) => {
    axiosInstance
      .get("/vozo/allbill/withall?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_serv_codes = Decrypt_Value(response.data, "vozo");

        setAllCodes(temp_role_serv_codes.flat().sort((a, b) => a.id - b.id));
        if (type === "ICD9") {
          seticdnine(temp_role_serv_codes[0]);
        } else if (type === "ICD10") {
          seticdcode(temp_role_serv_codes[1]);
        } else if (type === "HCPCS") {
          sethcpccode(temp_role_serv_codes[2]);
        } else if (type === "CPT4") {
          setfutureId(temp_role_serv_codes[3]);
        } else if (type === "CusCode") {
          setcustomcodeshow(temp_role_serv_codes[4]);
          checktotal();
        } else if (type === "") {
          seticdnine(temp_role_serv_codes[0]);
          seticdcode(temp_role_serv_codes[1]);
          sethcpccode(temp_role_serv_codes[2]);
          setfutureId(temp_role_serv_codes[3]);
          setcustomcodeshow(temp_role_serv_codes[4]);
          checktotal();
        }
        const result1 = temp_role_serv_codes[2].reduce((acc, item) => {
          let trimmedCode = item.code.trim();

          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        const result2 = temp_role_serv_codes[3].reduce((acc, item) => {
          let trimmedCode = item.code.trim();
          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        const result3 = temp_role_serv_codes[4].reduce((acc, item) => {
          let trimmedCode = item.code.trim();
          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        setFeeHolder({ ...result1, ...result2, ...result3 });
      })
      .catch(() => {
        seticdnine([]);
        seticdcode([]);
        sethcpccode([]);
        setfutureId([]);
        setcustomcodeshow([]);
      });
    // if (type === "CPT4") {
    //   axiosInstance
    //     .get("/vozo/allbill?eid=" + props.eventeid + "&type=" + type)
    //     .then((response) => {
    //       let temp_role_for_cpt4 = Decrypt_Value(response.data, "vozo");
    //       setfutureId(temp_role_for_cpt4);
    //     })
    //     .catch(() => {
    //       setfutureId([]);
    //     });
    // } else if (type === "ICD10") {
    //   axiosInstance
    //     .get("/vozo/allbill?eid=" + props.eventeid + "&type=" + type)
    //     .then((response) => {
    //       let temp_role_for_icd10 = Decrypt_Value(response.data, "vozo");
    //       seticdcode(temp_role_for_icd10);
    //     })
    //     .catch(() => {
    //       seticdcode([]);
    //     });
    // } else if (type === "HCPCS") {
    //   axiosInstance
    //     .get("/vozo/allbill?eid=" + props.eventeid + "&type=" + type)
    //     .then((response) => {
    //       let temp_role_for_hcpcs = Decrypt_Value(response.data, "vozo");
    //       sethcpccode(temp_role_for_hcpcs);
    //     })
    //     .catch(() => {
    //       sethcpccode([]);
    //     });
    // } else if (type === "ICD9") {
    //   axiosInstance
    //     .get("/vozo/allbill?eid=" + props.eventeid + "&type=" + type)
    //     .then((response) => {
    //       let temp_role_for_icd9 = Decrypt_Value(response.data, "vozo");
    //       seticdnine(temp_role_for_icd9);
    //     })
    //     .catch(() => {
    //       seticdnine([]);
    //     });
    // } else if (type === "CusCode") {
    //   axiosInstance
    //     .get("/vozo/allbill?eid=" + props.eventeid + "&type=" + type)
    //     .then((response) => {
    //       let temp_role_for_cuscode = Decrypt_Value(response.data, "vozo");
    //       setcustomcodeshow(temp_role_for_cuscode);
    //       checktotal();
    //     })
    //     .catch(() => {
    //       setcustomcodeshow([]);
    //     });
    // }
  };

  const codes = (args) => {
    const articles = {
      encounter: props.eventeid,
      bill_process: "0",
      status: "",
      code_type: "CPT4",
      code: args.code,
      userid: provider,
      groupname: "Default",
      authorized: "1",
      activity: "1",
      unit: "1",
      fee: args.fee,
      code_text: args.code_text,
      apptId: props.apptId,
    };

    if (!addedCPT.includes(args.code)) {
      setAddedCPT((prev) => [...prev, args.code]);
    }

    let encryptedData_for_cpt_code_save = Encrypt_Value(articles, "vozo");

    axiosInstance
      .post("/vozo/billingappointment", encryptedData_for_cpt_code_save)
      .then((res) => {
        refreshdata("CPT4");
        checktotal();
        tabsdisabled(false);
        setSearch("");
      })
      .catch((err) => {
        console.error(err);
        setSearch("");
      });
  };

  const handleSearchChange = (data) => {
    setSearch(data);
    setnoresult("1");
    setFilteredCode([]);
    setShowOptions(true);
  };

  const handlecustomChange = (data) => {
    if (accountType === "1") {
      setUpgrade(true);
    } else {
      setccsearch(data);
      setshowcode([]);
      setnoresult("1");
      setShowOptions(true);
    }
  };

  const handlefeeval = async (id, eid, index, unit, type, textValue, code) => {
    // setFeeHolder(x=> ({...x, [code] : textValue}));

    // let val;
    // if (type === "CPT4") {
    //   val = document.getElementById(`cptfee${index}`).value;
    // } else if (type === "HCPCS") {
    //   val = document.getElementById(`hcpcs${index}`).value;
    // } else if (type === "CusCode") {
    //   val = document.getElementById(`customfee${index}`).value;
    // }

    const result = allCodes
      .flatMap((x) => x)
      .filter((item) => item.fee !== 0)
      .map(({ id, fee, unit }) => ({ id, fee, unit }));

    // const articles = { fee: textValue, unit: unit };
    let encryptedData_for_totalbill = Encrypt_Value(result, "vozo");
    setFeeLoader(true);
    axiosInstance
      .put("/vozo/totalbill?eid=" + props.eventeid, encryptedData_for_totalbill)
      .then((response) => {
        let temp_role_total_bill = Decrypt_Value(response.data, "vozo");
        setfeetotal(temp_role_total_bill);
        checktotal();
        setFeeLoader(false);
        refreshdata(type);
      })
      .catch((err) => {
        console.error(err);
        setFeeLoader(false);
      });
  };

  const BillingUpdate = (id, eid, index, data, identifier) => {
    let a1 = "";
    let b1 = "";
    let c1 = "";
    let d1 = "";
    if (identifier === "select1") {
      setCustCodeSelect((prevState) => {
        let a = [...prevState];
        if (a[index]) a[index]["select1"] = data;
        if (a[index]) a1 = a[index]["select1"];
        return a;
      });
    }
    if (identifier === "select2") {
      setCustCodeSelect((prevState) => {
        let b = prevState;
        if (b[index]) b[index]["select2"] = data;
        if (b[index]) b1 = b[index]["select2"];
        return b;
      });
    }
    if (identifier === "select3") {
      setCustCodeSelect((prevState) => {
        let c = prevState;
        if (c[index]) c[index]["select3"] = data;
        if (c[index]) c1 = c[index]["select3"];
        return c;
      });
    }
    if (identifier === "select4") {
      setCustCodeSelect((prevState) => {
        let d = prevState;
        if (d[index]) d[index]["select4"] = data;
        if (d[index]) d1 = d[index]["select4"];
        return d;
      });
    }
    // let macust = "MA_Cust" + index,
    //   mbcust = "MB_Cust" + index,
    //   mccust = "MC_Cust" + index,
    //   mdcust = "MD_Cust" + index;

    let ma1 = custCodeSelect[index]?.select1 || a1,
      mb1 = custCodeSelect[index]?.select2 || b1,
      mc1 = custCodeSelect[index]?.select3 || c1,
      md1 = custCodeSelect[index]?.select4 || d1;

    const article = { ma1: ma1, mb1: mb1, mc1: mc1, md1: md1 };

    axiosInstance
      .put("/vozo/billingupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CusCode");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingUnitUpdate = (id, eid, e, fee) => {
    const article = { unitss: e, fee: fee, id: id, eid: eid };

    axiosInstance
      .put("/vozo/billingunitsupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CusCode");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingPointerUpdate = (id, eid, e) => {
    const article = { Pointerss: e };

    axiosInstance
      .put("/vozo/billingpointersupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CusCode");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingHCPCSUpdate = (id, eid, index, data, identifier) => {
    let a1 = "";
    let b1 = "";
    let c1 = "";
    let d1 = "";
    if (identifier === "select1") {
      setHcpcsSelect((prevState) => {
        let a = [...prevState];
        if (a[index]) a[index]["select1"] = data;
        if (a[index]) a1 = a[index]["select1"];
        return a;
      });
    }
    if (identifier === "select2") {
      setHcpcsSelect((prevState) => {
        let b = prevState;
        if (b[index]) b[index]["select2"] = data;
        if (b[index]) b1 = b[index]["select2"];
        return b;
      });
    }
    if (identifier === "select3") {
      setHcpcsSelect((prevState) => {
        let c = prevState;
        if (c[index]) c[index]["select3"] = data;
        if (c[index]) c1 = c[index]["select3"];
        return c;
      });
    }
    if (identifier === "select4") {
      setHcpcsSelect((prevState) => {
        let d = prevState;
        if (d[index]) d[index]["select4"] = data;
        if (d[index]) d1 = d[index]["select4"];
        return d;
      });
    }
    // let macust = "MA_HCPCS" + index,
    //   mbcust = "MB_HCPCS" + index,
    //   mccust = "MC_HCPCS" + index,
    //   mdcust = "MD_HCPCS" + index;

    let ma1 = hcpcsSelect[index]?.select1 || a1,
      mb1 = hcpcsSelect[index]?.select2 || b1,
      mc1 = hcpcsSelect[index]?.select3 || c1,
      md1 = hcpcsSelect[index]?.select4 || d1;

    const article = { ma1: ma1, mb1: mb1, mc1: mc1, md1: md1 };

    axiosInstance
      .put("/vozo/billingupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("HCPCS");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingHCPCSUnitUpdate = (id, eid, e, fee) => {
    const article = { unitss: e, fee: fee };
    axiosInstance
      .put("/vozo/billingunitsupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("HCPCS");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const storeFeeUnits = () => {
    const result = allCodes
      .flatMap((innerArray) => innerArray)
      .map((item) => {
        const { code } = item;
        const details = feeHolder[code.trim()];
        return (details && details.fee !== 0) || (details && details.unit > 1)
          ? { id: item.id, fee: details.fee, unit: details.unit }
          : null;
      })
      .filter(Boolean);

    let encryptedData_for_totalbill = Encrypt_Value(result, "vozo");
    setFeeLoader(true);
    axiosInstance
      .put("/vozo/totalbill?eid=" + props.eventeid, encryptedData_for_totalbill)
      .then((response) => {
        let temp_role_total_bill = Decrypt_Value(response.data, "vozo");
        setfeetotal(temp_role_total_bill);
        checktotal();
        setFeeLoader(false);
        refreshdata("");
      })
      .catch((err) => {
        console.error(err);
        setFeeLoader(false);
      });
  };

  const BillingHCPCSPointerUpdate = (id, eid, e) => {
    const article = { Pointerss: e };

    axiosInstance
      .put("/vozo/billingpointersupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("HCPCS");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingCPT_4Update = (id, eid, index, data, identifier) => {
    let a1 = "";
    let b1 = "";
    let c1 = "";
    let d1 = "";
    if (identifier === "select1") {
      setCpt4Select((prevState) => {
        let a = [...prevState];
        if (a[index]) a[index]["select1"] = data;
        if (a[index]) a1 = a[index]["select1"];
        return a;
      });
    }
    if (identifier === "select2") {
      setCpt4Select((prevState) => {
        let b = prevState;
        if (b[index]) b[index]["select2"] = data;
        if (b[index]) b1 = b[index]["select2"];
        return b;
      });
    }
    if (identifier === "select3") {
      setCpt4Select((prevState) => {
        let c = prevState;
        if (c[index]) c[index]["select3"] = data;
        if (c[index]) c1 = c[index]["select3"];
        return c;
      });
    }
    if (identifier === "select4") {
      setCpt4Select((prevState) => {
        let d = prevState;
        if (d[index]) d[index]["select4"] = data;
        if (d[index]) d1 = d[index]["select4"];
        return d;
      });
    }

    // let macust = "MA_CPT_4" + index,
    //   mbcust = "MB_CPT_4" + index,
    //   mccust = "MC_CPT_4" + index,
    //   mdcust = "MD_CPT_4" + index;

    let ma1 = cpt4Select[index]?.select1 || a1,
      mb1 = cpt4Select[index]?.select2 || b1,
      mc1 = cpt4Select[index]?.select3 || c1,
      md1 = cpt4Select[index]?.select4 || d1;

    const article = { ma1: ma1, mb1: mb1, mc1: mc1, md1: md1 };

    axiosInstance
      .put("/vozo/billingupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CPT4");
      })
      .catch((err) => console.error(err));
  };
  const formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const BillingCPT_4UnitUpdate = (id, eid, e, fee) => {
    const article = { unitss: e.target.value, fee: fee };

    axiosInstance
      .put("/vozo/billingunitsupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CPT4");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const BillingCPT_4PointerUpdate = (id, eid, e) => {
    const article = { Pointerss: e };

    axiosInstance
      .put("/vozo/billingpointersupdt?id=" + id + "&eid=" + eid, article)
      .then(() => {
        refreshdata("CPT4");
      })
      .catch((err) => console.error(err));
  };

  const checktotal = () => {
    axiosInstance
      .get("/vozo/calculatefee?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_calc_fee = Decrypt_Value(response.data, "vozo");
        setfeetotal(temp_role_calc_fee);
        props.changeRefresh();
      })
      .catch(() => setfeetotal(0));

    axiosInstance
      .get("/vozo/countservices?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_count_serv = Decrypt_Value(response.data, "vozo");
        temp_role_count_serv === "true"
          ? tabsdisabled(true)
          : tabsdisabled(false);
      })
      .catch((err) => console.error(err));
  };

  const deletebill = (data, pid) => {
    const { id, code } = data;
    const updatedCPT = addedCPT.filter((item) => item !== code);
    setAddedCPT(updatedCPT);
    setSearch("");
    axiosInstance
      .put("/vozo/deletebill?pid=" + pid + "&id=" + id)
      .then(() => {
        refreshdata("CPT4");
        checktotal();
        NDC_refresh("CPT4");
      })
      .catch((err) => console.error(err));
  };

  const deleteicd = (data, pid) => {
    const { id, code } = data;
    const updatedICD = addedICD.filter((item) => item !== code);
    setAddedICD(updatedICD);
    setscode("");
    axiosInstance
      .put("/vozo/deletebill?pid=" + pid + "&id=" + id)
      .then(() => {
        refreshdata("ICD10");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const deletehcpc = (data, pid) => {
    const { id, code } = data;
    const updatedHCPCS = addedHCPCS.filter((item) => item !== code);

    setAddedHCPCS(updatedHCPCS);
    sethcsearch("");
    axiosInstance
      .put("/vozo/deletebill?pid=" + pid + "&id=" + id)
      .then(() => {
        refreshdata("HCPCS");
        checktotal();
        NDC_refresh("HCPCS");
      })
      .catch((err) => console.error(err));
  };

  const deleteicdn = (id, pid) => {
    axiosInstance
      .put("/vozo/deletebill?pid=" + pid + "&id=" + id)
      .then(() => {
        refreshdata("ICD9");
        checktotal();
      })
      .catch((err) => console.error(err));
  };

  const contactres = (arg) => {
    console.log(arg, "arg");
    var codse = arg.title;
    var icdcode = codse.split("--");
    const articles = {
      encounter: props.eventeid,
      bill_process: "0",
      status: "",
      code_type: "ICD10",
      code: icdcode[0],
      userid: provider,
      groupname: "Default",
      authorized: "1",
      activity: "1",
      unit: "1",
      fee: "",
      code_text: icdcode[1],
      apptId: props.apptId,
    };

    console.log(articles, "articles");

    if (!addedICD.includes(icdcode[0])) {
      setAddedICD((prev) => {
        return [...prev, icdcode[0]];
      });
    }

    let encryptedData_for_icd10_code_save = Encrypt_Value(articles, "vozo");
    axiosInstance
      .post("/vozo/billingappointment", encryptedData_for_icd10_code_save)
      .then((res) => {
        refreshdata("ICD10");
        tabsdisabled(false);
        setscode("");
      })
      .catch((err) => {
        console.error(err);
        setscode("");
      });
  };

  const hcpcsres = (arg) => {
    var codse = arg.title;
    var icdcode = codse.split("--");
    const articles = {
      encounter: props.eventeid,
      bill_process: "0",
      status: "",
      code_type: "HCPCS",
      code: icdcode[0],
      userid: provider,
      groupname: "Default",
      authorized: "1",
      activity: "1",
      unit: "1",
      fee: arg.fee,
      code_text: icdcode[1],
      apptId: props.apptId,
    };

    if (!addedHCPCS.includes(icdcode[0])) {
      setAddedHCPCS((prev) => [...prev, icdcode[0]]);
    }

    let encryptedData_for_hcpcs_code_save = Encrypt_Value(articles, "vozo");
    axiosInstance
      .post("/vozo/billingappointment", encryptedData_for_hcpcs_code_save)
      .then((res) => {
        refreshdata("HCPCS");
        tabsdisabled(false);
        sethcsearch("");
      })
      .catch((err) => {
        console.error(err);
        sethcsearch("");
      });
  };

  const icdnres = (arg) => {
    var codse = arg.title;
    var icdcode = codse.split("--");

    const articles = {
      encounter: props.eventeid,
      bill_process: "0",
      status: "",
      code_type: "ICD9",
      code: icdcode[0],
      userid: provider,
      groupname: "Default",
      authorized: "1",
      activity: "1",
      unit: "1",
      fee: "",
      code_text: icdcode[1],
      apptId: props.apptId,
    };

    let encryptedData_for_icd9_code_save = Encrypt_Value(articles, "vozo");
    axiosInstance
      .post("/vozo/billingappointment", encryptedData_for_icd9_code_save)
      .then((res) => {
        refreshdata("ICD9");
        tabsdisabled(false);
        seticdnsearch("");
      })
      .catch((err) => {
        console.error(err);
        seticdnsearch("");
      });
  };

  const insertcustomecode = (arg) => {
    const articles = {
      encounter: props.eventeid,
      bill_process: "0",
      status: "",
      code_type: arg.code_type,
      code: arg.code,
      userid: provider,
      groupname: "Default",
      authorized: "1",
      activity: "1",
      unit: "1",
      fee: arg.fee,
      code_text: arg.code_text,
      modifier: arg.modifier,
      apptId: props.apptId,
    };

    if (!addedCustom.includes(icdcode[0])) {
      setAddedCustom((prev) => [...prev, arg.code]);
    }

    let encryptedData_for_custom_code_save = Encrypt_Value(articles, "vozo");
    axiosInstance
      .post(
        "/vozo/billingappointmentcustom",
        encryptedData_for_custom_code_save
      )
      .then((res) => {
        refreshdata("CusCode");
        checktotal();
        setccsearch("");
      })
      .catch((err) => {
        console.error(err);
        setccsearch("");
      });
  };

  const saveCodes = (e) => {
    e.preventDefault();
    var err = 0;
    /* //update by cheran 14-7-22 start */
    var desc = document.getElementById("descriptionval").value,
      sfees = `{"standard":"${fees}"}`;
    if (codeVal === "" || codeVal === null) {
      setCodeErr(true);
      err = 2;
    }
    if (codeType === "" || codeType === null) {
      setCodeTypeErr(true);
      err = 2;
    }
    if (fees === "" || fees === null) {
      setFeeErr(true);
      err = 2;
    }
    if (err > 0) {
      return;
    }
    const articles = {
      code: codeVal,
      codetype: codeType,
      codetext: desc,
      fees: fees,
      actiontype: "new",
      updateby: provider,
      sfees: sfees,
    };
    axiosInstance
      .post("/vozo/insertcustomcode", articles)
      .then(() => {
        setOpen(false);
        refreshdata("CCode");
        setCodeval("");
        setFees("");
        setCodeType("");
      })
      .catch((err) => {
        console.error(err);
        setFeeErr(true);
      });

    setCodeval("");
    setFees("");
    setCodeType("");

    document.getElementById("descriptionval").value = "";
    /* //update by cheran 14-7-22 end */
  };

  const deletecustomcode = (data, pid) => {
    const { id, code } = data;
    const updatedCustom = addedCustom.filter((item) => item !== code);
    setAddedCustom(updatedCustom);
    setccsearch("");
    axiosInstance
      .put("/vozo/deletebill?pid=" + pid + "&id=" + id)
      .then(() => {
        refreshdata("CusCode");
        checktotal();
        NDC_refresh("Custom_Code");
      })
      .catch((err) => console.error(err));
  };

  const NDC_refresh = (typ) => {
    if (typ === "HCPCS") {
      axiosInstance
        .get("vozo/bilingndc?eid=" + props.eventeid + "&type=" + typ)
        .then((res) => {
          let temp_role_hcpcs_ndc = Decrypt_Value(res.data, "vozo");
          setHCPCS_NDC(temp_role_hcpcs_ndc);
        })
        .catch(() => setHCPCS_NDC([]));
    } else if (typ === "Custom_Code") {
      axiosInstance
        .get("vozo/bilingndc?eid=" + props.eventeid + "&type=" + typ)
        .then((res) => {
          let temp_role_customcode_ndc = Decrypt_Value(res.data, "vozo");

          setCust_Code_NDC(temp_role_customcode_ndc);
        })
        .catch(() => setCust_Code_NDC([]));
    } else if (typ === "CPT4") {
      axiosInstance
        .get("vozo/bilingndc?eid=" + props.eventeid + "&type=" + typ)
        .then((res) => {
          let temp_role_cpt4_ndc = Decrypt_Value(res.data, "vozo");
          setCPT_4_NDC(temp_role_cpt4_ndc);
        })
        .catch(() => setCPT_4_NDC([]));
    }
  };

  const Billing_NDC_Add = (val, ind, typ) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [ind]: !prevState[ind],
    }));

    if (addTrue && editTrue) {
      if (typ === "CPT4") {
        setNDC_CPT_4SearchVal("");
        setRowId1(ind);
      } else if (typ === "HCPCS") {
        setNDC_HCPCSSearchVal("");
        setRowId(ind);
      } else if (typ === "Custom_Code") {
        setNDC_Cust_CodeSearchVal("");
        setRowId2(ind);
      }

      const article = {
        billid: val.id,
        billhdrid: val.billing_hdr_id,
        billcode: val.code,
        billprice: val.fee,
        eid: val.encounter,
        pid: val.pid,
      };

      axiosInstance
        .post("/vozo/billingndcinsert?typ=" + typ, article)
        .then(() => {
          NDC_refresh(typ);
        })
        .catch((err) => console.error(err));

      // }
    }
  };

  const Billing_NDC_update = (id, ind, typ, ndccode, data, identifier) => {
    setAddTrue(true);
    setDelTrue(true);
    setEditTrue(true);
    setAddErr(false);
    if (typ === "HCPCS") {
      // setHcpcsValval(ndccode);
      // setHcpcsIndind("");
      setToggle1("flex");
      setToggle("none");
      setNDC_HCPCSSearchVal("");
      if (ndccode === "") {
        ndccode = document.getElementById(
          `HSPSC_NDC_Code_Search${ind}${id}`
        ).value;
      }
      let code = ndccode;
      let quantity = document.getElementById(`HSPSC_NDCQty${ind}${id}`).value;

      let unit;
      if (identifier === "hspscndc") {
        unit = data;
      } else {
        unit = document.getElementById(`HSPSC_NDCUnit${ind}${id}`).value;
      }
      let qty;
      if (quantity === "") {
        qty = 1;
      } else {
        qty = quantity;
      }
      const article = { code: code, qty: qty, unit: unit };
      let for_ndc_code_update = Encrypt_Value(article, "vozo");
      axiosInstance
        .put(
          "/vozo/billingndcupdate?id=" + id + "&typ=" + typ,
          for_ndc_code_update
        )
        .then(() => {
          NDC_refresh(typ);
        })
        .catch((err) => console.log(err));
    } else if (typ === "Custom_Code") {
      // setHcpcsValval(ndccode);
      // setHcpcsIndind("");
      setNDC_Cust_CodeSearchVal("");
      setL("none");
      setL1("flex");

      if (ndccode === "") {
        ndccode = document.getElementById(
          `Cust_Code_NDC_Code_Search${ind}${id}`
        ).value;
      }

      let code = ndccode;
      let quantity = document.getElementById(
        `Cust_Code_NDCQty${ind}${id}`
      ).value;

      let unit;
      if (identifier === "custndc") {
        unit = data;
      } else {
        unit = document.getElementById(`Cust_Code_NDCUnit${ind}${id}`).value;
      }

      let qty;
      if (quantity === "") {
        qty = 1;
      } else {
        qty = quantity;
      }
      const article = { code: code, qty: qty, unit: unit };
      let for_ndc_code_update = Encrypt_Value(article, "vozo");
      axiosInstance
        .put(
          "/vozo/billingndcupdate?id=" + id + "&typ=" + typ,
          for_ndc_code_update
        )
        .then(() => {
          NDC_refresh(typ);
        })
        .catch((err) => console.log(err));
    } else if (typ === "CPT4") {
      // setHcpcsValval(ndccode);
      // setHcpcsIndind("");
      setNDC_CPT_4SearchVal("");
      setL2("none");
      setL3("flex");

      if (ndccode === "") {
        ndccode = document.getElementById(`CPT_4_NDC_Code_search${ind}`).value;
      }

      let code = ndccode;
      let quantity = document.getElementById(`CPT_4_NDCQty${ind}`).value;
      let unit;
      if (identifier === "cptndc") {
        unit = data;
      }

      let qty;
      if (quantity === "") {
        qty = 1;
      } else {
        qty = quantity;
      }
      const article = { code: code, qty: qty, unit: unit };
      let for_ndc_code_update = Encrypt_Value(article, "vozo");
      axiosInstance
        .put(
          "/vozo/billingndcupdate?id=" + id + "&typ=" + typ,
          for_ndc_code_update
        )
        .then(() => {
          NDC_refresh(typ);
        })
        .catch((err) => console.log(err));
    }
  };

  const Billing_NDC_Delete = (id, typ) => {
    setAddErr(false);
    setAddTrue(true);
    setEditTrue(true);
    if (delTrue) {
      axiosInstance
        .delete("/vozo/billingndcdelete?id=" + id)
        .then((res) => {
          NDC_refresh(typ);
          // setHcpcsIndind("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleNewCodes = (type, val) => {
    if (type === "codeType") {
      setCodeType(val);
      setCodeTypeErr(false);
    } else if (type === "codes") {
      setCodeval(val);
      setCodeErr(false);
    } else if (type === "fee") {
      if (!isNaN(val) || val === "") {
        setFees(val);
        setFeeErr(false);
      }
    }
  };

  const handlecloseUpgrade = () => {
    setUpgrade(false);
  };

  const handleCollapseCompnt = () => {
    if (accountType === "1") {
      setUpgrade(true);
    } else {
      setOpen(!open);
    }
  };

  const handleSearchChangeNdcCpt = (pos, e) => {
    if (pos === "1") {
      setAddTrue(false);
      setNDC_CPT_4SearchVal(e);
    } else {
      setNDC_CPT_4SearchVal(e);
    }
  };

  const handleSearchChangeNdcHcpcs = (e) => {
    setNDC_HCPCSSearchVal(e);
  };

  const handleSearchChangeNdcCustom = (e) => {
    setNDC_Cust_CodeSearchVal(e);
  };

  const handleIcd10Codes = (e) => {
    setscode(e);
    setnoresult("1");
    settitle([]);
    setShowOptions(true);
  };

  const handleHcpcsCode = (e) => {
    sethcsearch(e);
    setnoresult("1");
    sethctitle([]);
  };

  const handleIcd9Codes = (e) => {
    seticdnsearch(e);
    setnoresult("1");
    seticdntitle([]);
  };

  const getDatas = async () => {
    // await axiosInstance
    //   .get("/vozo/codesearch")
    //   .then(() => {})
    //   .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozo/Billing/Modifiers")
      .then((response) => {
        let temp_role_for_modifiers = Decrypt_Value(response.data, "vozo");

        setModifiersCodeDropdown(temp_role_for_modifiers);
      })
      .catch(() => setModifiersCodeDropdown([]));

    await axiosInstance

      .get("/vozo/allbill/withall?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_serv_codes = Decrypt_Value(response.data, "vozo");

        console.log(temp_role_serv_codes);

        setAllCodes(temp_role_serv_codes.flat().sort((a, b) => a.id - b.id));
        temp_role_serv_codes[1] &&
          temp_role_serv_codes[1].forEach((x) => {
            setAddedICD((prev) => [...prev, x.code]);
          });
        temp_role_serv_codes[2] &&
          temp_role_serv_codes[2].forEach((x) => {
            setAddedHCPCS((prev) => [...prev, x.code]);
          });
        temp_role_serv_codes[3] &&
          temp_role_serv_codes[3].forEach((x) => {
            setAddedCPT((prev) => [...prev, x.code]);
          });
        temp_role_serv_codes[4] &&
          temp_role_serv_codes[4].forEach((x) => {
            setAddedCustom((prev) => [...prev, x.code]);
          });
        seticdnine(temp_role_serv_codes[0]);
        seticdcode(temp_role_serv_codes[1]);
        sethcpccode(temp_role_serv_codes[2]);
        setfutureId(temp_role_serv_codes[3]);
        setcustomcodeshow(temp_role_serv_codes[4]);
        let count = 0;
        temp_role_serv_codes.forEach((x) => {
          if (x.length > 0) {
            count++;
          }
        });
        if (count > 0) {
          setShowService(false);
        }
      })
      .catch(() => {
        seticdnine([]);
        seticdcode([]);
        sethcpccode([]);
        setfutureId([]);
        setcustomcodeshow([]);
      });

    // await axiosInstance

    //   .get("/vozo/allbill?eid=" + props.eventeid + "&type=CPT4")
    //   .then((response) => {
    //     let temp_role_for_cpt4 = Decrypt_Value(response.data, "vozo");
    //     setfutureId(temp_role_for_cpt4);
    //   })
    //   .catch(() => {
    //     setfutureId([]);
    //   });

    // await axiosInstance

    //   .get("/vozo/allbill?eid=" + props.eventeid + "&type=ICD10")
    //   .then((response) => {
    //     let temp_role_for_icd10 = Decrypt_Value(response.data, "vozo");
    //     seticdcode(temp_role_for_icd10);
    //   })
    //   .catch(() => {
    //     seticdcode([]);
    //   });

    // await axiosInstance
    //   .get("/vozo/allbill?eid=" + props.eventeid + "&type=HCPCS")
    //   .then((response) => {
    //     let temp_role_for_hcpcs = Decrypt_Value(response.data, "vozo");
    //     sethcpccode(temp_role_for_hcpcs);
    //   })
    //   .catch(() => sethcpccode([]));

    // await axiosInstance
    //   .get("/vozo/allbill?eid=" + props.eventeid + "&type=ICD9")
    //   .then((response) => {
    //     let temp_role_for_icd9 = Decrypt_Value(response.data, "vozo");
    //     seticdnine(temp_role_for_icd9);
    //   })
    //   .catch(() => {
    //     seticdnine([]);
    //   });

    // await axiosInstance
    //   .get("/vozo/allbill?eid=" + props.eventeid + "&type=CusCode")
    //   .then((response) => {
    //     let temp_role_for_cuscode = Decrypt_Value(response.data, "vozo");
    //     setcustomcodeshow(temp_role_for_cuscode);
    //   })
    //   .catch(() => {
    //     setcustomcodeshow([]);
    //   });

    await axiosInstance
      .get("/vozo/calculatefee?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_calc_fee = Decrypt_Value(response.data, "vozo");
        setfeetotal(temp_role_calc_fee);
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("/vozo/allcustomcode")
      .then((response) => {
        let temp_role_all_custom_code = Decrypt_Value(response.data, "vozo");

        setallcustomecode(temp_role_all_custom_code);
      })
      .catch((err) => console.error(err));

    await axiosInstance
      .get("vozo/bilingndc?eid=" + props.eventeid + "&type=HCPCS")
      .then((res) => {
        let temp_role_hcpcs_ndc = Decrypt_Value(res.data, "vozo");
        setHCPCS_NDC(temp_role_hcpcs_ndc);
      })
      .catch(() => setHCPCS_NDC([]));

    await axiosInstance

      .get("vozo/bilingndc?eid=" + props.eventeid + "&type=Custom_Code")
      .then((res) => {
        let temp_role_customcode_ndc = Decrypt_Value(res.data, "vozo");

        setCust_Code_NDC(temp_role_customcode_ndc);
      })
      .catch(() => setCust_Code_NDC([]));

    await axiosInstance

      .get("vozo/bilingndc?eid=" + props.eventeid + "&type=CPT4")
      .then((res) => {
        let temp_role_cpt4_ndc = Decrypt_Value(res.data, "vozo");
        setCPT_4_NDC(temp_role_cpt4_ndc);
      })
      .catch(() => setCPT_4_NDC([]));

    await axiosInstance
      .get("/vozo/ndccode")
      .then((response) => {
        let temp_role_ndc_codes = Decrypt_Value(response.data, "vozo");
        setNDC_Code(temp_role_ndc_codes);
        setnoresult(temp_role_ndc_codes);
        setSpinnerVal(false);
        dispatch(changeAppointmentValues({ name: "spinner", value: false }));
      })
      .catch(() => {
        setNDC_Code([]);
        setSpinnerVal(false);
        dispatch(changeAppointmentValues({ name: "spinner", value: false }));
      });
  };

  const handleOptionChange = (event) => {
    setscode("");
    setSearch("");
    sethcsearch("");
    setccsearch("");
    setSelectedOption(event.target.value);
  };
  // ------------------------------ Functions End--------------------------------------------------- //

  // ------------------------------ useEffect Start--------------------------------------------------- //
  useEffect(() => {
    axiosInstance

      .get("/vozo/allbill/withall?eid=" + props.eventeid)
      .then((response) => {
        let temp_role_serv_codes = Decrypt_Value(response.data, "vozo");
        console.log(temp_role_serv_codes, "temp_role_serv_codes");

        setAllCodes(temp_role_serv_codes.flat().sort((a, b) => a.id - b.id));

        seticdnine(temp_role_serv_codes[0]);
        seticdcode(temp_role_serv_codes[1]);
        sethcpccode(temp_role_serv_codes[2]);
        setfutureId(temp_role_serv_codes[3]);
        setcustomcodeshow(temp_role_serv_codes[4]);
        const result1 = temp_role_serv_codes[2].reduce((acc, item) => {
          let trimmedCode = item.code.trim();

          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        const result2 = temp_role_serv_codes[3].reduce((acc, item) => {
          let trimmedCode = item.code.trim();
          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        const result3 = temp_role_serv_codes[4].reduce((acc, item) => {
          let trimmedCode = item.code.trim();
          acc[trimmedCode] = {
            fee: item.fee,
            unit: item.units,
          };
          return acc;
        }, {});
        setFeeHolder({ ...result1, ...result2, ...result3 });

        let count = 0;
        temp_role_serv_codes.forEach((x) => {
          if (x.length > 0) {
            count++;
          }
        });
        if (count > 0) {
          setShowService(false);
        }
      })
      .catch(() => {
        seticdnine([]);
        seticdcode([]);
        sethcpccode([]);
        setfutureId([]);
        setcustomcodeshow([]);
      });
  }, [props.rerender, props.serviceRefreshKey]);

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        console.log("fee holder");
        storeFeeUnits();
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [dummy]);

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (search.length > 0) {
          let cpt4code_search =
            "limit=10&offset=" + 0 + "&code_name=" + search.toLowerCase();
          let cpt4code_search_enc = Encrypt_Value(cpt4code_search, "vozo");
          axiosInstance
            .get("/vozo/cpt4?search=" + cpt4code_search_enc)
            .then((response) => {
              let temp_role_cpt4_search = Decrypt_Value(response.data, "vozo");
              const res = temp_role_cpt4_search.filter(
                (x) => !addedCPT.includes(x.code)
              );

              setFilteredCode(res);
              if (res.length > 0) {
                setnoresult(res);
              } else {
                setnoresult("");
              }
            })
            .catch(() => {
              setFilteredCode([]);
              setnoresult("");
            });
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (ccsearch.length > 0) {
          let customcode_search =
            "limit=10&offset=" + 0 + "&code_name=" + ccsearch.toLowerCase();

          let customcode_search_enc = Encrypt_Value(customcode_search, "vozo");

          axiosInstance
            .get("/vozo/searchcustomcode?search=" + customcode_search_enc)
            .then((response) => {
              let temp_role_custom_search = Decrypt_Value(
                response.data,
                "vozo"
              );
              const res = temp_role_custom_search.filter(
                (x) => !addedCustom.includes(x.code)
              );
              if (res.length > 0) {
                setnoresult(res);
              } else {
                setnoresult("");
              }
              setshowcode(res);
            })
            .catch(() => {
              setshowcode([]);
              setnoresult("");
            });
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [ccsearch]);

  useEffect(() => {
    setSpinnerVal(true);

    getDatas();
    setAddErr(false);
  }, [props.rerender]);

  /////////////////////////////////////////icd10/////////////////////////////////////////////////////

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (scode !== "") {
          let data =
            "limit=10&offset=" + 0 + "&code_name=" + scode.toLowerCase();
          let data1 = Encrypt_Value(data, "vozo");
          axiosInstance
            .get("/vozo/icd?search=" + data1)
            .then((response) => {
              var res3 = Decrypt_Value(response.data, "vozo");
              if (res3.length > 0) {
                setnoresult(res3);
              } else {
                setnoresult("");
              }
              settitle(res3);
              if (txt.length > 0) {
                setnoresult("1");
              } else {
                setnoresult("");
              }
            })
            .catch((err) => {
              settitle([]);
              setnoresult("");
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [scode]);

  /////////////////////////////////////////hcpcs///////////////////////////////////////////////////

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (hcsearch !== "") {
          let data =
            "limit=10&offset=" + 0 + "&code_name=" + hcsearch.toLowerCase();
          let data1 = Encrypt_Value(data, "vozo");
          axiosInstance
            .get("/vozo/hcpcs?search=" + data1)
            .then((response) => {
              var temp_role_hcpcs_search = Decrypt_Value(response.data, "vozo");
              const res = temp_role_hcpcs_search.filter(
                (x) => !addedHCPCS.includes(x.code)
              );
              if (res.length > 0) {
                setnoresult(res);
              } else {
                setnoresult("");
              }
              sethctitle(res);
            })
            .catch((err) => {
              sethctitle([]);
              setnoresult("");
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [hcsearch]);

  /////////////////////////////////////////icd9///////////////////////////////////////////////////

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (icdnsearch !== "") {
          let data =
            "limit=10&offset=" + 0 + "&code_name=" + icdnsearch.toLowerCase();
          let data1 = Encrypt_Value(data, "vozo");
          axiosInstance
            .get("/vozo/icd?search=" + data1)
            .then((response) => {
              var res3 = Decrypt_Value(response.data, "vozo");
              if (res3.length > 0) {
                setnoresult(res3);
              } else {
                setnoresult("");
              }
              seticdntitle(res3);
              if (txt.length > 0) {
                setnoresult("1");
              } else {
                setnoresult("");
              }
            })
            .catch((err) => {
              seticdntitle([]);
              setnoresult("");
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [icdnsearch]);

  ///////////////////////////////////////////////////insert codes custome.///////////////////////////////

  useEffect(() => {
    setNDC_HCPCSResult(
      NDC_Code.filter((row) =>
        row.title.toLowerCase().includes(NDC_HCPCSSearchVal.toLowerCase())
      )
    );
  }, [NDC_HCPCSSearchVal]);

  useEffect(() => {
    setNDC_Cust_CodeResult(
      NDC_Code.filter((row) =>
        row.title.toLowerCase().includes(NDC_Cust_CodeSearchVal.toLowerCase())
      )
    );
  }, [NDC_Cust_CodeSearchVal]);

  useEffect(() => {
    setNDC_CPT_4Result(
      NDC_Code.filter((row) =>
        row.title.toLowerCase().includes(NDC_CPT_4SearchVal.toLowerCase())
      )
    );
  }, [NDC_CPT_4SearchVal]);

  useEffect(() => {
    if (rows && rows.length !== 0) {
      setCpt4Select((prevState) => [
        ...rows.map((row, index) => ({
          select1: row.ma1 === "A1" ? undefined : row.ma1,
          select2: row.mb1 === "B1" ? undefined : row.mb1,
          select3: row.mc1 === "C1" ? undefined : row.mc1,
          select4: row.md1 === "D1" ? undefined : row.md1,
        })),
      ]);
    }
  }, [rows]);

  useEffect(() => {
    if (hcpccode && hcpccode.length !== 0) {
      setHcpcsSelect((prevState) => [
        ...hcpccode.map((row, index) => ({
          select1: row.ma1 === "A1" ? undefined : row.ma1,
          select2: row.mb1 === "B1" ? undefined : row.mb1,
          select3: row.mc1 === "C1" ? undefined : row.mc1,
          select4: row.md1 === "D1" ? undefined : row.md1,
        })),
      ]);
    }
  }, [hcpccode]);

  useEffect(() => {
    if (customcodeshow && customcodeshow.length !== 0) {
      setCustCodeSelect((prevState) => [
        ...customcodeshow.map((row, index) => ({
          select1: row.ma1 === "A1" ? undefined : row.ma1,
          select2: row.mb1 === "B1" ? undefined : row.mb1,
          select3: row.mc1 === "C1" ? undefined : row.mc1,
          select4: row.md1 === "D1" ? undefined : row.md1,
        })),
      ]);
    }
  }, [customcodeshow]);

  // ------------------------------ useEffect End---------------------------------------------------

  return (
    <>
      {SpinnerVal ? (
        <SpinnerDiv height="380px">
          <Spinner animation="border" variant="primary" />
        </SpinnerDiv>
      ) : (
        <>
          {/* {props.savebox && showService ? (
            <Button
              onClick={props.openServiceTab}
              className="close_active service-edit-button outline-primary"
            >
              + Add Services
            </Button>
          ) : ( */}
          <>
            <div className="app-table-cont">
              <div className="appt_heading">Services</div>
              <div className="d-flex">
                <div className="grey-color services_total1">
                  TOTAL FEES
                  <span className="blue-color m-l-5" id="appt_service_fees">
                    {feeLoad ? (
                      <Spinner animation="border" size="sm" variant="primary" />
                    ) : (
                      <span>
                        {currency} {feetotal ? feetotal : "0.00"}
                      </span>
                    )}
                  </span>
                </div>
                <div className="services_total2">
                  <button
                    variant="light"
                    id="dropdown-basic4"
                    type="button"
                    onClick={handleCollapseCompnt}
                    disabled={props.savebox}
                    className="close_active  pat-mob-dd app_det_close_active m-l-12"
                  >
                    <AiOutlinePlus className="m-t--1" /> Add Codes
                  </button>{" "}
                </div>
              </div>
              <div className="d-flex align_item_center">
                <div class="radio-label-container">
                  <input
                    type="radio"
                    id="icd10"
                    name="service"
                    value="icd10"
                    checked={selectedOption === "icd10"}
                    onChange={handleOptionChange}
                  />
                  <label for="hcpcs" class="radio-label">
                    ICD 10 Diagnosis
                  </label>
                </div>
                <div class="radio-label-container">
                  <input
                    type="radio"
                    id="cpt"
                    name="service"
                    value="cpt"
                    checked={selectedOption === "cpt"}
                    onChange={handleOptionChange}
                  />
                  <label for="hcpcs" class="radio-label">
                    CPT Procedure/Service
                  </label>
                </div>
                <div class="radio-label-container">
                  <input
                    type="radio"
                    id="hcpcs"
                    name="service"
                    value="hcpcs"
                    checked={selectedOption === "hcpcs"}
                    onChange={handleOptionChange}
                  />
                  <label for="hcpcs" class="radio-label">
                    HCPCS Procedure/Service
                  </label>
                </div>
                <div class="radio-label-container">
                  <input
                    type="radio"
                    id="custom"
                    name="service"
                    value="custom"
                    checked={selectedOption === "custom"}
                    onChange={handleOptionChange}
                  />
                  <label for="hcpcs" class="radio-label">
                    Custom Codes
                  </label>
                </div>

                <div className="prob-head-right for_service_add_a_c">
                  {selectedOption === "cpt" && (
                    <div
                      className="for_autoComplete_bi for_s_t_a_c m-l-5"
                      id="appt_service_cpt_option"
                    >
                      <AiOutlineSearch />

                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find CPT Codes"
                        dropdownClassName={search === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        disabled={
                          props.savebox && encounter === "0" && encounter === 0
                        }
                        onSelect={(e, data) => {
                          let trimmedCode = data.code.trim();
                          setDummy(trimmedCode);
                          setFeeHolder((x) => ({
                            ...x,
                            [trimmedCode]: {
                              fee: 0,
                              unit: 1,
                            },
                          }));
                          codes(data);
                        }}
                        onChange={handleSearchChange}
                        options={
                          showOptions &&
                          filteredCode.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              code: x.code,
                              code_text: x.code_text,
                              fee: x.fee,
                            };
                          })
                        }
                        value={search}
                        className={`for-service-codes-bground ${caretClr} appt_detail_diagnosis_code topnav_card_top`}
                        id="appt_service_findcpt for_search_scroll_issue"
                      />
                    </div>
                  )}
                  {selectedOption === "icd10" && (
                    <div
                      className="for_autoComplete_bi  for_s_t_a_c m-l-5"
                      id="appt_icd10_option"
                    >
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find ICD10 Codes"
                        onSelect={(e, data) => contactres(data)}
                        disabled={
                          props.savebox && encounter === "0" && encounter === 0
                        }
                        onChange={(e) => {
                          handleIcd10Codes(e);
                          setShowOptions(true);
                        }}
                        id="appt_icd10_code"
                        dropdownClassName={scode === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        options={
                          showOptions &&
                          title.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              title: x.title,
                            };
                          })
                        }
                        value={scode}
                        className={`for-service-codes-bground ${caretClr} topnav_card_top`}
                      />
                    </div>
                  )}
                  {selectedOption === "hcpcs" && (
                    <div
                      className=" for_autoComplete_bi  for_s_t_a_c m-l-5"
                      id="appt_hcpcs_option"
                    >
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find HCPCS Codes"
                        onSelect={(e, data) => {
                          console.log(data, "data");
                          let trimmedCode = data.code.trim();
                          setDummy(trimmedCode);

                          setFeeHolder((x) => ({
                            ...x,
                            [trimmedCode]: {
                              fee: 0,
                              unit: 1,
                            },
                          }));
                          hcpcsres(data);
                        }}
                        id="appt_hcpcs_code"
                        disabled={
                          props.savebox && encounter === "0" && encounter === 0
                        }
                        onChange={(e) => {
                          handleHcpcsCode(e);
                          setShowOptions(true);
                        }}
                        dropdownClassName={hcsearch === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        options={
                          showOptions &&
                          hctitle.map((x) => {
                            return {
                              label: x.title,
                              code: x.code,
                              value: x.title,
                              title: x.title,
                              fee: x.fee,
                            };
                          })
                        }
                        value={hcsearch}
                        className={`for-service-codes-bground ${caretClr} topnav_card_top`}
                      />
                    </div>
                  )}

                  {selectedOption === "custom" && (
                    <div className="for_autoComplete_bi  for_s_t_a_c m-l-5">
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={() => mouseClick("custom")}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find Custom Codes"
                        onSelect={(e, data) => {
                          let trimmedCode = data.code.trim();
                          setDummy(trimmedCode);

                          setFeeHolder((x) => ({
                            ...x,
                            [trimmedCode]: {
                              fee: 0,
                              unit: 1,
                            },
                          }));
                          insertcustomecode(data);
                        }}
                        dropdownClassName={ccsearch === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        disabled={
                          props.savebox && encounter === "0" && encounter === 0
                        }
                        onChange={handlecustomChange}
                        options={
                          showOptions &&
                          showcode.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              code_type: x.code_type,
                              code: x.code,
                              fee: x.fee,
                              code_text: x.code_text,
                              modifier: x.modifier,
                            };
                          })
                        }
                        value={ccsearch}
                        className={`for-service-codes-bground ${caretClr} topnav_card_top`}
                        id="appt_service_custom"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                {/* ///////////////////////////////////////cpt4//////////////////////////////////// */}

                <div className="for-serv-code-overflow appt_Service_sec_table">
                  <Table minWidth="650px">
                    <TableHead className="pat-list-thead appt-head">
                      <Tablerow
                        padding=" 17px 0 17px 16px"
                        lineHeight="21px"
                        color="#005fbe"
                        className="schedule-head-row"
                      >
                        <TableHeadCell className="b-l-none">Type</TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Code
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Description
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Modifier 1
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Modifier 2
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Modifier 3
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Modifier 4
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Justify
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Quantity
                        </TableHeadCell>
                        <TableHeadCell textAlign="left" className="b-l-none">
                          Fee
                        </TableHeadCell>
                        <TableHeadCell
                          textAlign="left"
                          className="b-l-none"
                        ></TableHeadCell>
                        <TableHeadCell
                          textAlign="left"
                          className="b-l-none"
                        ></TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody>
                      {allCodes?.map((row, index) => (
                        <React.Fragment key={index}>
                          {/* ======================ICD 10=========================== */}
                          {row.code_type === "ICD10" && (
                            <Tablerow
                              key={`${index}icd10`}
                              className="table-data-row schedule-body-row for-service-code-search-result"
                              padding=" 6px 0 6px 16px"
                              lineHeight="20px"
                            >
                              <TableBodyCell align="left" className="b-l-none">
                                {"ICD 10"}
                              </TableBodyCell>

                              <TableBodyCell align="left" className="b-l-none">
                                {row.code}
                              </TableBodyCell>
                              <TableBodyCell align="left" className="b-l-none ">
                                {row.code_text}
                              </TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>

                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>
                              <TableBodyCell
                                align="left"
                                className="b-l-none "
                              ></TableBodyCell>

                              <TableBodyCell
                                align="left"
                                className="b-l-none appt_dlt_btn"
                              >
                                <Button
                                  variant="light"
                                  className="m-l-11"
                                  disabled={
                                    row.invoice_id ||
                                    (props.savebox &&
                                      encounter === "0" &&
                                      encounter === 0)
                                      ? true
                                      : false
                                  }
                                  onClick={() => deleteicd(row, props.eventeid)}
                                >
                                  <RiDeleteBin5Line id="appt_service_icd10_svg" />
                                </Button>
                              </TableBodyCell>
                            </Tablerow>
                          )}
                          {/* ======================CPT=========================== */}
                          {row.code_type === "CPT4" && (
                            <>
                              <Tablerow
                                key={`${index}cpt4`}
                                className="table-data-row schedule-body-row for-service-code-search-result"
                                padding=" 6px 0 6px 16px"
                                lineHeight="20px"
                              >
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {"CPT"}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {row.code}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  {row.code_text}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.ma1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MA_CPT_4" + index}
                                    value={
                                      row.ma1 === "A1"
                                        ? undefined
                                        : row.ma1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingCPT_4Update(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select1"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MA_CPT_4`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mb1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MB_CPT_4" + index}
                                    value={
                                      row.mb1 === "B1"
                                        ? undefined
                                        : row.mb1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingCPT_4Update(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select2"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MB_CPT_4`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mc1val}
                                >
                                  <Select
                                    placeholder="Select"
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MC_CPT_4" + index}
                                    value={
                                      row.mc1 === "C1"
                                        ? undefined
                                        : row.mc1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingCPT_4Update(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select3"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MC_CPT_4`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.md1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_CPT_4" + index}
                                    value={
                                      row.md1 === "D1"
                                        ? undefined
                                        : row.md1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingCPT_4Update(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select4"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MD_CPT_4`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Select
                                    mode="multiple"
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_CPT_4" + index}
                                    value={
                                      row.justify
                                        ? row.justify
                                            .split(",")
                                            .map((item) => item.trim())
                                        : []
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(selectedValues) =>
                                      BillingCPT_4PointerUpdate(
                                        row.id,
                                        props.eventeid,
                                        selectedValues
                                      )
                                    }
                                  >
                                    {icdcode.map((moddropdown, index) => (
                                      <Option
                                        key={`${index}MD_CPT_4`}
                                        value={moddropdown.code}
                                      >
                                        {moddropdown.code}{" "}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Form.Control
                                      name="justify"
                                      type="number"
                                      // disabled={
                                      //   row.invoice_id || props.savebox
                                      //     ? true
                                      //     : false
                                      // }
                                      // min="0"
                                      className="we-none w-96px"
                                      // id={"cptfee" + index}
                                      // defaultValue={row.fee}
                                      // onWheel={(e) => e.target.blur()}
                                      // onChange={(e) => e.target.value}
                                    /> */}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    className="we-none w-96px"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    value={feeHolder[row.code.trim()]?.unit}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      // BillingCPT_4UnitUpdate(
                                      //   row.id,
                                      //   props.eventeid,
                                      //   e,
                                      //   row.fee
                                      // )
                                      {
                                        setDummy(e.target.value);
                                        let trimmedCode = row.code.trim();
                                        setFeeHolder((x) => ({
                                          ...x,
                                          [trimmedCode]: {
                                            ...x[trimmedCode],
                                            unit: e.target.value,
                                          },
                                        }));
                                      }
                                    }
                                    onKeyDown={(e) => formatInput(e)}
                                    id={"cpt_4_units" + index}
                                  />
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    type="number"
                                    name="fees"
                                    className="we-none w-96px"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    min="0"
                                    id={"cptfee" + index}
                                    value={feeHolder[row.code.trim()]?.fee}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                      //  if (window.changeTimeout) {
                                      //     clearTimeout(window.changeTimeout);
                                      //   }
                                      //   window.changeTimeout = setTimeout(() => {
                                      //     handlefeeval(
                                      //       row.id,
                                      //       props.eventeid,
                                      //       index,
                                      //       row.units,
                                      //       "CPT4",
                                      //       val,
                                      //       row.code
                                      //     );
                                      //   }, 2000);
                                      setDummy(e.target.value);
                                      let trimmedCode = row.code.trim();

                                      setFeeHolder((x) => ({
                                        ...x,
                                        [trimmedCode]: {
                                          ...x[trimmedCode],
                                          fee: e.target.value,
                                        },
                                      }));
                                    }}
                                  />
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  {/* <Button
                                      className="ndc_button"
                                      // className={
                                      //   addErr
                                      //     ? "err-btn ndc_focus btn-ndc"
                                      //     : "close_active close_active2 ndc_focus btn-ndc"
                                      // }
                                      // style={
                                      //   addErr
                                      //     ? {
                                      //         border: "1px solid red",
                                      //       }
                                      //     : {}
                                      // }
                                      variant="light"
                                      id={"CPT_4NDAdd" + index}
                                      disabled={
                                        row.invoice_id || props.savebox
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        Billing_NDC_Add(row, index, "CPT4")
                                      }
                                    >
                                      + NDC
                                    </Button> */}
                                  {/* {addErr ? (
                                  <span className="red-col fs-10">
                                    not allowed
                                  </span>
                                ) : (
                                  ""
                                )} */}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none appt_dlt_btn"
                                >
                                  <Button
                                    variant="light"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    className="m-l-11"
                                    onClick={() =>
                                      deletebill(row, props.eventeid)
                                    }
                                  >
                                    <RiDeleteBin5Line />
                                  </Button>
                                </TableBodyCell>
                              </Tablerow>
                            </>
                          )}
                          {/* ======================HCPCS=========================== */}
                          {row.code_type === "HCPCS" && (
                            <>
                              <Tablerow
                                key={`${index}hcpcs`}
                                className="table-data-row schedule-body-row for-service-code-search-result"
                                padding=" 6px 0 6px 16px"
                                lineHeight="20px"
                              >
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {"HCPCS"}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {row.code}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  {row.code_text}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.ma1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MA_HCPCS" + index}
                                    value={
                                      row.ma1 === "A1"
                                        ? undefined
                                        : row.ma1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select1"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MA_HCPCS`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mb1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MB_HCPCS" + index}
                                    value={
                                      row.mb1 === "B1"
                                        ? undefined
                                        : row.mb1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select2"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MB_HCPCS`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mc1val}
                                >
                                  <Select
                                    placeholder="Select"
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MC_HCPCS" + index}
                                    value={
                                      row.mc1 === "C1"
                                        ? undefined
                                        : row.mc1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select3"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MC_HCPCS`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.md1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_HCPCS" + index}
                                    value={
                                      row.md1 === "D1"
                                        ? undefined
                                        : row.md1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select4"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MD_HCPCS`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Select
                                    mode="multiple"
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_HCPCS" + index}
                                    value={
                                      row.justify
                                        ? row.justify
                                            .split(",")
                                            .map((item) => item.trim())
                                        : []
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(selectedValues) =>
                                      BillingHCPCSPointerUpdate(
                                        row.id,
                                        props.eventeid,
                                        selectedValues
                                      )
                                    }
                                  >
                                    {icdcode.map((moddropdown, index) => (
                                      <Option
                                        key={`${index}MD_CPT_4`}
                                        value={moddropdown.code}
                                      >
                                        {moddropdown.code}{" "}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Form.Control
                                      name="justify"
                                      type="number"
                                      // disabled={
                                      //   row.invoice_id || props.savebox
                                      //     ? true
                                      //     : false
                                      // }
                                      // min="0"
                                      className="we-none w-96px"
                                      // id={"cptfee" + index}
                                      // defaultValue={row.fee}
                                      // onWheel={(e) => e.target.blur()}
                                      // onChange={(e) => e.target.value}
                                    /> */}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    className="we-none w-96px"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    value={feeHolder[row.code.trim()]?.unit}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      // BillingHCPCSUnitUpdate(
                                      //   row.id,
                                      //   props.eventeid,
                                      //   e.target.value,
                                      //   row.fee
                                      // )
                                      {
                                        setDummy(e.target.value);
                                        let trimmedCode = row.code.trim();
                                        setFeeHolder((x) => ({
                                          ...x,
                                          [trimmedCode]: {
                                            ...x[trimmedCode],
                                            unit: e.target.value,
                                          },
                                        }));
                                      }
                                    }
                                    onKeyDown={(e) => formatInput(e)}
                                    id={"HCPCS_units" + index}
                                  />
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    type="number"
                                    name="fees"
                                    min="0"
                                    className="we-none w-96px"
                                    id={"hcpcs" + index}
                                    value={feeHolder[row.code.trim()]?.fee}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                      // if (window.changeTimeout) {
                                      //   clearTimeout(window.changeTimeout);
                                      // }

                                      // window.changeTimeout = setTimeout(() => {
                                      //   handlefeeval(
                                      //     row.id,
                                      //     props.eventeid,
                                      //     index,
                                      //     row.units,
                                      //     "HCPCS",
                                      //     val,
                                      //     row.code
                                      //   );
                                      // }, 2000);
                                      setDummy(e.target.value);
                                      let trimmedCode = row.code.trim();
                                      setFeeHolder((x) => ({
                                        ...x,
                                        [trimmedCode]: {
                                          ...x[trimmedCode],
                                          fee: e.target.value,
                                        },
                                      }));
                                    }}
                                  />
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Button
                                    className={
                                      addErr
                                        ? "ndc_button err-btn ndc_focus btn-ndc"
                                        : " ndc_button close_active close_active2 ndc_focus btn-ndc"
                                    }
                                    style={
                                      addErr
                                        ? {
                                            border: "1px solid red",
                                          }
                                        : {}
                                    }
                                    variant="light"
                                    id={"HCNDAdd" + index}
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0) ||
                                      HCPCS_NDC.filter(
                                        (item) =>
                                          item.billing_id === row.id &&
                                          item.NDC_procedures === row.code
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      Billing_NDC_Add(row, index, "HCPCS")
                                    }
                                  >
                                    + NDC
                                  </Button>
                                  {/* {addErr ? (
                                    <span className="red-col fs-10">
                                      not allowed
                                    </span>
                                  ) : (
                                    ""
                                  )} */}
                                  {/* {addErr ? (
                                  <span className="red-col fs-10">
                                    not allowed
                                  </span>
                                ) : (
                                  ""
                                )} */}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none appt_dlt_btn"
                                >
                                  <Button
                                    variant="light"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    className="m-l-11"
                                    onClick={() =>
                                      deletehcpc(row, props.eventeid)
                                    }
                                  >
                                    <RiDeleteBin5Line />
                                  </Button>
                                </TableBodyCell>
                              </Tablerow>
                              {HCPCS_NDC.filter(
                                (item) =>
                                  item.billing_id === row.id &&
                                  item.NDC_procedures === row.code
                              ).length > 0 && (
                                <tr>
                                  <td
                                    colSpan={12}
                                    className="ndc-table-wrapper app_det_ndc_tabl"
                                  >
                                    <Table
                                      minWidth="500px"
                                      className="for-ndc-serv-codes"
                                    >
                                      <TableHead
                                        className="pat-list-thead appt-head "
                                        colspan={12}
                                      >
                                        <Tablerow
                                          padding="17px 0 17px 20px"
                                          lineHeight="21px"
                                          color="#005fbe"
                                          className="schedule-head-row tr-no"
                                        >
                                          <TableHeadCell className="p-t-8">
                                            Procedure
                                          </TableHeadCell>
                                          <TableHeadCell className="p-t-8">
                                            NDC Code
                                          </TableHeadCell>
                                          <TableHeadCell className="p-t-8">
                                            Quantity
                                          </TableHeadCell>
                                          <TableHeadCell
                                            className="p-t-8"
                                            align="left"
                                          >
                                            Units
                                          </TableHeadCell>
                                          <TableHeadCell
                                            className="p-t-8"
                                            align="left"
                                          ></TableHeadCell>
                                        </Tablerow>
                                      </TableHead>
                                      <TableBody>
                                        {HCPCS_NDC.filter(
                                          (item) =>
                                            item.billing_id === row.id &&
                                            item.NDC_procedures === row.code
                                        ).map((row, index) => (
                                          <Tablerow
                                            className="table-data-row schedule-body-row for-service-code-search-result"
                                            key={`${index}HCPCS_NDC`}
                                            padding=" 6px 0 6px 18px"
                                            lineHeight="20px"
                                          >
                                            <TableBodyCell
                                              align="left"
                                              className="b-l-none"
                                            >
                                              <div id={`HSPSC_NDCProc` + index}>
                                                {row.NDC_procedures}/:::/$
                                                {row.NDC_fee}.00
                                              </div>
                                            </TableBodyCell>
                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              {row.ndc_code === "" ||
                                              row.ndc_code === null ? (
                                                <div
                                                  id={
                                                    `HSPSC_NDC_Code_Search_head` +
                                                    index
                                                  }
                                                  className="dis-blck for_autoComplete_bi   for_a_c_s_a_t_ndc"
                                                >
                                                  <AiOutlineSearch
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  />
                                                  <AutoComplete
                                                    onClick={mouseClick}
                                                    onMouseLeave={
                                                      handleMouseLeave
                                                    }
                                                    id={
                                                      `HSPSC_NDC_Code` + index
                                                    }
                                                    className={`search_width for-service-codes-bground w-96 ${caretClr}`}
                                                    placeholder="Find NDC Codes"
                                                    disabled={
                                                      props.savebox &&
                                                      encounter === "0" &&
                                                      encounter === 0
                                                    }
                                                    onSelect={(e, data) => {
                                                      Billing_NDC_update(
                                                        row.id,
                                                        index,
                                                        "HCPCS",
                                                        data.title,
                                                        "",
                                                        ""
                                                      );
                                                    }}
                                                    dropdownClassName={
                                                      NDC_HCPCSSearchVal ===
                                                        "" && "for_no_loading"
                                                    }
                                                    notFoundContent={
                                                      showOptions
                                                        ? fornoresults.length !==
                                                          0
                                                          ? "loading..."
                                                          : "No Results Found"
                                                        : ""
                                                    }
                                                    onChange={(e) =>
                                                      handleSearchChangeNdcHcpcs(
                                                        e
                                                      )
                                                    }
                                                    options={
                                                      showOptions &&
                                                      NDC_HCPCSResult.map(
                                                        (x) => {
                                                          return {
                                                            label: x.title,
                                                            value: x.title,
                                                            title: x.title,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    value={NDC_HCPCSSearchVal}
                                                  />
                                                </div>
                                              ) : (
                                                <>
                                                  <div
                                                    className="for_autoComplete_bi remove-marg-right for_a_c_s_a_t_ndc"
                                                    style={
                                                      rowId === index
                                                        ? {
                                                            display: toggle,
                                                          }
                                                        : {
                                                            display: "none",
                                                          }
                                                    }
                                                  >
                                                    <AiOutlineSearch
                                                      style={{
                                                        position: "absolute",
                                                      }}
                                                    />
                                                    <AutoComplete
                                                      onClick={mouseClick}
                                                      onMouseLeave={
                                                        handleMouseLeave
                                                      }
                                                      id={
                                                        `HSPSC_NDC_Code` + index
                                                      }
                                                      className={`search_width for-service-codes-bground w-96 ${caretClr}`}
                                                      placeholder="Find NDC Codes"
                                                      disabled={
                                                        props.savebox &&
                                                        encounter === "0" &&
                                                        encounter === 0
                                                      }
                                                      onSelect={(e, data) => {
                                                        Billing_NDC_update(
                                                          row.id,
                                                          index,
                                                          "HCPCS",
                                                          data.title,
                                                          "",
                                                          ""
                                                        );
                                                      }}
                                                      onChange={(e) =>
                                                        handleSearchChangeNdcHcpcs(
                                                          e
                                                        )
                                                      }
                                                      options={
                                                        showOptions &&
                                                        NDC_HCPCSResult.map(
                                                          (x) => {
                                                            return {
                                                              label: x.title,
                                                              value: x.title,
                                                              title: x.title,
                                                            };
                                                          }
                                                        )
                                                      }
                                                      dropdownClassName={
                                                        NDC_HCPCSSearchVal ===
                                                          "" && "for_no_loading"
                                                      }
                                                      notFoundContent={
                                                        showOptions
                                                          ? fornoresults.length !==
                                                            0
                                                            ? "loading..."
                                                            : "No Results Found"
                                                          : ""
                                                      }
                                                      value={NDC_HCPCSSearchVal}
                                                    />
                                                  </div>

                                                  <div
                                                    id={
                                                      `HSPSC_NDC_Code_Edit` +
                                                      index
                                                    }
                                                    style={
                                                      rowId === index
                                                        ? {
                                                            display: toggle1,
                                                          }
                                                        : {
                                                            display: "flex",
                                                          }
                                                    }
                                                  >
                                                    <Form.Control
                                                      className="w-221px m-r-5"
                                                      disabled={true}
                                                      name="ndccode"
                                                      id={
                                                        `HSPSC_NDC_Code_Search` +
                                                        index +
                                                        row.id
                                                      }
                                                      type="text"
                                                      value={
                                                        row.ndc_code === null
                                                          ? " "
                                                          : row.ndc_code
                                                      }
                                                    />

                                                    <span
                                                      className={`serv-edit-ndc-icon ${
                                                        props.savebox &&
                                                        encounter === "0" &&
                                                        encounter === 0
                                                          ? "disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      <BsPencilSquare
                                                        className={`red-col m-t-10 m-l-5 curs serv-edit-ndc-icon ${
                                                          props.savebox &&
                                                          encounter === "0" &&
                                                          encounter === 0
                                                            ? "disabled"
                                                            : ""
                                                        }`}
                                                        onClick={() =>
                                                          HCPCSSerachElementChange(
                                                            index,
                                                            row.ndc_code,
                                                            row.id
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            </TableBodyCell>

                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              <Form.Group className="for-bor-btm-none-in-ndc">
                                                <Form.Control
                                                  className="w-50px"
                                                  id={
                                                    `HSPSC_NDCQty` +
                                                    index +
                                                    row.id
                                                  }
                                                  name="appduration"
                                                  type="number"
                                                  step="1"
                                                  defaultValue={row.NDC_qty}
                                                  disabled={
                                                    row.ndc_code === null ||
                                                    row.ndc_code === "" ||
                                                    (props.savebox &&
                                                      encounter === "0" &&
                                                      encounter === 0)
                                                      ? true
                                                      : false
                                                  }
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                  onChange={() =>
                                                    Billing_NDC_update(
                                                      row.id,
                                                      index,
                                                      "HCPCS",
                                                      "",
                                                      "",
                                                      ""
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </TableBodyCell>

                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              <Select
                                                suffixIcon={<SuffixIcon />}
                                                className="w-150-mt-3 "
                                                id={
                                                  `HSPSC_NDCUnit` +
                                                  index +
                                                  row.id
                                                }
                                                placeholder="Select"
                                                name="appoint_office"
                                                disabled={
                                                  row.ndc_code === null ||
                                                  row.ndc_code === "" ||
                                                  (props.savebox &&
                                                    encounter === "0" &&
                                                    encounter === 0)
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  row.unit === null
                                                    ? undefined
                                                    : row.unit || undefined
                                                }
                                                onChange={(e) =>
                                                  Billing_NDC_update(
                                                    row.id,
                                                    index,
                                                    "HCPCS",
                                                    "",
                                                    e,
                                                    "hspscndc"
                                                  )
                                                }
                                              >
                                                <Option
                                                  value="F2 (Internal Unit)"
                                                  key="F2 HCPCS"
                                                >
                                                  F2 (Internal Unit)
                                                </Option>
                                                <Option
                                                  value="GR (Gram)"
                                                  key="GR HCPCS"
                                                >
                                                  GR (Gram)
                                                </Option>
                                                <Option
                                                  value="MG (MilliGram)"
                                                  key="MG HCPCS"
                                                >
                                                  MG (MilliGram)
                                                </Option>
                                                <Option
                                                  value="ML (MilliLiter)"
                                                  key="ML HCPCS"
                                                >
                                                  ML (MilliLiter)
                                                </Option>
                                                <Option
                                                  value="UN (Unit)"
                                                  key="UN HCPCS"
                                                >
                                                  UN (Unit)
                                                </Option>
                                              </Select>
                                            </TableBodyCell>

                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top ribdel"
                                            >
                                              <Button
                                                variant="light"
                                                disabled={
                                                  row.invoice_id !== null ||
                                                  (props.savebox &&
                                                    encounter === "0" &&
                                                    encounter === 0)
                                                    ? true
                                                    : false
                                                }
                                                className="m-l-11 for_serv_add_ndc_right"
                                                onClick={() =>
                                                  Billing_NDC_Delete(
                                                    row.id,
                                                    "HCPCS"
                                                  )
                                                }
                                              >
                                                <RiDeleteBin5Line />
                                              </Button>
                                            </TableBodyCell>
                                          </Tablerow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </td>{" "}
                                </tr>
                              )}
                            </>
                          )}
                          {/* ======================Custom=========================== */}
                          {row.code_type === "Custom" && (
                            <>
                              <Tablerow
                                key={`${index}custom`}
                                className="table-data-row schedule-body-row for-service-code-search-result"
                                padding=" 6px 0 6px 16px"
                                lineHeight="20px"
                              >
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {"Custom"}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none"
                                >
                                  {row.code}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  {row.code_text}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.ma1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MA_Cust" + index}
                                    value={
                                      row.ma1 === "A1"
                                        ? undefined
                                        : row.ma1 || undefined
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select1"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MA_Cust`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mb1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MB_Cust" + index}
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select2"
                                      )
                                    }
                                    value={
                                      row.mb1 === "B1"
                                        ? undefined
                                        : row.mb1 || undefined
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MB_Cust`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.mc1val}
                                >
                                  <Select
                                    placeholder="Select"
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MC_Cust" + index}
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select3"
                                      )
                                    }
                                    value={
                                      row.mc1 === "C1"
                                        ? undefined
                                        : row.mc1 || undefined
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MC_Cust`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                  title={row.md1val}
                                >
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_Cust" + index}
                                    className="w-96px m-r-10"
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select4"
                                      )
                                    }
                                    value={
                                      row.md1 === "D1"
                                        ? undefined
                                        : row.md1 || undefined
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MD_Cust`}
                                          value={moddropdown.value}
                                          title={moddropdown.option}
                                        >
                                          {moddropdown.option}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Select
                                    mode="multiple"
                                    suffixIcon={<SuffixIcon />}
                                    placeholder="Select"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    id={"MD_Cust" + index}
                                    value={
                                      row.justify
                                        ? row.justify
                                            .split(",")
                                            .map((item) => item.trim())
                                        : []
                                    }
                                    className="w-96px m-r-10"
                                    onChange={(selectedValues) =>
                                      BillingPointerUpdate(
                                        row.id,
                                        props.eventeid,
                                        selectedValues
                                      )
                                    }
                                  >
                                    {icdcode.map((moddropdown, index) => (
                                      <Option
                                        key={`${index}MD_CPT_4`}
                                        value={moddropdown.code}
                                      >
                                        {moddropdown.code}{" "}
                                      </Option>
                                    ))}
                                  </Select>
                                  {/* <Form.Control
                                      name="justify"
                                      type="number"
                                      // disabled={
                                      //   row.invoice_id || props.savebox
                                      //     ? true
                                      //     : false
                                      // }
                                      // min="0"
                                      className="we-none w-96px"
                                      // id={"cptfee" + index}
                                      // defaultValue={row.fee}
                                      // onWheel={(e) => e.target.blur()}
                                      // onChange={(e) => e.target.value}
                                    /> */}
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    type="number"
                                    name="quantity"
                                    className="we-none w-96px"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    value={feeHolder[row.code.trim()]?.unit}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) =>
                                      // BillingUnitUpdate(
                                      //   row.id,
                                      //   props.eventeid,
                                      //   e.target.value,
                                      //   row.fee
                                      // )
                                      {
                                        setDummy(e.target.value);
                                        let trimmedCode = row.code.trim();
                                        setFeeHolder((x) => ({
                                          ...x,
                                          [trimmedCode]: {
                                            ...x[trimmedCode],
                                            unit: e.target.value,
                                          },
                                        }));
                                      }
                                    }
                                    onKeyDown={(e) => formatInput(e)}
                                    id={"units" + index}
                                  />
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Form.Control
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    type="number"
                                    name="fees"
                                    min="0"
                                    className="we-none w-96px"
                                    id={"customfee" + index}
                                    // defaultValue={row.fee}
                                    value={feeHolder[row.code.trim()]?.fee}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                      // if (window.changeTimeout) {
                                      //   clearTimeout(window.changeTimeout);
                                      // }
                                      // window.changeTimeout = setTimeout(() => {
                                      //   handlefeeval(
                                      //     row.id,
                                      //     props.eventeid,
                                      //     index,
                                      //     row.units,
                                      //     "CusCode",
                                      //     val,
                                      //     row.code
                                      //   );
                                      // }, 2000);
                                      setDummy(e.target.value);
                                      let trimmedCode = row.code.trim();

                                      setFeeHolder((x) => ({
                                        ...x,
                                        [trimmedCode]: {
                                          ...x[trimmedCode],
                                          fee: e.target.value,
                                        },
                                      }));
                                    }}
                                  />
                                </TableBodyCell>
                                <TableBodyCell
                                  align="left"
                                  className="b-l-none "
                                >
                                  <Button
                                    className={
                                      addErr
                                        ? " ndc_button err-btn ndc_focus btn-ndc"
                                        : "ndc_button close_active close_active2 ndc_focus btn-ndc"
                                    }
                                    style={
                                      addErr
                                        ? {
                                            border: "1px solid red",
                                          }
                                        : {}
                                    }
                                    variant="light"
                                    id={"CC_NDAdd" + index}
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0) ||
                                      Cust_Code_NDC.filter(
                                        (item) =>
                                          item.billing_id === row.id &&
                                          item.NDC_procedures === row.code
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      Billing_NDC_Add(row, index, "Custom_Code")
                                    }
                                  >
                                    + NDC
                                  </Button>
                                  {/* {addErr ? (
                                    <span className="red-col fs-10">
                                      not allowed
                                    </span>
                                  ) : (
                                    ""
                                  )} */}
                                </TableBodyCell>

                                <TableBodyCell
                                  align="left"
                                  className="b-l-none appt_dlt_btn"
                                >
                                  <Button
                                    variant="light"
                                    className="m-l-11"
                                    disabled={
                                      row.invoice_id ||
                                      (props.savebox &&
                                        encounter === "0" &&
                                        encounter === 0)
                                        ? true
                                        : false
                                    }
                                    onClick={() =>
                                      deletecustomcode(row, props.eventeid)
                                    }
                                  >
                                    <RiDeleteBin5Line />
                                  </Button>
                                </TableBodyCell>
                              </Tablerow>
                              {Cust_Code_NDC.filter(
                                (item) =>
                                  item.billing_id === row.id &&
                                  item.NDC_procedures === row.code
                              ).length > 0 && (
                                <tr>
                                  <td
                                    colSpan={12}
                                    className="ndc-table-wrapper app_det_ndc_tabl"
                                  >
                                    <Table
                                      minWidth="500px"
                                      className="for-ndc-serv-codes"
                                    >
                                      <TableHead
                                        className="pat-list-thead appt-head "
                                        colspan={12}
                                      >
                                        <Tablerow
                                          padding="17px 0 17px 20px"
                                          lineHeight="21px"
                                          color="#005fbe"
                                          className="schedule-head-row tr-no"
                                        >
                                          <TableHeadCell className="p-t-8">
                                            Procedure
                                          </TableHeadCell>
                                          <TableHeadCell className="p-t-8">
                                            NDC Code
                                          </TableHeadCell>
                                          <TableHeadCell className="p-t-8">
                                            Quantity
                                          </TableHeadCell>
                                          <TableHeadCell
                                            className="p-t-8"
                                            align="left"
                                          >
                                            Units
                                          </TableHeadCell>
                                          <TableHeadCell
                                            className="p-t-8"
                                            align="left"
                                          ></TableHeadCell>
                                        </Tablerow>
                                      </TableHead>
                                      <TableBody>
                                        {Cust_Code_NDC.filter(
                                          (item) =>
                                            item.billing_id === row.id &&
                                            item.NDC_procedures === row.code
                                        ).map((row, index) => (
                                          <Tablerow
                                            className="table-data-row schedule-body-row for-service-code-search-result"
                                            key={`${index}Cust_Code_NDC`}
                                            padding="6px 0 6px 18px"
                                            lineHeight="20px"
                                          >
                                            <TableBodyCell
                                              align="left"
                                              className="b-l-none"
                                            >
                                              <div id={`HSPSC_NDCProc` + index}>
                                                {row.NDC_procedures}/:::/$
                                                {row.NDC_fee}.00
                                              </div>
                                            </TableBodyCell>
                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              {row.ndc_code === "" ||
                                              row.ndc_code === null ? (
                                                <div className="dis-blck for_autoComplete_bi   for_a_c_s_a_t_ndc">
                                                  <AiOutlineSearch
                                                    style={{
                                                      position: "absolute",
                                                    }}
                                                  />
                                                  <AutoComplete
                                                    onClick={mouseClick}
                                                    onMouseLeave={
                                                      handleMouseLeave
                                                    }
                                                    id={
                                                      `Cust_Code_NDC_Code` +
                                                      index
                                                    }
                                                    className={`for-service-codes-bground search_width w-96 ${caretClr}`}
                                                    placeholder="Find NDC Codes"
                                                    onSelect={(e, data) => {
                                                      Billing_NDC_update(
                                                        row.id,
                                                        index,
                                                        "Custom_Code",
                                                        data.title,
                                                        "",
                                                        ""
                                                      );
                                                    }}
                                                    onChange={(e) =>
                                                      handleSearchChangeNdcCustom(
                                                        e
                                                      )
                                                    }
                                                    options={
                                                      showOptions &&
                                                      NDC_Cust_CodeResult.map(
                                                        (x) => {
                                                          return {
                                                            label: x.title,
                                                            value: x.title,
                                                            title: x.title,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    dropdownClassName={
                                                      NDC_Cust_CodeSearchVal ===
                                                        "" && "for_no_loading"
                                                    }
                                                    disabled={
                                                      props.savebox &&
                                                      encounter === "0" &&
                                                      encounter === 0
                                                    }
                                                    notFoundContent={
                                                      showOptions
                                                        ? fornoresults.length !==
                                                          0
                                                          ? "loading..."
                                                          : "No Results Found"
                                                        : ""
                                                    }
                                                    value={
                                                      NDC_Cust_CodeSearchVal
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <>
                                                  <div
                                                    className="for_autoComplete_bi remove-marg-right  for_a_c_s_a_t_ndc"
                                                    style={
                                                      rowId2 === index
                                                        ? { display: l }
                                                        : {
                                                            display: "none",
                                                          }
                                                    }
                                                  >
                                                    <AiOutlineSearch
                                                      style={{
                                                        position: "absolute",
                                                      }}
                                                    />
                                                    <AutoComplete
                                                      onClick={mouseClick}
                                                      onMouseLeave={
                                                        handleMouseLeave
                                                      }
                                                      id={
                                                        `Cust_Code_NDC_Code` +
                                                        index
                                                      }
                                                      className={`for-service-codes-bground search_width w-96 ${caretClr}`}
                                                      placeholder="Find NDC Codes"
                                                      disabled={
                                                        props.savebox &&
                                                        encounter === "0" &&
                                                        encounter === 0
                                                      }
                                                      onSelect={(e, data) => {
                                                        Billing_NDC_update(
                                                          row.id,
                                                          index,
                                                          "Custom_Code",
                                                          data.title,
                                                          "",
                                                          ""
                                                        );
                                                      }}
                                                      onChange={(e) =>
                                                        handleSearchChangeNdcCustom(
                                                          e
                                                        )
                                                      }
                                                      options={
                                                        showOptions &&
                                                        NDC_Cust_CodeResult.map(
                                                          (x) => {
                                                            return {
                                                              label: x.title,
                                                              value: x.title,
                                                              title: x.title,
                                                            };
                                                          }
                                                        )
                                                      }
                                                      dropdownClassName={
                                                        NDC_Cust_CodeSearchVal ===
                                                          "" && "for_no_loading"
                                                      }
                                                      notFoundContent={
                                                        showOptions
                                                          ? fornoresults.length !==
                                                            0
                                                            ? "loading..."
                                                            : "No Results Found"
                                                          : ""
                                                      }
                                                      value={
                                                        NDC_Cust_CodeSearchVal
                                                      }
                                                    />
                                                  </div>

                                                  <div
                                                    id={
                                                      `Cust_Code_NDC_Code_ser` +
                                                      index
                                                    }
                                                    style={
                                                      rowId2 === index
                                                        ? { display: l1 }
                                                        : {
                                                            display: "flex",
                                                          }
                                                    }
                                                  >
                                                    <Form.Control
                                                      className="w-221px m-r-5"
                                                      disabled={true}
                                                      name="ndccode"
                                                      id={
                                                        `Cust_Code_NDC_Code_Search` +
                                                        index +
                                                        row.id
                                                      }
                                                      type="text"
                                                      value={
                                                        row.ndc_code === null
                                                          ? " "
                                                          : row.ndc_code
                                                      }
                                                    />
                                                    <span
                                                      className={`serv-edit-ndc-icon ${
                                                        props.savebox &&
                                                        encounter === "0" &&
                                                        encounter === 0
                                                          ? "disabled"
                                                          : ""
                                                      }`}
                                                    >
                                                      <BsPencilSquare
                                                        className={`red-col m-t-10 m-l-5 curs serv-edit-ndc-icon ${
                                                          props.savebox &&
                                                          encounter === "0" &&
                                                          encounter === 0
                                                            ? "disabled"
                                                            : ""
                                                        }`}
                                                        onClick={() =>
                                                          Cust_CodeSerachElementChange(
                                                            index,
                                                            row.ndc_code,
                                                            row.id
                                                          )
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                            </TableBodyCell>
                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              <Form.Group className="for-bor-btm-none-in-ndc">
                                                <Form.Control
                                                  className="w-50px"
                                                  id={
                                                    `Cust_Code_NDCQty` +
                                                    index +
                                                    row.id
                                                  }
                                                  name="appduration"
                                                  type="number"
                                                  step="1"
                                                  defaultValue={row.NDC_qty}
                                                  disabled={
                                                    row.ndc_code === null ||
                                                    row.ndc_code === "" ||
                                                    (props.savebox &&
                                                      encounter === "0" &&
                                                      encounter === 0)
                                                      ? true
                                                      : false
                                                  }
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                  onChange={() =>
                                                    Billing_NDC_update(
                                                      row.id,
                                                      index,
                                                      "Custom_Code",
                                                      "",
                                                      "",
                                                      ""
                                                    )
                                                  }
                                                />
                                              </Form.Group>
                                            </TableBodyCell>

                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top"
                                            >
                                              <Select
                                                suffixIcon={<SuffixIcon />}
                                                className="w-150-mt-3 "
                                                id={
                                                  `Cust_Code_NDCUnit` +
                                                  index +
                                                  row.id
                                                }
                                                placeholder="Select"
                                                name="appoint_office"
                                                value={
                                                  row.unit === null
                                                    ? undefined
                                                    : row.unit || undefined
                                                }
                                                disabled={
                                                  row.ndc_code === null ||
                                                  row.ndc_code === "" ||
                                                  (props.savebox &&
                                                    encounter === "0" &&
                                                    encounter === 0)
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  Billing_NDC_update(
                                                    row.id,
                                                    index,
                                                    "Custom_Code",
                                                    "",
                                                    e,
                                                    "custndc"
                                                  )
                                                }
                                              >
                                                <option
                                                  value="F2 (Internal Unit)"
                                                  key="F2 Custom_Code"
                                                >
                                                  F2 (Internal Unit)
                                                </option>
                                                <option
                                                  value="GR (Gram)"
                                                  key="GR Custom_Code"
                                                >
                                                  GR (Gram)
                                                </option>
                                                <option
                                                  value="MG (MilliGram)"
                                                  key="MG Custom_Code"
                                                >
                                                  MG (MilliGram)
                                                </option>
                                                <option
                                                  value="ML (MilliLiter)"
                                                  key="ML Custom_Code"
                                                >
                                                  ML (MilliLiter)
                                                </option>
                                                <option
                                                  value="UN (Unit)"
                                                  key="UN Custom_Code"
                                                >
                                                  UN (Unit)
                                                </option>
                                              </Select>
                                            </TableBodyCell>

                                            <TableBodyCell
                                              align="left"
                                              className="b-l-top ribdel"
                                            >
                                              <Button
                                                variant="light"
                                                disabled={
                                                  row.invoice_id !== null ||
                                                  (props.savebox &&
                                                    encounter === "0" &&
                                                    encounter === 0)
                                                    ? true
                                                    : false
                                                }
                                                className="m-l-11 for_serv_add_ndc_right"
                                                onClick={() =>
                                                  Billing_NDC_Delete(
                                                    row.id,
                                                    "Custom_Code"
                                                  )
                                                }
                                              >
                                                <RiDeleteBin5Line />
                                              </Button>
                                            </TableBodyCell>
                                          </Tablerow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </td>{" "}
                                </tr>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                      {allCodes.length === 0 && (
                        <Tablerow className="bot_border">
                          <TableBodyCell
                            className="appCellCenter patpor_main_div"
                            colSpan={10}
                          >
                            <div className="img_top">
                              <Image
                                height="133px"
                                width="133px"
                                src={Emp_data_pic}
                              ></Image>
                            </div>
                          </TableBodyCell>
                        </Tablerow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>

              {/* {CPT_4_NDC == "" ? ( */}
              {/* {CPT_4_NDC.length === 0 ? (
                  ""
                ) : (
                  <div className="m-b-30 for-serv-code-overflow">
                    <Table minWidth="500px" className="for-ndc-serv-codes ">
                      <TableHead className="pat-list-thead appt-head ">
                        <Tablerow
                          padding="17px 0 17px 20px"
                          lineHeight="21px"
                          color="#005fbe"
                          className="schedule-head-row tr-no"
                        >
                          <TableHeadCell className="p-t-8 ">
                            Procedure
                          </TableHeadCell>
                          <TableHeadCell className="p-t-8 ">
                            NDC Code
                          </TableHeadCell>
                          <TableHeadCell className="p-t-8 ">
                            Quantity
                          </TableHeadCell>
                          <TableHeadCell className="p-t-8 " align="left">
                            Units
                          </TableHeadCell>
                          <TableHeadCell
                            className="p-t-8 "
                            align="left"
                          ></TableHeadCell>
                        </Tablerow>
                      </TableHead>
                      <TableBody>
                        {CPT_4_NDC.map((row, index) => (
                          <Tablerow
                            className="table-data-row schedule-body-row for-service-code-search-result"
                            key={`${index}cpt4ndc`}
                            padding="6px 0 6px 18px"
                            lineHeight="20px"
                          >
                            <TableBodyCell align="left" className="b-l-none">
                              <div id={`CPT_4_NDCProc` + index}>
                                {row.NDC_procedures}/:::/${row.NDC_fee}.00
                              </div>
                            </TableBodyCell>
                            <TableBodyCell align="left" className="b-l-top">
                              {row.ndc_code === "" || row.ndc_code === null ? (
                                <div
                                  id={`Cpt_NDC_Code_Search_head` + index}
                                  className="dis-blck for_autoComplete_bi   for_a_c_s_a_t_ndc"
                                >
                                  <AiOutlineSearch
                                    style={{ position: "absolute" }}
                                  />
                                  <AutoComplete
                                    onClick={mouseClick}
                                    onMouseLeave={handleMouseLeave}
                                    id={`CPT_4_NDC_Code` + index}
                                    className={`search_width for-service-codes-bground w-96  ${caretClr}`}
                                    placeholder="Find NDC Codes"
                                    disabled={props.savebox}
                                    onSelect={(e, data) => {
                                      Billing_NDC_update(
                                        row.id,
                                        index,
                                        "CPT4",
                                        data.title,
                                        "",
                                        ""
                                      );
                                    }}
                                    dropdownClassName={
                                      NDC_CPT_4SearchVal === "" &&
                                      "for_no_loading"
                                    }
                                    notFoundContent={
                                      showOptions
                                        ? fornoresults.length !== 0
                                          ? "loading..."
                                          : "No Results Found"
                                        : ""
                                    }
                                    onChange={(e) => {
                                      handleSearchChangeNdcCpt("1", e);
                                    }}
                                    options={
                                      showOptions &&
                                      NDC_CPT_4Result.map((x) => {
                                        return {
                                          label: x.title,
                                          value: x.title,
                                          title: x.title,
                                        };
                                      })
                                    }
                                    value={NDC_CPT_4SearchVal}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="for_autoComplete_bi remove-marg-right  for_a_c_s_a_t_ndc"
                                    style={
                                      rowId1 === index
                                        ? {
                                            display: l2,
                                          }
                                        : { display: "none" }
                                    }
                                  >
                                    <AiOutlineSearch
                                      style={{ position: "absolute" }}
                                    />
                                    <AutoComplete
                                      onClick={mouseClick}
                                      onMouseLeave={handleMouseLeave}
                                      id={`CPT_4_NDC_Code` + index}
                                      className={`search_width for-service-codes-bground w-96 ${caretClr}`}
                                      placeholder="Find NDC Codes"
                                      onSelect={(e, data) => {
                                        Billing_NDC_update(
                                          row.id,
                                          index,
                                          "CPT4",
                                          data.title,
                                          "",
                                          ""
                                        );
                                      }}
                                      disabled={props.savebox}
                                      dropdownClassName={
                                        NDC_CPT_4SearchVal === "" &&
                                        "for_no_loading"
                                      }
                                      notFoundContent={
                                        showOptions
                                          ? fornoresults.length !== 0
                                            ? "loading..."
                                            : "No Results Found"
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleSearchChangeNdcCpt("2", e)
                                      }
                                      options={
                                        showOptions &&
                                        NDC_CPT_4Result.map((x) => {
                                          return {
                                            label: x.title,
                                            value: x.title,
                                            title: x.title,
                                          };
                                        })
                                      }
                                      value={NDC_CPT_4SearchVal}
                                    />
                                  </div>

                                  <div
                                    style={
                                      rowId1 === index
                                        ? {
                                            display: l3,
                                          }
                                        : { display: "flex" }
                                    }
                                  >
                                    <Form.Control
                                      className="w-221px m-r-5"
                                      disabled={true}
                                      name="ndccode"
                                      id={`CPT_4_NDC_Code_search` + index}
                                      type="text"
                                      value={
                                        row.ndc_code === null
                                          ? " "
                                          : row.ndc_code
                                      }
                                    />
                                    <span
                                      className={`serv-edit-ndc-icon ${
                                        props.savebox ? "disabled" : ""
                                      }`}
                                    >
                                      <BsPencilSquare
                                        className={`red-col m-t-10 m-l-5 curs serv-edit-ndc-icon ${
                                          props.savebox ? "disabled" : ""
                                        }`}
                                        onClick={() =>
                                          CPT_4SerachElementChange(
                                            index,
                                            row.ndc_code,
                                            row.id
                                          )
                                        }
                                      />
                                    </span>
                                  </div>
                                </>
                              )}
                            </TableBodyCell>
                            <TableBodyCell align="left" className="b-l-top">
                              <Form.Group className="for-bor-btm-none-in-ndc">
                                <Form.Control
                                  className="w-50px"
                                  id={`CPT_4_NDCQty` + index}
                                  name="appduration"
                                  type="number"
                                  step="1"
                                  defaultValue={row.NDC_qty}
                                  disabled={
                                    row.ndc_code === null ||
                                    row.ndc_code === "" ||
                                    props.savebox
                                      ? true
                                      : false
                                  }
                                  onWheel={(e) => e.target.blur()}
                                  onChange={() =>
                                    Billing_NDC_update(
                                      row.id,
                                      index,
                                      "CPT4",
                                      "",
                                      "",
                                      ""
                                    )
                                  }
                                />
                              </Form.Group>
                            </TableBodyCell>

                            <TableBodyCell align="left" className="b-l-top">
                              <Select
                                suffixIcon={<SuffixIcon />}
                                className="w-150-mt-3 "
                                id={`CPT_4_NDCUnit` + index}
                                placeholder="Select"
                                name="appoint_office"
                                disabled={
                                  row.ndc_code === null ||
                                  row.ndc_code === "" ||
                                  props.savebox
                                    ? true
                                    : false
                                }
                                value={
                                  row.unit === null
                                    ? undefined
                                    : row.unit || undefined
                                }
                                onChange={(e) =>
                                  Billing_NDC_update(
                                    row.id,
                                    index,
                                    "CPT4",
                                    "",
                                    e,
                                    "cptndc"
                                  )
                                }
                              >
                                <Option
                                  value="F2 (Internal Unit)"
                                  key="F2 Cpt4"
                                >
                                  F2 (Internal Unit)
                                </Option>
                                <Option value="GR (Gram)" key="GR Cpt4">
                                  GR (Gram)
                                </Option>
                                <Option value="MG (MilliGram)" key="MG Cpt4">
                                  MG (MilliGram)
                                </Option>
                                <Option value="ML (MilliLiter)" key="ML Cpt4">
                                  ML (MilliLiter)
                                </Option>
                                <Option value="UN (Unit)" key="UN Cpt4">
                                  UN (Unit)
                                </Option>
                              </Select>
                            </TableBodyCell>

                            <TableBodyCell
                              align="left"
                              className="b-l-top ribdel"
                            >
                              <Button
                                variant="light"
                                disabled={
                                  row.invoice_id !== null || props.savebox
                                    ? true
                                    : false
                                }
                                className="m-l-11 for_serv_add_ndc_right"
                                onClick={() =>
                                  Billing_NDC_Delete(row.id, "CPT4")
                                }
                              >
                                <RiDeleteBin5Line />
                              </Button>
                            </TableBodyCell>
                          </Tablerow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                )}*/}
            </div>
            {/* ////////////////////////////////////icd 10////////////////////*/}
            {/* <div className="app-table-cont for-serv-table-top-cs app-table-top">
                <div className="d-flex for-service-codes-box table-top-layer f-12 p-l-16">
                  <div className="prob-head-title m-t-0">ICD-10-CM</div>

                  <div className="prob-head-right for_service_add_a_c">
                    <div
                      className="for_autoComplete_bi  for_s_t_a_c m-l-5"
                      id="appt_icd10_option"
                    >
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find ICD10 Codes"
                        onSelect={(e, data) => contactres(data)}
                        disabled={props.savebox}
                        onChange={(e) => {
                          handleIcd10Codes(e);
                        }}
                        id="appt_icd10_code"
                        dropdownClassName={scode === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        options={
                          showOptions &&
                          title.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              title: x.title,
                            };
                          })
                        }
                        value={scode}
                        className={`for-service-codes-bground ${caretClr}`}
                      />
                    </div>
                  </div>
                </div>
                <Table className="servicetable-width">
                  <TableHead className="pat-list-thead appt-head">
                    <Tablerow
                      padding=" 17px 0 17px 16px !important"
                      lineHeight="21px"
                      color="#005fbe"
                      className="schedule-head-row"
                    >
                      <TableHeadCell className="b-l-none">S.No</TableHeadCell>
                      <TableHeadCell align="left" className="b-l-none">
                        Code
                      </TableHeadCell>
                      <TableHeadCell align="left" className="b-l-none">
                        Description
                      </TableHeadCell>

                      <TableHeadCell
                        align="left"
                        className="b-l-none"
                      ></TableHeadCell>
                    </Tablerow>
                  </TableHead>
                  <TableBody>
                    {icdcode.map((row, index) => (
                      <Tablerow
                        key={`${index}icd10`}
                        className="table-data-row schedule-body-row for-service-code-search-result"
                        padding="6px 0 6px 16px"
                        lineHeight="20px"
                      >
                        <TableBodyCell align="left" className="b-l-none">
                          <div>{index + 1}</div>
                        </TableBodyCell>

                        <TableBodyCell align="left" className="b-l-none">
                          {row.code}
                        </TableBodyCell>
                        <TableBodyCell align="left" className="b-l-none w-40">
                          {row.code_text}
                        </TableBodyCell>
                        <TableBodyCell>
                          <Button
                            variant="light"
                            className="m-l-11"
                            disabled={
                              row.invoice_id || props.savebox ? true : false
                            }
                            onClick={() => deleteicd(row.id, props.eventeid)}
                          >
                            <RiDeleteBin5Line id="appt_service_icd10_svg" />
                          </Button>
                        </TableBodyCell>
                      </Tablerow>
                    ))}
                  </TableBody>
                </Table>
              </div> */}
            {/* ////////////////////////////////////hcpcs//////////////////////////////// */}
            {/* <div className="app-table-cont for-serv-table-top-cs app-table-top">
                <div className="d-flex for-service-codes-box table-top-layer f-12 p-l-16">
                  <div className="prob-head-title m-t-0">HCPCS Codes</div>

                  <div className="prob-head-right for_service_add_a_c">
                    <div
                      className=" for_autoComplete_bi  for_s_t_a_c m-l-5"
                      id="appt_hcpcs_option"
                    >
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find HCPCS Codes"
                        onSelect={(e, data) => hcpcsres(data)}
                        id="appt_hcpcs_code"
                        disabled={props.savebox}
                        onChange={(e) => {
                          handleHcpcsCode(e);
                        }}
                        dropdownClassName={hcsearch === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        options={
                          showOptions &&
                          hctitle.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              title: x.title,
                            };
                          })
                        }
                        value={hcsearch}
                        className={`for-service-codes-bground ${caretClr}`}
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="for-serv-code-overflow">
                  <Table minWidth="650px">
                    <TableHead className="pat-list-thead appt-head">
                      <Tablerow
                        padding=" 17px 0 17px 16px !important"
                        lineHeight="21px"
                        color="#005fbe"
                        className="schedule-head-row"
                      >
                        <TableHeadCell className="b-l-none">S.No</TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Code
                        </TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Description
                        </TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Fee
                        </TableHeadCell>
                        <TableHeadCell
                          align="left"
                          className="b-l-none"
                        ></TableHeadCell>
                        <TableHeadCell
                          align="left"
                          className="b-l-none"
                        ></TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody>
                      {hcpccode.map((row, index) => (
                        <React.Fragment key={index}>
                          <Tablerow
                            key={`${index}hcpccode`}
                            className="table-data-row schedule-body-row for-service-code-search-result"
                            padding=" 6px 0 6px 18px"
                            lineHeight="20px"
                          >
                            <TableBodyCell align="left" className="b-l-none">
                              <div>{index + 1}</div>
                            </TableBodyCell>
                            <TableBodyCell align="left" className="b-l-none">
                              {row.code}
                            </TableBodyCell>
                            <TableBodyCell align="left" className="b-l-none  ">
                              {row.code_text}
                            </TableBodyCell>
                            <TableBodyCell className="b-r-block dis-flx">
                              <Form.Control
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                type="number"
                                min="0"
                                className="we-none w-70"
                                id={"hcpcs" + index}
                                defaultValue={row.fee}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => e.target.value}
                              />
                              {props.savebox ? (
                                <button
                                  variant="light"
                                  type="button"
                                  onClick={() => {
                                    handlefeeval(
                                      row.id,
                                      props.eventeid,
                                      index,
                                      row.units,
                                      "HCPCS"
                                    );
                                  }}
                                  disabled={props.savebox}
                                  className="pat-mob-dd m-l-12 servicedetails_tick_btn servicedetails_tick_btnalt"
                                >
                                  <FaCheckCircle className="apptservicedetail_tickicon" />
                                </button>
                              ) : (
                                <button
                                  variant="light"
                                  type="button"
                                  onClick={() => {
                                    handlefeeval(
                                      row.id,
                                      props.eventeid,
                                      index,
                                      row.units,
                                      "HCPCS"
                                    );
                                  }}
                                  className="close_active pat-mob-dd app_det_close_active m-l-12"
                                >
                                  Save
                                </button>
                              )}
                            </TableBodyCell>
                            <TableBodyCell
                              rowSpan="2"
                              align="left"
                              className="b-l-none"
                            >
                              <Button
                                className={
                                  addErr
                                    ? "err-btn ndc_focus btn-ndc"
                                    : " close_active close_active2 ndc_focus btn-ndc"
                                }
                                style={
                                  addErr
                                    ? {
                                        border: "1px solid red",
                                      }
                                    : {}
                                }
                                variant="light"
                                id={"HCNDAdd" + index}
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                onClick={() =>
                                  Billing_NDC_Add(row, index, "HCPCS")
                                }
                              >
                                + NDC
                              </Button>
                              {addErr ? (
                                <span className="red-col fs-10">
                                  not allowed
                                </span>
                              ) : (
                                ""
                              )}
                            </TableBodyCell>
                            <TableBodyCell rowSpan="2" className="b-l-top">
                              <Button
                                variant="light"
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                className="m-l-11"
                                onClick={() =>
                                  deletehcpc(row.id, props.eventeid)
                                }
                              >
                                <RiDeleteBin5Line />
                              </Button>
                            </TableBodyCell>
                          </Tablerow>

                          <Tablerow key={`${index}hcpccodemod`}>
                            <TableBodyCell
                              colSpan="4"
                              className="b-r-block b-rgt"
                            >
                              <Form.Group as={Col}>
                                <Form.Label className="m-r-100 ">
                                  Modifiers
                                </Form.Label>
                                <div className="dis-flx ">
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MA_HCPCS" + index}
                                    value={
                                      row.ma1 === "A1"
                                        ? undefined
                                        : row.ma1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select1"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MA_HCPCS`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>

                                  <div className="w-1 "></div>
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MB_HCPCS" + index}
                                    value={
                                      row.mb1 === "B1"
                                        ? undefined
                                        : row.mb1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select2"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MB_HCPCS`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                  <div className="w-1 "></div>

                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    as="select"
                                    id={"MC_HCPCS" + index}
                                    value={
                                      row.mc1 === "C1"
                                        ? undefined
                                        : row.mc1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select3"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MC_HCPCS`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                  <div className="w-1 "></div>

                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    as="select"
                                    id={"MD_HCPCS" + index}
                                    value={
                                      row.md1 === "D1"
                                        ? undefined
                                        : row.md1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                    onChange={(e) =>
                                      BillingHCPCSUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select4"
                                      )
                                    }
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MD_HCPCS`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </div>
                              </Form.Group>
                              <div className="m-t-10  dis-flx ">
                                <div>
                                  <Form.Group as={Col}>
                                    <Form.Label className="m-r-100 ">
                                      Quantity:
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      disabled={
                                        row.invoice_id || props.savebox
                                          ? true
                                          : false
                                      }
                                      defaultValue={row.units}
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        BillingHCPCSUnitUpdate(
                                          row.id,
                                          props.eventeid,
                                          e.target.value,
                                          row.fee
                                        )
                                      }
                                      onKeyDown={(e) => formatInput(e)}
                                      id={"HCPCS_units" + index}
                                    />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group as={Col}>
                                    <Form.Label className="m-r-100 ">
                                      Pointers:
                                    </Form.Label>
                                    <Form.Control
                                      disabled={
                                        row.invoice_id || props.savebox
                                          ? true
                                          : false
                                      }
                                      defaultValue={row.diagnosis_pointer}
                                      id={"HCPCS_pointer" + index}
                                      onChange={(e) =>
                                        BillingHCPCSPointerUpdate(
                                          row.id,
                                          props.eventeid,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) => formatInput(e)}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </TableBodyCell>
                          </Tablerow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>

              {HCPCS_NDC.length !== 0 && (
                <div className="m-b-30 for-serv-code-overflow">
                  <Table minWidth="500px" className="for-ndc-serv-codes">
                    <TableHead className="pat-list-thead appt-head ">
                      <Tablerow
                        padding="17px 0 17px 20px"
                        lineHeight="21px"
                        color="#005fbe"
                        className="schedule-head-row tr-no"
                      >
                        <TableHeadCell className="p-t-8 ">
                          Procedure
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 ">
                          NDC Code
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 ">
                          Quantity
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 " align="left">
                          Units
                        </TableHeadCell>
                        <TableHeadCell
                          className="p-t-8 "
                          align="left"
                        ></TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody>
                      {HCPCS_NDC.map((row, index) => (
                        <Tablerow
                          className="table-data-row schedule-body-row for-service-code-search-result"
                          key={`${index}HCPCS_NDC`}
                          padding=" 6px 0 6px 18px"
                          lineHeight="20px"
                        >
                          <TableBodyCell align="left" className="b-l-none">
                            <div id={`HSPSC_NDCProc` + index}>
                              {row.NDC_procedures}/:::/${row.NDC_fee}.00
                            </div>
                          </TableBodyCell>
                          <TableBodyCell align="left" className="b-l-top">
                            {row.ndc_code === "" || row.ndc_code === null ? (
                              <div
                                id={`HSPSC_NDC_Code_Search_head` + index}
                                className="dis-blck for_autoComplete_bi   for_a_c_s_a_t_ndc"
                              >
                                <AiOutlineSearch
                                  style={{ position: "absolute" }}
                                />
                                <AutoComplete
                                  onClick={mouseClick}
                                  onMouseLeave={handleMouseLeave}
                                  id={`HSPSC_NDC_Code` + index}
                                  className={`search_width for-service-codes-bground w-96 ${caretClr}`}
                                  placeholder="Find NDC Codes"
                                  disabled={props.savebox}
                                  onSelect={(e, data) => {
                                    Billing_NDC_update(
                                      row.id,
                                      index,
                                      "HCPCS",
                                      data.title,
                                      "",
                                      ""
                                    );
                                  }}
                                  dropdownClassName={
                                    NDC_HCPCSSearchVal === "" &&
                                    "for_no_loading"
                                  }
                                  notFoundContent={
                                    showOptions
                                      ? fornoresults.length !== 0
                                        ? "loading..."
                                        : "No Results Found"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleSearchChangeNdcHcpcs(e)
                                  }
                                  options={
                                    showOptions &&
                                    NDC_HCPCSResult.map((x) => {
                                      return {
                                        label: x.title,
                                        value: x.title,
                                        title: x.title,
                                      };
                                    })
                                  }
                                  value={NDC_HCPCSSearchVal}
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="for_autoComplete_bi remove-marg-right for_a_c_s_a_t_ndc"
                                  style={
                                    rowId === index
                                      ? { display: toggle }
                                      : { display: "none" }
                                  }
                                >
                                  <AiOutlineSearch
                                    style={{ position: "absolute" }}
                                  />
                                  <AutoComplete
                                    onClick={mouseClick}
                                    onMouseLeave={handleMouseLeave}
                                    id={`HSPSC_NDC_Code` + index}
                                    className={`search_width for-service-codes-bground w-96 ${caretClr}`}
                                    placeholder="Find NDC Codes"
                                    disabled={props.savebox}
                                    onSelect={(e, data) => {
                                      Billing_NDC_update(
                                        row.id,
                                        index,
                                        "HCPCS",
                                        data.title,
                                        "",
                                        ""
                                      );
                                    }}
                                    onChange={(e) =>
                                      handleSearchChangeNdcHcpcs(e)
                                    }
                                    options={
                                      showOptions &&
                                      NDC_HCPCSResult.map((x) => {
                                        return {
                                          label: x.title,
                                          value: x.title,
                                          title: x.title,
                                        };
                                      })
                                    }
                                    dropdownClassName={
                                      NDC_HCPCSSearchVal === "" &&
                                      "for_no_loading"
                                    }
                                    notFoundContent={
                                      showOptions
                                        ? fornoresults.length !== 0
                                          ? "loading..."
                                          : "No Results Found"
                                        : ""
                                    }
                                    value={NDC_HCPCSSearchVal}
                                  />
                                </div>

                                <div
                                  id={`HSPSC_NDC_Code_Edit` + index}
                                  style={
                                    rowId === index
                                      ? { display: toggle1 }
                                      : { display: "flex" }
                                  }
                                >
                                  <Form.Control
                                    className="w-221px m-r-5"
                                    disabled={true}
                                    name="ndccode"
                                    id={`HSPSC_NDC_Code_Search` + index}
                                    type="text"
                                    value={
                                      row.ndc_code === null ? " " : row.ndc_code
                                    }
                                  />

                                  <span
                                    className={`serv-edit-ndc-icon ${
                                      props.savebox ? "disabled" : ""
                                    }`}
                                  >
                                    <BsPencilSquare
                                      className={`red-col m-t-10 m-l-5 curs serv-edit-ndc-icon ${
                                        props.savebox ? "disabled" : ""
                                      }`}
                                      onClick={() =>
                                        HCPCSSerachElementChange(
                                          index,
                                          row.ndc_code,
                                          row.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              </>
                            )}
                          </TableBodyCell>

                          <TableBodyCell align="left" className="b-l-top">
                            <Form.Group className="for-bor-btm-none-in-ndc">
                              <Form.Control
                                className="w-50px"
                                id={`HSPSC_NDCQty` + index}
                                name="appduration"
                                type="number"
                                step="1"
                                defaultValue={row.NDC_qty}
                                disabled={
                                  row.ndc_code === null ||
                                  row.ndc_code === "" ||
                                  props.savebox
                                    ? true
                                    : false
                                }
                                onWheel={(e) => e.target.blur()}
                                onChange={() =>
                                  Billing_NDC_update(
                                    row.id,
                                    index,
                                    "HCPCS",
                                    "",
                                    "",
                                    ""
                                  )
                                }
                              />
                            </Form.Group>
                          </TableBodyCell>

                          <TableBodyCell align="left" className="b-l-top">
                            <Select
                              suffixIcon={<SuffixIcon />}
                              className="w-150-mt-3 "
                              id={`HSPSC_NDCUnit` + index}
                              placeholder="Select"
                              name="appoint_office"
                              disabled={
                                row.ndc_code === null ||
                                row.ndc_code === "" ||
                                props.savebox
                                  ? true
                                  : false
                              }
                              value={
                                row.unit === null
                                  ? undefined
                                  : row.unit || undefined
                              }
                              onChange={(e) =>
                                Billing_NDC_update(
                                  row.id,
                                  index,
                                  "HCPCS",
                                  "",
                                  e,
                                  "hspscndc"
                                )
                              }
                            >
                              <Option value="F2 (Internal Unit)" key="F2 HCPCS">
                                F2 (Internal Unit)
                              </Option>
                              <Option value="GR (Gram)" key="GR HCPCS">
                                GR (Gram)
                              </Option>
                              <Option value="MG (MilliGram)" key="MG HCPCS">
                                MG (MilliGram)
                              </Option>
                              <Option value="ML (MilliLiter)" key="ML HCPCS">
                                ML (MilliLiter)
                              </Option>
                              <Option value="UN (Unit)" key="UN HCPCS">
                                UN (Unit)
                              </Option>
                            </Select>
                          </TableBodyCell>

                          <TableBodyCell
                            align="left"
                            className="b-l-top ribdel"
                          >
                            <Button
                              variant="light"
                              disabled={
                                row.invoice_id !== null || props.savebox
                                  ? true
                                  : false
                              }
                              className="m-l-11 for_serv_add_ndc_right"
                              onClick={() =>
                                Billing_NDC_Delete(row.id, "HCPCS")
                              }
                            >
                              <RiDeleteBin5Line />
                            </Button>
                          </TableBodyCell>
                        </Tablerow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )} */}

            {/* ////////////////////////////////////icd 9//////////////////////////////// */}
            {/* <div className="app-table-cont for-serv-table-top-cs app-table-top">
                <div className="d-flex for-service-codes-box table-top-layer f-12 p-l-16">
                  <div className="prob-head-title m-t-0">
                    ICD-9 Codes to Convert
                  </div>

                  <div className="prob-head-right for_service_add_a_c">
                    <div
                      className="for_autoComplete_bi  for_s_t_a_c m-l-5"
                      id="appt_service_icd9_option"
                    >
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={mouseClick}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find ICD9 Codes"
                        onSelect={(e, data) => icdnres(data)}
                        onChange={(e) => {
                          handleIcd9Codes(e);
                        }}
                        results={icdntitle}
                        options={
                          showOptions &&
                          icdntitle.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              title: x.title,
                            };
                          })
                        }
                        dropdownClassName={
                          icdnsearch === "" && "for_no_loading"
                        }
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        value={icdnsearch}
                        disabled={props.savebox}
                        className={`for-service-codes-bground ${caretClr}`}
                        id="appt_service_icd9"
                      />
                    </div>
                  </div>
                </div>
                <Table className="servicetable-width">
                  <TableHead className="pat-list-thead appt-head">
                    <Tablerow
                      padding=" 17px 0 17px 16px !important"
                      lineHeight="21px"
                      color="#005fbe"
                      className="schedule-head-row"
                    >
                      <TableHeadCell className="b-l-none">S.No</TableHeadCell>
                      <TableHeadCell align="left" className="b-l-none">
                        Code
                      </TableHeadCell>
                      <TableHeadCell align="left" className="b-l-none">
                        Description
                      </TableHeadCell>

                      <TableHeadCell
                        align="left"
                        className="b-l-none"
                      ></TableHeadCell>
                    </Tablerow>
                  </TableHead>
                  <TableBody>
                    {icdnine.map((row, index) => (
                      <Tablerow
                        key={`${index}icdnine`}
                        className="table-data-row schedule-body-row for-service-code-search-result"
                        padding=" 6px 0 6px 16px !important"
                        lineHeight="20px"
                      >
                        <TableBodyCell align="left" className="b-l-none">
                          <div>{index + 1}</div>
                        </TableBodyCell>

                        <TableBodyCell align="left" className="b-l-none">
                          {row.code}
                        </TableBodyCell>
                        <TableBodyCell align="left" className="b-l-none w-40">
                          {row.code_text}
                        </TableBodyCell>
                        <TableBodyCell>
                          <Button
                            variant="light"
                            className="m-l-11"
                            disabled={
                              row.invoice_id !== null || props.savebox
                                ? true
                                : false
                            }
                            onClick={() => deleteicdn(row.id, props.eventeid)}
                          >
                            <RiDeleteBin5Line />
                          </Button>
                        </TableBodyCell>
                      </Tablerow>
                    ))}
                  </TableBody>
                </Table>
              </div> */}
            {/* ///////////////////////////////////custome code///////////////////////////////////// */}
            {/* <div className="app-table-cont lazy-load-get-height for-serv-table-top-cs app-table-top">
                <div className="d-flex for-service-codes-box table-top-layer p-l-16">
                  <div className="prob-head-title m-t-0">Custom Codes</div>

                  <div className="prob-head-right for_service_add_a_c">
                    <div className="for_autoComplete_bi  for_s_t_a_c m-l-5">
                      <AiOutlineSearch />
                      <AutoComplete
                        onClick={() => mouseClick("custom")}
                        onMouseLeave={handleMouseLeave}
                        placeholder="Find Custom Codes"
                        onSelect={(e, data) => insertcustomecode(data)}
                        dropdownClassName={ccsearch === "" && "for_no_loading"}
                        notFoundContent={
                          showOptions
                            ? fornoresults.length !== 0
                              ? "loading..."
                              : "No Results Found"
                            : ""
                        }
                        disabled={props.savebox}
                        onChange={handlecustomChange}
                        options={
                          showOptions &&
                          showcode.map((x) => {
                            return {
                              label: x.title,
                              value: x.title,
                              code_type: x.code_type,
                              code: x.code,
                              fee: x.fee,
                              code_text: x.code_text,
                              modifier: x.modifier,
                            };
                          })
                        }
                        value={ccsearch}
                        className={`for-service-codes-bground ${caretClr}`}
                        id="appt_service_custom"
                      />
                    </div>
                  </div>
                </div>
                <div className="for-serv-code-overflow">
                  <Table minWidth="650px" className="f-12">
                    <TableHead className="pat-list-thead appt-head">
                      <Tablerow
                        padding=" 17px 0 17px 14px !important"
                        lineHeight="21px"
                        color="#005fbe"
                        className="schedule-head-row"
                      >
                        <TableHeadCell className="b-l-none">S.No</TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Code
                        </TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Description
                        </TableHeadCell>
                        <TableHeadCell align="left" className="b-l-none">
                          Fee
                        </TableHeadCell>
                        <TableHeadCell
                          align="left"
                          className="b-l-none"
                        ></TableHeadCell>
                        <TableHeadCell
                          align="left"
                          className="b-l-none"
                        ></TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody>
                      {customcodeshow.map((row, index) => (
                        <React.Fragment key={index}>
                          <Tablerow
                            key={`${index}customcodeshow`}
                            className="table-data-row schedule-body-row for-service-code-search-result"
                            padding=" 6px 0 6px 16px"
                            lineHeight="20px"
                          >
                            <TableBodyCell align="left" className="b-l-none">
                              <div>{index + 1}</div>
                            </TableBodyCell>

                            <TableBodyCell align="left" className="b-l-none">
                              {row.code}
                            </TableBodyCell>
                            <TableBodyCell align="left" className="b-l-none  ">
                              {row.code_text}
                            </TableBodyCell>
                            <TableBodyCell className="b-r-block dis-flx">
                              <Form.Control
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                type="number"
                                min="0"
                                className="we-none w-70"
                                id={"customfee" + index}
                                defaultValue={row.fee}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => e.target.value}
                              />
                              {props.savebox ? (
                                <button
                                  variant="light"
                                  type="button"
                                  disabled={props.savebox}
                                  onClick={() => {
                                    handlefeeval(
                                      row.id,
                                      props.eventeid,
                                      index,
                                      row.units,
                                      "CusCode"
                                    );
                                  }}
                                  className="pat-mob-dd m-l-12 servicedetails_tick_btn servicedetails_tick_btnalt"
                                >
                                  <FaCheckCircle className="apptservicedetail_tickicon" />
                                </button>
                              ) : (
                                <button
                                  variant="light"
                                  type="button"
                                  onClick={() => {
                                    handlefeeval(
                                      row.id,
                                      props.eventeid,
                                      index,
                                      row.units,
                                      "CusCode"
                                    );
                                  }}
                                  className="close_active pat-mob-dd app_det_close_active m-l-12"
                                >
                                  Save
                                </button>
                              )}
                            </TableBodyCell>
                            <TableBodyCell
                              rowSpan="2"
                              align="left"
                              className="b-l-none"
                            >
                              <Button
                                className={
                                  addErr
                                    ? "err-btn ndc_focus btn-ndc"
                                    : "close_active close_active2 ndc_focus btn-ndc"
                                }
                                style={
                                  addErr
                                    ? {
                                        border: "1px solid red",
                                      }
                                    : {}
                                }
                                variant="light"
                                id={"CC_NDAdd" + index}
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                onClick={() =>
                                  Billing_NDC_Add(row, index, "Custom_Code")
                                }
                              >
                                + NDC
                              </Button>
                              {addErr ? (
                                <span className="red-col fs-10">
                                  not allowed
                                </span>
                              ) : (
                                ""
                              )}
                            </TableBodyCell>
                            <TableBodyCell rowSpan="2" className=" b-l-top">
                              <Button
                                variant="light"
                                className="m-l-11"
                                disabled={
                                  row.invoice_id || props.savebox ? true : false
                                }
                                onClick={() =>
                                  deletecustomcode(row.id, props.eventeid)
                                }
                              >
                                <RiDeleteBin5Line />
                              </Button>
                            </TableBodyCell>
                          </Tablerow>
                          <Tablerow
                            key={`${index}customcodeshowmod`}
                            padding=" 7px 0 6px 2px"
                            lineHeight="20px"
                          >
                            <TableBodyCell colSpan="4" className="b-r-block">
                              <Form.Group as={Col}>
                                <Form.Label className="m-r-100 ">
                                  Modifiers
                                </Form.Label>
                                <div className="dis-flx ">
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MA_Cust" + index}
                                    value={
                                      row.ma1 === "A1"
                                        ? undefined
                                        : row.ma1 || undefined
                                    }
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select1"
                                      )
                                    }
                                    className="w-266 m-r-10"
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MA_Cust`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>

                                  <div className="w-1 "></div>
                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MB_Cust" + index}
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select2"
                                      )
                                    }
                                    value={
                                      row.mb1 === "B1"
                                        ? undefined
                                        : row.mb1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MB_Cust`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                  <div className="w-1 "></div>

                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MC_Cust" + index}
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select3"
                                      )
                                    }
                                    value={
                                      row.mc1 === "C1"
                                        ? undefined
                                        : row.mc1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MC_Cust`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                  <div className="w-1 "></div>

                                  <Select
                                    suffixIcon={<SuffixIcon />}
                                    disabled={
                                      row.invoice_id || props.savebox
                                        ? true
                                        : false
                                    }
                                    placeholder="Select"
                                    id={"MD_Cust" + index}
                                    onChange={(e) =>
                                      BillingUpdate(
                                        row.id,
                                        props.eventeid,
                                        index,
                                        e,
                                        "select4"
                                      )
                                    }
                                    value={
                                      row.md1 === "D1"
                                        ? undefined
                                        : row.md1 || undefined
                                    }
                                    className="w-266 m-r-10"
                                  >
                                    {ModifiersCodeDropdown.map(
                                      (moddropdown, index) => (
                                        <Option
                                          key={`${index}MD_Cust`}
                                          value={moddropdown.value}
                                        >
                                          {moddropdown.option}{" "}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </div>
                              </Form.Group>
                              <div className="m-t-10  dis-flx ">
                                <div>
                                  <Form.Group as={Col}>
                                    <Form.Label className="m-r-100 ">
                                      Quantity:
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      disabled={
                                        row.invoice_id || props.savebox
                                          ? true
                                          : false
                                      }
                                      defaultValue={row.units}
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) =>
                                        BillingUnitUpdate(
                                          row.id,
                                          props.eventeid,
                                          e.target.value,
                                          row.fee
                                        )
                                      }
                                      onKeyDown={(e) => formatInput(e)}
                                      id={"units" + index}
                                    />
                                  </Form.Group>
                                </div>
                                <div>
                                  <Form.Group as={Col}>
                                    <Form.Label className="m-r-100 ">
                                      Pointers:
                                    </Form.Label>
                                    <Form.Control
                                      disabled={
                                        row.invoice_id || props.savebox
                                          ? true
                                          : false
                                      }
                                      defaultValue={row.diagnosis_pointer}
                                      id={"pointer" + index}
                                      onChange={(e) =>
                                        BillingPointerUpdate(
                                          row.id,
                                          props.eventeid,
                                          e.target.value
                                        )
                                      }
                                      onKeyDown={(e) => formatInput(e)}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                            </TableBodyCell>
                          </Tablerow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div> */}

            {/* {Cust_Code_NDC.length !== 0 && (
                <div className="m-b-30 for-serv-code-overflow">
                  <Table minWidth="500px" className="for-ndc-serv-codes ">
                    <TableHead className="pat-list-thead appt-head ">
                      <Tablerow
                        padding="17px 0 17px 20px"
                        lineHeight="21px"
                        color="#005fbe"
                        className="schedule-head-row tr-no"
                      >
                        <TableHeadCell className="p-t-8 ">
                          Procedure
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 ">
                          NDC Code
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 ">
                          Quantity
                        </TableHeadCell>
                        <TableHeadCell className="p-t-8 " align="left">
                          Units
                        </TableHeadCell>
                        <TableHeadCell
                          className="p-t-8 "
                          align="left"
                        ></TableHeadCell>
                      </Tablerow>
                    </TableHead>
                    <TableBody>
                      {Cust_Code_NDC.map((row, index) => (
                        <Tablerow
                          className="table-data-row schedule-body-row for-service-code-search-result"
                          key={`${index}Cust_Code_NDC`}
                          padding="6px 0 6px 18px"
                          lineHeight="20px"
                        >
                          <TableBodyCell align="left" className="b-l-none">
                            <div id={`HSPSC_NDCProc` + index}>
                              {row.NDC_procedures}/:::/${row.NDC_fee}.00
                            </div>
                          </TableBodyCell>
                          <TableBodyCell align="left" className="b-l-top">
                            {row.ndc_code === "" || row.ndc_code === null ? (
                              <div className="dis-blck for_autoComplete_bi   for_a_c_s_a_t_ndc">
                                <AiOutlineSearch
                                  style={{ position: "absolute" }}
                                />
                                <AutoComplete
                                  onClick={mouseClick}
                                  onMouseLeave={handleMouseLeave}
                                  id={`Cust_Code_NDC_Code` + index}
                                  className={`for-service-codes-bground search_width w-96 ${caretClr}`}
                                  placeholder="Find NDC Codes"
                                  onSelect={(e, data) => {
                                    Billing_NDC_update(
                                      row.id,
                                      index,
                                      "Custom_Code",
                                      data.title,
                                      "",
                                      ""
                                    );
                                  }}
                                  onChange={(e) =>
                                    handleSearchChangeNdcCustom(e)
                                  }
                                  options={
                                    showOptions &&
                                    NDC_Cust_CodeResult.map((x) => {
                                      return {
                                        label: x.title,
                                        value: x.title,
                                        title: x.title,
                                      };
                                    })
                                  }
                                  dropdownClassName={
                                    NDC_Cust_CodeSearchVal === "" &&
                                    "for_no_loading"
                                  }
                                  disabled={props.savebox}
                                  notFoundContent={
                                    showOptions
                                      ? fornoresults.length !== 0
                                        ? "loading..."
                                        : "No Results Found"
                                      : ""
                                  }
                                  value={NDC_Cust_CodeSearchVal}
                                />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="for_autoComplete_bi remove-marg-right  for_a_c_s_a_t_ndc"
                                  style={
                                    rowId2 === index
                                      ? { display: l }
                                      : { display: "none" }
                                  }
                                >
                                  <AiOutlineSearch
                                    style={{ position: "absolute" }}
                                  />
                                  <AutoComplete
                                    onClick={mouseClick}
                                    onMouseLeave={handleMouseLeave}
                                    id={`Cust_Code_NDC_Code` + index}
                                    className={`for-service-codes-bground search_width w-96 ${caretClr}`}
                                    placeholder="Find NDC Codes"
                                    disabled={props.savebox}
                                    onSelect={(e, data) => {
                                      Billing_NDC_update(
                                        row.id,
                                        index,
                                        "Custom_Code",
                                        data.title,
                                        "",
                                        ""
                                      );
                                    }}
                                    onChange={(e) =>
                                      handleSearchChangeNdcCustom(e)
                                    }
                                    options={
                                      showOptions &&
                                      NDC_Cust_CodeResult.map((x) => {
                                        return {
                                          label: x.title,
                                          value: x.title,
                                          title: x.title,
                                        };
                                      })
                                    }
                                    dropdownClassName={
                                      NDC_Cust_CodeSearchVal === "" &&
                                      "for_no_loading"
                                    }
                                    notFoundContent={
                                      showOptions
                                        ? fornoresults.length !== 0
                                          ? "loading..."
                                          : "No Results Found"
                                        : ""
                                    }
                                    value={NDC_Cust_CodeSearchVal}
                                  />
                                </div>

                                <div
                                  id={`Cust_Code_NDC_Code_ser` + index}
                                  style={
                                    rowId2 === index
                                      ? { display: l1 }
                                      : { display: "flex" }
                                  }
                                >
                                  <Form.Control
                                    className="w-221px m-r-5"
                                    disabled={true}
                                    name="ndccode"
                                    id={`Cust_Code_NDC_Code_Search` + index}
                                    type="text"
                                    value={
                                      row.ndc_code === null ? " " : row.ndc_code
                                    }
                                  />
                                  <span
                                    className={`serv-edit-ndc-icon ${
                                      props.savebox ? "disabled" : ""
                                    }`}
                                  >
                                    <BsPencilSquare
                                      className={`red-col m-t-10 m-l-5 curs serv-edit-ndc-icon ${
                                        props.savebox ? "disabled" : ""
                                      }`}
                                      onClick={() =>
                                        Cust_CodeSerachElementChange(
                                          index,
                                          row.ndc_code,
                                          row.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              </>
                            )}
                          </TableBodyCell>
                          <TableBodyCell align="left" className="b-l-top">
                            <Form.Group className="for-bor-btm-none-in-ndc">
                              <Form.Control
                                className="w-50px"
                                id={`Cust_Code_NDCQty` + index}
                                name="appduration"
                                type="number"
                                step="1"
                                defaultValue={row.NDC_qty}
                                disabled={
                                  row.ndc_code === null ||
                                  row.ndc_code === "" ||
                                  props.savebox
                                    ? true
                                    : false
                                }
                                onWheel={(e) => e.target.blur()}
                                onChange={() =>
                                  Billing_NDC_update(
                                    row.id,
                                    index,
                                    "Custom_Code",
                                    "",
                                    "",
                                    ""
                                  )
                                }
                              />
                            </Form.Group>
                          </TableBodyCell>

                          <TableBodyCell align="left" className="b-l-top">
                            <Select
                              suffixIcon={<SuffixIcon />}
                              className="w-150-mt-3 "
                              id={`Cust_Code_NDCUnit` + index}
                              placeholder="Select"
                              name="appoint_office"
                              value={
                                row.unit === null
                                  ? undefined
                                  : row.unit || undefined
                              }
                              disabled={
                                row.ndc_code === null ||
                                row.ndc_code === "" ||
                                props.savebox
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                Billing_NDC_update(
                                  row.id,
                                  index,
                                  "Custom_Code",
                                  "",
                                  e,
                                  "custndc"
                                )
                              }
                            >
                              <option
                                value="F2 (Internal Unit)"
                                key="F2 Custom_Code"
                              >
                                F2 (Internal Unit)
                              </option>
                              <option value="GR (Gram)" key="GR Custom_Code">
                                GR (Gram)
                              </option>
                              <option
                                value="MG (MilliGram)"
                                key="MG Custom_Code"
                              >
                                MG (MilliGram)
                              </option>
                              <option
                                value="ML (MilliLiter)"
                                key="ML Custom_Code"
                              >
                                ML (MilliLiter)
                              </option>
                              <option value="UN (Unit)" key="UN Custom_Code">
                                UN (Unit)
                              </option>
                            </Select>
                          </TableBodyCell>

                          <TableBodyCell
                            align="left"
                            className="b-l-top ribdel"
                          >
                            <Button
                              variant="light"
                              disabled={
                                row.invoice_id !== null || props.savebox
                                  ? true
                                  : false
                              }
                              className="m-l-11 for_serv_add_ndc_right"
                              onClick={() =>
                                Billing_NDC_Delete(row.id, "Custom_Code")
                              }
                            >
                              <RiDeleteBin5Line />
                            </Button>
                          </TableBodyCell>
                        </Tablerow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )} */}
          </>
          {/* // )} */}
        </>
      )}
      {open && <AppointmentDetailsAddCodes open={open} setOpen={setOpen} />}
      {upgrade && (
        <UpgradeRequired
          title="Manage your Services"
          description="Manage that the services and charges entered correlate with the actual guidelines."
          handlecloseUpgrade={handlecloseUpgrade}
          boxContent={
            <span>
              Available in
              <span className="upgradFont"> Premium</span> plan
            </span>
          }
        />
      )}
    </>
  );
};
export default memo(ServicesAddTable);
