import axiosInstance from "./axios/axios.js";

const logout = (userid) => {
  axiosInstance
    .post(`/vozo/updatelogout?uid=${userid}`)
    .then(() => {
      localStorage.clear();
      localStorage.setItem("logout", true);
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
      localStorage.clear();
      localStorage.setItem("logout", true);
      window.location.href = "/";
    });
};

export default logout;
