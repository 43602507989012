import { call, put } from "redux-saga/effects";
import {
  SetManagementDrugs,
  SetCPT4Codes,
  SetEditDrugs,
  SetDestroyedDrugs,
} from "../../StateManagement/Reducers/SettingState";
import {
  AddDrugDataApi,
  DestroyDrugDataApi,
  GetManagementDrugsApi,
  GetEditDrugsApi,
  GetDestroyedDrugsApi,
  GetCPT4CodesWorkApi,
  GetICD9CodesWorkApi,
  GetICD10CodesWorkApi,
  GetHCPCSCodesWorkApi,
} from "../Apis/SettingApi";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

export function* AddDrugDataWork({ payload }) {
  try {
    const Data = Encrypt_Value(payload, "vozo");
    const res = yield call(AddDrugDataApi, Data);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e);
  }
}
export function* DestroyDrugDataWork({ payload }) {
  try {
    const res = yield call(DestroyDrugDataApi, payload);
    if (res.status === 200) {
    }
  } catch (e) {
    console.log(e);
  }
}
export function* GetManagementDrugsWork({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(GetManagementDrugsApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetManagementDrugs(decryptResponse));
    }
  } catch (e) {
    yield put(SetManagementDrugs([]));
    console.log(e.message);
  }
}

export function* GetEditDrugsWork({ payload }) {
  try {
    const res = yield call(GetEditDrugsApi, payload);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetEditDrugs(decryptResponse));
    }
  } catch (e) {
    yield put(SetEditDrugs([]));
    console.log(e.message);
  }
}

export function* GetCPT4CodesWork({ payload }) {
  if (payload.name === "CPT4") {
    try {
      let data = "limit=10&offset=0&code_name=" + payload.text;
      const Data = Encrypt_Value(data, "vozo");
      const res = yield call(GetCPT4CodesWorkApi, Data);
      if (res.status === 200) {
        const decryptResponse = Decrypt_Value(res.data, "vozo");
        yield put(SetCPT4Codes(decryptResponse));
      }
    } catch (e) {
      yield put(SetCPT4Codes([]));
      console.log(e.message);
    }
  } else if (payload.name === "ICD9") {
    try {
      const res = yield call(GetICD9CodesWorkApi, payload.text);
      if (res.status === 200) {
        const decryptResponse = Decrypt_Value(res.data, "vozo");
        yield put(SetCPT4Codes(decryptResponse));
      }
    } catch (e) {
      yield put(SetCPT4Codes([]));
      console.log(e.message);
    }
  } else if (payload.name === "ICD10") {
    try {
      const res = yield call(GetICD10CodesWorkApi, payload.text);
      if (res.status === 200) {
        const decryptResponse = Decrypt_Value(res.data, "vozo");
        yield put(SetCPT4Codes(decryptResponse));
      }
    } catch (e) {
      yield put(SetCPT4Codes([]));
      console.log(e.message);
    }
  } else if (payload.name === "HCPCS") {
    try {
      const res = yield call(GetHCPCSCodesWorkApi, payload.text);
      if (res.status === 200) {
        const decryptResponse = Decrypt_Value(res.data, "vozo");
        yield put(SetCPT4Codes(decryptResponse));
      }
    } catch (e) {
      yield put(SetCPT4Codes([]));
      console.log(e.message);
    }
  }
}
export function* GetDestroyedDrugsWork({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(GetDestroyedDrugsApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetDestroyedDrugs(decryptResponse));
    }
  } catch (e) {
    yield put(SetDestroyedDrugs([]));
    console.log(e.message);
  }
}
