import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Div } from "../../StyledComponents";
import { Button1 } from "../setting/PatientPortalSettings/StyledComponents";
import { memo } from "react";
import Loader from "../popups/Loading";

const NewSource = ({ newOpen, setNewOpen, finalURL }) => {
  const [blogData, setBlogData] = useState([]);
  const [spinner, setspinner] = useState(true);

  const handleMore = (title) => {
    window.open(`https://www.vozohealth.com/blog/${title}`, "_blank");
  };

  useEffect(() => {
    axios
      .get(finalURL + "getNewFeatures.php")
      .then((res) => {
        setBlogData(res.data);
        setspinner(false);
      })
      .catch((err) => console.log(err.message));
  }, []);

  return spinner ? (
    <Loader fromWhere="whatsnew_newsource" />
  ) : (
    <Modal
      show={newOpen}
      onHide={() => setNewOpen(false)}
      className="topnav_newSource"
      transition={null}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            fontSize: "18px",
            marginTop: "0px",
            marginLeft: "0px",
            fontWeight: "600",
          }}
        >
          What's New
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {blogData.map((blogs) => (
          <>
            <Div display="flex" alignItems="flex-start" textAlign="left">
              <Div
                fontSize="18px"
                fontWeight="600"
                color="#2E2E2E"
                width="445px"
              >
                {blogs.title}
              </Div>
              <Div
                marginLeft="auto"
                fontSize="14px"
                fontWeight="600"
                paddingLeft="20px"
                paddingTop="5px"
                color="rgba(46, 46, 46, 0.5)"
              >
                {blogs.date}
              </Div>
            </Div>
            <Div fontSize="14px" fontWeight="400" paddingTop="16px">
              {blogs.content}
            </Div>
            <Button1
              type="button"
              fontSize="14px"
              fontWeight="400"
              color="#2C7BE5"
              padding="0px"
              paddingTop="8px"
              backgroundColor="#fff"
              onClick={() => handleMore(blogs.formatTitle)}
            >
              Learn more
            </Button1>
            <hr />
          </>
        ))}
      </Modal.Body>
    </Modal>
  );
};
export default memo(NewSource);
