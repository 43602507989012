import React, { useState, useEffect, memo } from "react";
import { Image, Modal, Form, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Decrypt_Value, Encrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { Select, Tooltip } from "antd";
import AlertMsgIcon from "../../assets/images/AlertMsgIcon.png";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FormLabel, SpanTag, Input } from "../patient/StyledComponent";
import CustomSuffixIcon from "./CustomSuffixIcon";
import { AutoComplete } from "antd";
import axios from "axios";
import {
  Div1 as Div,
  Span,
} from "../setting/PatientPortalSettings/StyledComponents";
import { fileurl } from "../../axios/url.js";

const CreateClient = ({ clientShow, dataFetch, ClientAdd, ClientFailed }) => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //
  const { Option } = Select;

  let history = useHistory();
  const [countryCodes, setCountryCodes] = useState([]);

  const handleClose = () => clientShow(false);
  const [externalID, setExternalID] = useState("");
  const [first_name, setfirstname] = useState("");
  const [last_name, setlastname] = useState("");
  const [email_id, setemailid] = useState("");
  const [phone_number, setphonenumber] = useState("");
  const [providerID, setproviderID] = useState("");
  const [providerlist, getproviderlist] = useState([]);
  const [fnError, setFnError] = useState(false);
  const [lnError, setLnError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [mailError1, setMailError1] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [provErr, setProvErr] = useState(false);
  const [mailErrMsg, setMailErrMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [idErrMsg, setIdErrMsg] = useState("");
  const [sameNumber, setSameNumber] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState("United States of America -( +1 )-US");
  const [codeCountry, setCodeCountry] = useState("+1");
  const [codeOnly, setCodeOnly] = useState("US");
  const [mobileErr, setMobileErr] = useState(false);
  const [countryErr, setCountryErr] = useState("");
  const [countrywithNumber, setcountrywithNumber] = useState(false);
  const [mailErrorVal, setmailErrorVal] = useState(false);
  const [mailErrorReq, setMailErrorReq] = useState(true);

  var storedNames = Decrypt_Value(localStorage.getItem("setroles"), "vozo");
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized = Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro = Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id = Decrypt_Value(localStorage.getItem("owner_id"), "vozo");

  //-------------------------------------------State and Variables End -------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //
  const mouseClick = () => {
    setShowOptions(!showOptions);
  };

  const changeCountryCode = (data) => {
    let values = data.split("-");

    const codevalues = values[1].replace(/[()+\s]/g, "");
    setSearch(data);
    setCodeCountry("+" + codevalues);
    setCodeOnly(values[2]);
    setCountryErr(false);
    // setcountrywithNumber(false);

    setPhoneError(false);
  };

  const handleSearchChange = (data) => {
    setSearch(data);
    setCodeCountry("");
    setCodeOnly("");
  };
  const checkPhone = (e, mode) => {
    e.preventDefault();
    const checkPhone = new Promise((resolve, reject) => {
      handlephonechange(resolve, reject);
    });

    checkPhone
      .then((res) => {
        if (res === "break") {
          throw new Error("number already used");
        }
      })
      .then(() => {
        if (mode === "more") {
          createSaveAddMore();
        } else {
          createSave();
        }
      })
      .catch((err) => {
        console.error("number already used");
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const digits = cleaned.slice(0, 10);

    let formattedNumber = "";
    for (let i = 0; i < digits.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += "-";
      }
      formattedNumber += digits[i];
    }
    return formattedNumber;
  };

  const createSave = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }
    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number ? codeCountry + " " + phone_number : "",
      country_code: codeOnly,
      external: externalID,
      prov_id: providerID,
    };
    let err = 0;
    if (first_name === "" || first_name === null) {
      setFnError(true);
      err = 1;
    }
    if (last_name === "" || last_name === null) {
      setLnError(true);
      err = 1;
    }
    // if (email_id === "") {
    //   setMailError(true);
    //   setMailErrMsg("Enter Email address");
    //   err = 1;
    // }
    // if (phone_number === "") {
    //   setPhoneError(true);
    //   err = 1;
    // }
    if (phoneError || countrywithNumber) {
      err = 1;
    }
    if (providerID === "" || providerID === null) {
      setProvErr(true);
      err = 1;
    }

    if (err > 0) {
      return;
    } else if (!idError) {
      setDisable(true);
      let Data_enc = Encrypt_Value(article, "vozo");
      axiosInstance
        .post("/vozo/patient", Data_enc)
        .then((response) => {
          if (typeof response.data === "number") {
            OnChangeClientAdd();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            clientShow();
            dataFetch();
            setDisable(false);
          }
          //  else if (response.data === "External ID is already present") {
          //   setIdError(true);
          //   setIdErrMsg("External ID is already present");
          //   setDisable(false);
          // }
          else {
            OnChangeClientFail();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
          }
        })
        .catch((err) => {
          setIdError(true);
          setIdErrMsg("External ID is already present");
          setDisable(false);
        });
    }
  };
  const handleInput = (event) => {
    const input = event.target.value;

    const phoneNumber = parsePhoneNumberFromString(input, codeOnly);

    if (phoneNumber) {
      const possible = phoneNumber.isPossible();
      const valid = phoneNumber.isValid();

      if (possible && valid) {
        const formattedNumber = phoneNumber.formatInternational();

        const [countryCode, ...restOfNumber] = formattedNumber.split(" ");
        const localNumber = restOfNumber.join(" ");
        setphonenumber(localNumber);
        setcountrywithNumber(false);
      } else {
        if (input === "") {
          setphonenumber(input);
          setcountrywithNumber(false);
        } else {
          setphonenumber(input);
          setcountrywithNumber(true);
        }
      }
    } else {
      if (input === "") {
        setphonenumber(input);
        setcountrywithNumber(false);
      } else {
        setphonenumber(input);
        setcountrywithNumber(true);
      }
    }

    // Example POST request for server-side validation
    // axios
    //   .post(actionUrl + "/interface/register/validatephonenumber.php", {
    //     phone_number: phoneNumber ? phoneNumber.number : input, // Send the raw or parsed number
    //     country_code: codeOnly,
    //     format: "INTERNATIONAL",
    //   })
    //   .then((res) => {
    //     // Handle the response as needed
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     // Handle the error as needed
    //   });

    setMobileErr(false);
    setCountryErr(false);
    setSameNumber(false);
  };

  const handlephonechange = (resolve, reject) => {
    if (phone_number !== "") {
      let a = {
        value: phone_number,
        pid: "none",
      };
      let data1 = Encrypt_Value(a, "vozo");
      axiosInstance
        .post("/vozonew/patphonecheck?type=patient", data1)
        .then((res) => {
          let data = Decrypt_Value(res.data, "vozo");
          if (data === "number not found") {
            setSameNumber(false);
            resolve("continue");
          } else if (
            data.id &&
            (data.id !== undefined || data.id !== "" || data.id !== null)
          ) {
            setSameNumber(true);
            setPhoneErrMsg("This phone number Already exist");
            resolve("break");
          } else {
            setSameNumber(false);
            resolve("continue");
          }
        })
        .catch((err) => {
          console.error(err);
          resolve("continue");
        });
    } else {
      resolve("continue");
    }
  };

  const createSaveAddMore = async () => {
    if (mailError === true) {
      return;
    }
    if (mailError1 === true) {
      return;
    }
    if (sameNumber === true) {
      return;
    }
    const article = {
      fname: first_name,
      lname: last_name,
      email: email_id,
      phone: phone_number ? codeCountry + " " + phone_number : "",
      external: externalID,
      prov_id: providerID,
    };

    if (first_name === "" || first_name === null) {
      setFnError(true);
      return;
    } else if (last_name === "" || last_name === null) {
      setLnError(true);
      return;
    }
    // else if (email_id === "") {
    //   setMailError(true);
    //   setMailErrMsg("Enter Email address");
    //   return;
    // } else if (phone_number === "") {
    //   setPhoneError(true);
    //   return;
    // }
    else if (providerID === "" || providerID === null) {
      setProvErr(true);
      return;
    } else if (phoneError || countrywithNumber) {
      return;
    } else {
      let Data_enc = Encrypt_Value(article, "vozo");
      setDisable(true);
      axiosInstance
        .post("/vozo/patient", Data_enc)
        .then((response) => {
          if (typeof response.data === "number") {
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
            clientShow();
            history.push("/patient/demographics/" + response.data);
          }
          // else if (response.data === "External ID is already present") {
          //   setIdError(true);
          //   setIdErrMsg("External ID is already present");
          //   setDisable(false);
          // }
          else {
            OnChangeClientFail();
            setfirstname("");
            setproviderID("");
            setlastname("");
            setemailid("");
            setphonenumber("");
            setExternalID("");
            setDisable(false);
          }
        })
        .catch((err) => {
          setIdError(true);
          setIdErrMsg("External ID is already present");
          setDisable(false);
        });
    }
  };

  const OnChangeClientAdd = () => {
    ClientAdd(true);
    setTimeout(() => {
      ClientAdd(false);
    }, 1000);
  };

  const OnChangeClientFail = () => {
    ClientFailed(true);
    setTimeout(() => {
      ClientFailed(false);
    }, 1000);
  };

  const phoneChange = (e) => {
    if (!isNaN(e.target.value)) {
      const value = e.target.value.replace(/\s/g, "");
      setphonenumber(value);
      setPhoneError(false);
      setSameNumber(false);
    }
  };

  const externalId = (e) => {
    if (!isNaN(e.target.value)) {
      setExternalID(e.target.value);
      setIdError(false);
      setIdErrMsg("");
    }
  };
  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const EmailCheck = (e) => {
    setemailid(e.target.value);

    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]{1,4}$/;
    if (regex.test(e.target.value)) {
      setmailErrorVal(false);
      setMailError(false);
      setMailErrorReq(false);
    } else {
      if (e.target.value === "") {
        setmailErrorVal(false);
        setMailError(false);
        setMailErrorReq(true);
      } else {
        setMailError(true);
        setmailErrorVal(true);
        setMailErrorReq(true);
        setMailErrMsg("Enter valid e-mail address");
      }
    }
  };

  const fnameSetVal = (e) => {
    setfirstname(e.target.value);
    setFnError(false);
  };

  const lnameSetVal = (e) => {
    setlastname(e.target.value);
    setLnError(false);
  };

  const provIdSetVal = (e) => {
    setproviderID(e);
    setProvErr(false);
  };

  // ------------------------------- Functions End----------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    axios
      .get(fileurl + "/interface/register/get_country_code_rpm.php")
      .then((res) => {
        setCountryCodes(res.data);
      })
      .catch((err) => {
        console.error(err);
        setCountryCodes([]);
      });
  }, []);

  useEffect(() => {
    if (codeCountry !== "" && phone_number !== "") {
      axios
        .post(fileurl + "/interface/register/validatephonenumber.php", {
          phone_number: phone_number,
          country_code: codeOnly,
        })

        .then((res) => {
          if (res.data === "invalid") {
            setMobileErr(true);
          } else {
            setMobileErr(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setMobileErr(false);
        });
    } else {
      setMobileErr(false);
      setPhoneError(false);
    }
  }, [phone_number, codeCountry]);
  useEffect(() => {
    let Data_frsh =
      "limit=40&authorized=" +
      authorized +
      "&main_pro=" +
      main_pro +
      "&owner_id=" +
      owner_id +
      "&userID=" +
      userID +
      "&pat_share=" +
      storedNames.pat_g;

    let Data_enc = Encrypt_Value(Data_frsh, "vozo");
    axiosInstance
      .get("/vozonew/provider?search=" + Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");
        getproviderlist(DecodeVal);
      })
      .catch((err) => {
        getproviderlist([]);
      });
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const handlechange = async () => {
      await timeout(1000);
      if (!isCancelled) {
        if (email_id !== "") {
          let data1 = Encrypt_Value(email_id, "vozo");
          axiosInstance
            .get("/vozonew/patmailcheck?search=" + data1)
            .then((res) => {
              let id = res.data.id;
              if (res.data === "Email not found") {
                setMailError1(false);
              } else if (id !== undefined || id !== "" || id !== null) {
                setMailError1(true);
                setMailErrMsg("This E-mail Already Exists");
              } else {
                setMailError1(false);
              }
            });
        }
      }
    };
    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [email_id]);
  useEffect(() => {
    setphonenumber("");
  }, [search]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <div id="client-create">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title className="table-top">Create Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>
                First Name<span style={{ color: "#E23D28" }}>*</span>
              </Form.Label>
              <Form.Control
                name="first_name"
                style={
                  fnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                value={first_name}
                onChange={(e) => fnameSetVal(e)}
                autoComplete="off"
              />
              {fnError ? (
                <p className="errormessage">Invalid first name</p>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>
                Last Name<span style={{ color: "#E23D28" }}>*</span>
              </Form.Label>
              <Form.Control
                name="last_name"
                value={last_name}
                style={
                  lnError
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={(e) => lnameSetVal(e)}
                autoComplete="off"
              />
              {lnError ? <p className="errormessage">Invalid last name</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="client-form-control">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                name="email_id"
                value={email_id}
                style={
                  mailErrorVal || mailError1
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : {}
                }
                onChange={EmailCheck}
                autoComplete="off"
              />
              {mailErrorVal || mailError1 ? (
                <p className="errormessage">{mailErrMsg}</p>
              ) : (
                ""
              )}
              {mailErrorReq && (
                <Div display="flex">
                  <Span marginright="5px" color="#F3282B">
                    Required for Patient Portal
                  </Span>
                  <Tooltip
                    className="for_tolltip_Phone_email"
                    title={
                      <Div radius="6px" width="320px">
                        <Div>
                          Without an email you might miss out functions like:
                        </Div>
                        <Div marginLeft="10px">
                          <Div>. Patient Portal</Div>
                          <Div>
                            . In Person & Telehealth Appointment Reminders
                          </Div>
                          <Div>. Email Notifications</Div>
                          <Div>. Billing Documents</Div>
                        </Div>
                      </Div>
                    }
                    overlayInnerStyle={{
                      backgroundColor: "#FFF", // Change background color
                      color: "#F3282B", // Change text color
                      width: "340px",
                      height: "136px",
                    }}
                  >
                    <Image
                      height="20px"
                      width="20px"
                      src={AlertMsgIcon}
                      alt="pic"
                      className="pat-image-right m-t-2"
                    />
                  </Tooltip>
                </Div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <FormLabel
                color="#2d3748"
                fontWeight="500"
                fontFamily="'Open Sans', sans-serif"
                marginBottom="4px"
              >
                Mobile
              </FormLabel>
              <Div display="flex" width="100%">
                {/* Autocomplete Start */}

                <Div
                  className="for_autoComplete_bi_Patient create-client-country-code pat-search-autocomplete for_contry_code"
                  width="75%"
                  height="34px"
                  marginTop="30px"
                >
                  {/* <AiOutlineSearch /> */}
                  <AutoComplete
                    onClick={mouseClick}
                    // onMouseLeave={handleMouseLeave}
                    placeholder="Country code"
                    onSelect={changeCountryCode}
                    onSearch={handleSearchChange}
                    popupClassName={search !== "" ? "" : "for_no_loading"}
                    notFoundContent="No results found"
                    className="top-nav-pat-search mobcountry_radius"
                    id="top-nav-pat-search"
                    options={countryCodes
                      .filter((item) => {
                        let result = search.toLowerCase();
                        let s = String(item.full_details);

                        // Check if cleaned search matches either full_name or number
                        return s.toLowerCase().includes(result);
                      })
                      .map((x, index) => {
                        return {
                          label: x.full_details,
                          value: x.full_details,
                          key: x.number + "_" + index,
                        };
                      })}
                    value={search}
                    style={
                      countryErr
                        ? {
                            border: "1px solid red",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            borderRight: "none",
                          }
                        : {}
                    }
                  />
                </Div>

                {/* Autocomplete End */}
                <Input
                  height="34px"
                  fontFamily="'Open Sans', sans-serif"
                  color="#718096"
                  border="1px solid #D8E0F0"
                  paddingLeft="12px"
                  width="80%"
                  placeholder="Enter your Number"
                  name="mobile"
                  btrr="4px"
                  bbrr="4px"
                  onChange={handleInput}
                  value={phone_number}
                  style={
                    mobileErr || countryErr || phoneError || countrywithNumber
                      ? { border: "1px solid red", borderTopRightRadius: "5px" }
                      : {}
                  }
                />
              </Div>
              {mobileErr || phoneError ? (
                <p className="errormessage">Invalid Mobile number</p>
              ) : (
                ""
              )}
              {countryErr ? (
                <p className="errormessage">Invalid country code</p>
              ) : (
                ""
              )}
              {countrywithNumber && !countryErr ? (
                <p className="errormessage">Invalid Mobile Number</p>
              ) : (
                ""
              )}

              {phone_number === "" && (
                <Div display="flex">
                  <Span marginright="5px" color="#F3282B">
                    Required for Patient Portal
                  </Span>
                  <Tooltip
                    className="for_tolltip_Phone_email"
                    title={
                      <Div radius="6px" width="320px">
                        <Div>
                          Without an phone number you might miss out functions
                          like:
                        </Div>
                        <Div marginLeft="10px">
                          <Div>
                            . In Person & Telehealth Appointment Reminders
                          </Div>
                        </Div>
                      </Div>
                    }
                    overlayInnerStyle={{
                      backgroundColor: "#FFF", // Change background color
                      color: "#F3282B", // Change text color
                      width: "340px",
                      height: "80px",
                    }}
                  >
                    <Image
                      height="20px"
                      width="20px"
                      src={AlertMsgIcon}
                      alt="pic"
                      className="pat-image-right m-t-2"
                    />
                  </Tooltip>
                </Div>
              )}

              {sameNumber ? <p className="errormessage">{phoneErrMsg}</p> : ""}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>External ID</Form.Label>
              <Form.Control
                id="pat_crt-inp_exId"
                name="external_id"
                value={externalID}
                // style={
                //   idError
                //     ? { border: "1px solid red", borderRadius: "5px" }
                //     : {}
                // }
                onChange={externalId}
                autoComplete="off"
              />
              {/* {idError ? <p className="errormessage">{idErrMsg}</p> : ""} */}
            </Form.Group>
            <Col>
              <Form.Group>
                <Form.Label>
                  Provider<span style={{ color: "#E23D28" }}>*</span>
                </Form.Label>

                <Select
                  placeholder="Select Provider"
                  className="w-100 creat_provider ant-sel-hei34 FutVideoShow staterelative"
                  id="new-app-office"
                  value={providerID ? providerID : undefined}
                  // disabled={!storedNames.pat_g}
                  onChange={(e) => provIdSetVal(e)}
                  suffixIcon={<CustomSuffixIcon />}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.title ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {providerlist.map((s, ind) => (
                    <Option
                      key={`29${ind}`}
                      value={s.id}
                      className={`crt_cln-pro_li${ind}`}
                      title={`${s.fname} ${s.lname}`}
                    >
                      {`${s.fname} ${s.lname}`}
                    </Option>
                  ))}
                </Select>
                {provErr ? (
                  <p className="errormessage">Please Select Provider</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer className="client-button">
          <Button
            className="close_active close-btn"
            variant="light"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="close_active fill-btn"
            variant="outline-secondary"
            onClick={(e) => checkPhone(e, "more")}
          >
            Save and fill more details
          </Button>
          <Button
            className="save_active save-btn"
            variant="primary"
            disabled={disable}
            onClick={(e) => checkPhone(e, "save")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(CreateClient);
