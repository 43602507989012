import React, { PureComponent } from "react";
import { Modal, Card, ListGroup } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../setting/PatientPortalSettings/StyledComponents";
import ReactToPrint from "react-to-print";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import moment from "moment";
import { globalFont } from "../utils/RepeatFunctions/Schedule";
import { fileTypes, generateFileURL } from "../../axios/url.js";

class SuperBillComp extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      patData: props.temp.patData,
      patAppt: props.temp.patAppt,
      currency: props.temp.currency,
      billed: props.temp.billed,
      patAmt: props.temp.patAmt,
      insAmt: props.temp.insAmt,
      adjmt: props.temp.adjmt,
      totalBal: props.temp.totalBal,
      insData: props.temp.insData
        ? props.temp.insData
        : {
            insurer: "",
            subscriber_name: "",
            group_number: "",
            member_id: "",
          },
      logo: props.temp.logo,
      footer: props.temp.footer,
      sign: props.temp.sign,
      text: props.temp.text,
      code: props.temp.icdCode,
      logoname: props.temp.logoname,
      dateFormat: localStorage.getItem("dateFormat"),
      dtFormattime: (() => {
        const dateFormat = localStorage.getItem("dateFormat");
        return dateFormat ? `${dateFormat} hh:mm:ss` : "hh:mm:ss";
      })(),
    };
    this.checkValue = this.checkValue.bind(this);
    this.checkValue1 = this.checkValue1.bind(this);
  }

  checkValue1 = (value) => {
    var add = "";
    if (value.pat_street !== undefined && value.pat_street !== "") {
      add = add + value.pat_street + ", ";
    }

    if (value.pat_city !== undefined && value.pat_city !== "") {
      add = add + value.pat_city + ", ";
    }

    if (
      value.pat_state !== undefined &&
      value.pat_state !== "" &&
      value.pat_state !== null
    ) {
      add = add + value.pat_state + "-";
    }
    if (value.pat_postal_code !== undefined && value.pat_postal_code !== "") {
      add = add + value.pat_postal_code;
    }

    return add;
  };

  checkValue = (value) => {
    var add = "";
    if (
      value.us_street !== undefined &&
      value.us_street !== "" &&
      value.us_street !== null
    ) {
      add = add + value.us_street + ", ";
    }

    if (
      value.us_city !== undefined &&
      value.us_city !== "" &&
      value.us_city !== null
    ) {
      add = add + value.us_city + ", ";
    }

    if (
      value.us_state !== undefined &&
      value.us_state !== "" &&
      value.us_state !== null
    ) {
      add = add + value.us_state + ", ";
    }
    if (
      value.us_postal_code !== undefined &&
      value.us_postal_code !== "" &&
      value.us_postal_code !== null
    ) {
      add = add + value.us_postal_code;
    }

    return add;
  };

  render() {
    return (
      <>
        <Modal.Body className="w-800 pad-0">
          <div className="p-t-20 p-r-20 p-l-20 p-b-20">
            {this.state.logo === "1" ? (
              <div>
                <img
                  src={generateFileURL(
                    fileTypes.profileimages,
                    this.state.logoname
                  )}
                  className="align-center invoice-logo in-logo-exm1"
                  height="60"
                  width="100"
                  alt="vozo-logo"
                ></img>
              </div>
            ) : null}
            <div className="sbill_pat_title m-t-0">Patient Receipt</div>

            <div className="sbill_pat_date m-t-0 ">
              Appointment Date:
              <span className="bill_pat_date ">
                {this.state.patData.appt_date
                  ? moment(this.state.patData.appt_date).format(
                      this.state.dateFormat
                    )
                  : ""}
              </span>
            </div>
          </div>

          <div className="card_container flex-cen-space  m-t-0 p-r-20 p-l-20 p-b-20 p-t-0">
            <div className="provider_card super-bill-card-head-style w-350">
              <Card>
                <Card.Header className="superbill-font-family">
                  Provider Information
                </Card.Header>
                <Card.Body className="p-t-20 p-r-20 p-l-20 p-b-20">
                  <div>
                    <div className="card_body_flex">
                      <div className="card_title_text super-bill-card-label">
                        Name
                      </div>
                      <div className="card_value_text superbill-font-family w-175">
                        {this.state.patData.pro_name != undefined
                          ? this.state.patData.pro_name
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text super-bill-card-label">
                        Service Code
                      </div>
                      <div className="card_value_text superbill-font-family w-175">
                        {this.state.patData.pid != undefined
                          ? this.state.patData.pid
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex address-scroll-hide">
                      <div className="card_title_text  super-bill-card-label">
                        Provider Address
                      </div>
                      <div className="card_value_text  superbill-font-family over-flow-hide-address">
                        {`${this.checkValue(this.state.patData)}`}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text  super-bill-card-label">
                        Office Phone
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.pro_phone != undefined
                          ? this.state.patData.pro_phone
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text  super-bill-card-label p-b-0">
                        Email
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.pro_email != undefined
                          ? this.state.patData.pro_email
                          : ""}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div className="patient_card super-bill-card-head-style w-350">
              <Card>
                <Card.Header className="superbill-font-family">
                  Patient Information
                </Card.Header>
                <Card.Body className="p-t-20 p-l-20 p-r-20 p-b-20">
                  <div>
                    <div className="card_body_flex ">
                      <div className="card_title_text  super-bill-card-label ">
                        Name
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.pat_name != undefined
                          ? this.state.patData.pat_name
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text  super-bill-card-label ">
                        Date of Birth
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.DOB != undefined
                          ? this.state.patData.DOB
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex address-scroll-hide">
                      <div className="card_title_text  super-bill-card-label w_42_">
                        Patient Address
                      </div>
                      <div className="card_value_text  superbill-font-family over-flow-hide-address">
                        {`${this.checkValue1(this.state.patData)}`}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text  super-bill-card-label ">
                        Patient Phone:
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.phone_cell != undefined
                          ? this.state.patData.phone_cell
                          : ""}
                      </div>
                    </div>
                    <div className="card_body_flex">
                      <div className="card_title_text  super-bill-card-label p-b-0 ">
                        Email
                      </div>
                      <div className="card_value_text  superbill-font-family w-175">
                        {this.state.patData.pat_email != undefined
                          ? this.state.patData.pat_email
                          : ""}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="card_container p-l-20 m-t-0">
            <div className="insurance_card w-350">
              <Card>
                <Card.Body className="p-t-20 p-l-20 p-r-20 p-b-20">
                  <div className="card_body_flex">
                    <div className="card_title_text  super-bill-card-label ">
                      Insurer
                    </div>
                    <div className="card_value_text  superbill-font-family w-175">
                      {this.state.insData.insurer != undefined
                        ? this.state.insData.insurer
                        : ""}
                    </div>
                  </div>
                  <div className="card_body_flex">
                    <div className="card_title_text  super-bill-card-label">
                      Subscriber
                    </div>
                    <div className="card_value_text  superbill-font-family w-175">
                      {this.state.insData.subscriber_name != undefined
                        ? this.state.insData.subscriber_name
                        : ""}
                    </div>
                  </div>
                  <div className="card_body_flex">
                    <div className="card_title_text  super-bill-card-label">
                      Group #
                    </div>
                    <div className="card_value_text  superbill-font-family w-175">
                      {this.state.insData.group_number != undefined
                        ? this.state.insData.group_number
                        : ""}
                    </div>
                  </div>
                  <div className="card_body_flex">
                    <div className="card_title_text  super-bill-card-label p-b-0">
                      Member #
                    </div>
                    <div className="card_value_text  superbill-font-family w-175">
                      {this.state.insData.member_id != undefined
                        ? this.state.insData.member_id
                        : ""}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="m-t-20 m-b-20 m-r-0 m-l-0">
            {this.state.code.length > 0 && (
              <Table size="small" aria-label="a dense table">
                <TableHead
                  className="pat-list-thead bt-none"
                  paddingLeft="16px"
                >
                  <TableRow className="sup-bill-head-color">
                    <TableHeadCell align="left" paddingLeft="12px">
                      Dx Pointer
                    </TableHeadCell>
                    <TableHeadCell align="left" paddingLeft="12px">
                      Diagnosis Code
                    </TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.code.map((item, ind) => (
                    <TableRow
                      key={`1j${ind}`}
                      className="row-border-super-bill"
                    >
                      <TableBodyCell align="left" paddingLeft="16px">
                        {item.diagnosis_pointer}
                      </TableBodyCell>
                      <TableBodyCell align="left" paddingLeft="16px">
                        {item.code}
                        {this.state.text == "1" ? `- ${item.code_text}` : ""}
                      </TableBodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
          <div className="suber_bill_table">
            <Table>
              <TableHead display="table-header-group" height="56px">
                <TableRow>
                  <TableHeadCell
                    padding="6px 24px 6px 16px !important"
                    background="#f8f8f8"
                    color="#000"
                    fontFamily={globalFont}
                  >
                    Date of Service
                  </TableHeadCell>
                  <TableHeadCell
                    align="left"
                    background="#f8f8f8"
                    color="#000"
                    padding="6px 24px 6px 16px !important"
                    fontFamily={globalFont}
                  >
                    Billing Code
                  </TableHeadCell>

                  <TableHeadCell
                    align="left"
                    background="#f8f8f8"
                    color="#000"
                    padding="6px 24px 6px 16px !important"
                    fontFamily={globalFont}
                  >
                    Dx Ptrs
                  </TableHeadCell>
                  <TableHeadCell
                    align="left"
                    background="#f8f8f8"
                    color="#000"
                    padding="6px 24px 6px 16px !important"
                    fontFamily={globalFont}
                  >
                    Qty
                  </TableHeadCell>
                  <TableHeadCell
                    align="left"
                    background="#f8f8f8"
                    color="#000"
                    padding="6px 24px 6px 16px !important"
                    fontFamily={globalFont}
                  >
                    Fee
                  </TableHeadCell>
                  <TableHeadCell
                    align="left"
                    background="#f8f8f8"
                    color="#000"
                    padding="6px 16px 6px 16px !important"
                    fontFamily={globalFont}
                  >
                    Total
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody display="table-row-group">
                {this.state.patAppt.map((item, ind) => (
                  <TableRow
                    key={`1j${ind}`}
                    height="56px"
                    className="row-border-super-bill"
                  >
                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 24px 6px 16px"
                      fontSize="13px"
                    >
                      {moment(item.appt).format(this.state.dtFormattime)}
                    </TableBodyCell>
                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 24px 6px 16px"
                      fontSize="13px"
                    >
                      {item.code}
                    </TableBodyCell>

                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 24px 6px 16px"
                      fontSize="13px"
                    >
                      {item.diagnosis_pointer}
                    </TableBodyCell>
                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 24px 6px 16px"
                      fontSize="13px"
                    >
                      {item.units}
                    </TableBodyCell>
                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 24px 6px 16px"
                      fontSize="13px"
                    >{`${this.state.currency} ${item.fee}`}</TableBodyCell>
                    <TableBodyCell
                      align="left"
                      bodered
                      color=" #2d3748 !important"
                      padding="6px 16px 6px 16px"
                      fontSize="13px "
                    >{`${this.state.currency} ${item.total}`}</TableBodyCell>
                  </TableRow>
                ))}
                <TableRow className="super-bill-no-border-btm sup-list-items">
                  <TableBodyCell
                    align="left"
                    colSpan={4}
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  ></TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >
                    Total Charges:
                  </TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >{`${this.state.currency} ${this.state.billed}`}</TableBodyCell>
                </TableRow>
                <TableRow className="super-bill-no-border-btm sup-list-items">
                  <TableBodyCell
                    align="left"
                    colSpan={4}
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  ></TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >
                    {" "}
                    Patient Paid:
                  </TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >{`${this.state.currency} ${this.state.patAmt}`}</TableBodyCell>
                </TableRow>
                <TableRow>
                  <TableBodyCell
                    align="left"
                    colSpan={4}
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  ></TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >
                    Insurance Paid:
                  </TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >{`${this.state.currency} ${this.state.insAmt}`}</TableBodyCell>
                </TableRow>
                <TableRow>
                  <TableBodyCell
                    align="left"
                    colSpan={4}
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  ></TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >
                    Total Adjustment:
                  </TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    fontSize="13px !important"
                  >{`${this.state.currency} ${this.state.adjmt}`}</TableBodyCell>
                </TableRow>
                <TableRow>
                  <TableBodyCell
                    align="left"
                    colSpan={4}
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    font-size="13px !important"
                  ></TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    font-size="13px !important"
                  >
                    Due
                  </TableBodyCell>
                  <TableBodyCell
                    align="left"
                    color=" #2d3748 !important"
                    padding="16px 24px 0!important"
                    font-size="13px !important"
                  >{`${this.state.currency} ${this.state.totalBal}`}</TableBodyCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          {this.state.sign === "1" ? (
            <div className="footer_info">
              <div className="bill_info sup-bill-foot-style">
                I authorize the release of any medical information necessary to
                process this claim.
              </div>
              <div className="patient_sign sup-bill-foot-style">
                <div className="p-r-20">
                  Date:
                  <span className="input_val_">
                    ______________________________
                  </span>
                </div>
                <div>
                  Patient Signature:
                  <span className="input_val_ ">
                    ______________________________
                  </span>
                </div>
              </div>
              <div className="provider_sign sup-bill-foot-style">
                <div className="p-r-20">
                  Date:
                  <span className="input_val_">
                    ______________________________
                  </span>
                </div>
                <div>
                  Provider Signature:
                  <span className="input_val_">
                    ______________________________
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="grey-color w-100 fs-16 text-cen p-t-20 p-r-20 p-l-20 p-b-20">
            {this.state.footer}
          </div>
        </Modal.Body>
      </>
    );
  }
}

class SuperBill extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      temp: props,
    };
  }

  PrintSuperBill() {
    document.getElementById("printbtn").click();
  }

  render() {
    return (
      <div id="PdfRender">
        <ReactToPrint
          trigger={() => (
            <button
              className="btn btn-primary hidden"
              target="_blank"
              id="printbtn"
            >
              Print this out!
            </button>
          )}
          content={() => this.componentRef}
        />
        <SuperBillComp
          temp={this.state.temp}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default SuperBill;
