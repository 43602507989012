import styled from "styled-components";
import { globalFont } from "../../utils/RepeatFunctions/Schedule";

export const Modal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: ${(p) => (p.top ? p.top : "50px")};
  bottom: 0;
  transition: all ease 10s;
  animation: fade 0.5s linear;
  background-color: rgba(203, 209, 217, 0.5);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: ${(p) => p.width && p.width};
  margin: 0.05rem auto !important;

  overflow-y: scroll;
  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TableHead = styled.th`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const font1 = styled.div`
  font-family: ${globalFont};
`;
export const font2 = styled.div`
  font-family: ${globalFont};
`;

export const Container = styled(font1)`
  height: 591px;
  width: 433px;
  background-color: #fff;
  border-color: #cbd1d9;
  border-radius: 6px;
  transition: opacity 0.7s linear, right 7s ease-out;
  position: relative;
  left: -1%;
  right: 0;
  top: 24px;
  bottom: 0;
`;
export const ModalHead = styled(Flex)`
  width: 433px;
  height: 56px;
  margin: 0px;
  background-repeat: no-repeat;
  background-position: right 12px top 20px;

  border-bottom: 1px solid #cbd5e0;
`;
export const Iconmargin = styled.div`
  margin-right: 16px;
`;
export const ModalTitle = styled(font1)`
  color: #132047;
  font-size: 20px;
  margin: 16px;
  line-height: 24.2px;
  font-weight: 500;
`;
export const ModalBody = styled.div`
  height: 288px;
`;
export const ModalHeading = styled(font2)`
  font-family: ${globalFont};
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #132047;
  height: 19px;
  padding: 16px;
`;
export const ModalHeadingContent = styled(Flex)`
  margin-right: 16px;
`;

export const Span = styled.span`
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-style: ${({ fontStyle }) => fontStyle && fontStyle};
  font-size: ${({ fontSize }) => fontSize && fontSize};
`;

export const ModalHeadingLeft = styled(font1)`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  width: 260px;
  height: 32px;
  margin-left: 16px;
  text-align: left;
  color: #000000;
`;
export const ModalHeadingRight = styled(Flex)``;

export const Button = styled.button`
  cursor: pointer;
  width: 14px;
  height: 14px;
  background-color: #557ff5;
  border: none;
  color: #f9f8f8;
  padding: 2px 2px;
  padding-bottom: 4px;
  line-height: 2px;
  margin-left: 7px;
  border-radius: 3px;
  .btnnotallowed {
    cursor: not-allowed !important;
    background-color: #cccccc;
    color: #666666;
  }
`;
export const Content = styled.table`
  margin: 16px;
  width: 401px;

  background-color: #f9f8f8;
  border-radius: 6px;
`;
export const PaynowButton = styled(Button)`
  width: 198px;
  height: 37px;
  text-align: center;
  border-radius: 6px;
  font-family: ${globalFont};
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
`;

export const TableheadCell = styled.td`
  color: #777777;
  font-family: ${globalFont};
  font-weight: 500;
  font-size: 12px;
  line-height: 15.62px;
  // padding: 12px 16px 16px 16px;
  text-align: left;
`;
export const TableData = styled(TableheadCell)`
  //padding: 0px 16px 22px 16px;
  color: #1a1a1a;
  font-family: ${globalFont};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;
export const TableheadM = styled(TableheadCell)`
  // padding: 12px 0px 16px 16px;
`;

export const TableRow = styled.tr``;
export const NumberHolder = styled.div`
  height: 16px;
  width: 22px;
  background-color: #fff;
  color: #000;
  border: #cbd1d9;
  line-height: 0%;
  margin-left: 7px;
  font-size: 11px;
  padding-top: 8px;
  border: 1px solid #f9f8f8;
`;
export const Icon = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 16px;
`;
export const Modclosebtn = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  padding-bottom: 1px;
  line-height: 1.15px;
  margin-right: 16px;
`;

export const Errmsg = styled.p`
  color: red;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  width: 110%;
`;

export const Tablecontainer = styled.div`
  background: ${(p) => p.background && p.background};
`;

export const Display = styled.div`
  display: ${(p) => p.display && p.display};
`;
