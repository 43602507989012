import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";

const user_id =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozo");
let DBHost =
  localStorage.getItem("DBHost") &&
  Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
let subdomain = window.location.hostname.split(".")[0];
const fileurl =
  subdomain !== "localhost"
    ? process.env.REACT_APP_BACKEND_FILE_ACCESS.replace("oemr", subdomain)
    : process.env.REACT_APP_BACKEND_FILE_ACCESS;
const defaultSubdomains = ["localhost", "mumbai", "production"];
DBHost = defaultSubdomains.includes(subdomain) ? "default" : subdomain;

let AccessMailUrl;
if (window.location.href.includes("localhost")) {
  AccessMailUrl = "http://localhost:8000/InstantSignIn/Old";
} else {
  let firstDot = window.location.hostname.split(".");
  AccessMailUrl = `https://${firstDot[0]}.clientx.me/InstantSignIn/Old`;
}
const apiURL =
  subdomain !== "localhost"
    ? process.env.REACT_APP_BACKEND_URL.replace("oemr", subdomain)
    : process.env.REACT_APP_BACKEND_URL;

const fileTypes = {
  profileimages: 1,
  educationFiles: 2,
  insuranceImg: 3,
  labDocuments: 4,
  messageImages: 5,
  audio: 6,
  incomingFax: 7,
  outgoingFax: 8,
  services: 9,
  eobDoc: 10,
};

const generateFileURL = (file_type, file_name) => {
  let token = localStorage.getItem("csrfToken");
  return `${fileurl}/customapi/image.php?file=${file_name}&file_site=${DBHost}&file_type=${file_type}&id=${token}&type=${user_id}`;
};
const ProfileFileURL = `${fileurl}/sites/${DBHost}/documentimg/`;
const MessageFileUrl = `${fileurl}/sites/${DBHost}/MessageImages/`;
const EobFileURL = `${fileurl}/sites/${DBHost}/documents/eob/`;
const IncomingURL = `${fileurl}/sites/${DBHost}/Faxdocuments/Incoming/`;
const OutgoingURL = `${fileurl}/sites/${DBHost}/Faxdocuments/Outgoing/`;
const ServDocURL = `${fileurl}/sites/${DBHost}/documentimg/`;
const InsuranceFileURL = `${fileurl}/sites/${DBHost}/insimage/`;
const EducationHWURL = `${fileurl}/sites/${DBHost}/Education_Files/`;
const EobfileUrl = `${fileurl}/sites/${DBHost}/Eobdocuments/`;
const WhatsNewUrl = `${fileurl}/interface/register/`;

export {
  fileurl,
  ProfileFileURL,
  ServDocURL,
  IncomingURL,
  OutgoingURL,
  DBHost,
  apiURL,
  AccessMailUrl,
  fileTypes,
  generateFileURL,
  EobFileURL,
  subdomain,
  InsuranceFileURL,
  EducationHWURL,
  EobfileUrl,
  WhatsNewUrl,
  MessageFileUrl,
};
