import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react";
import JoditEditor from "jodit-react";
import { Menu, Dropdown, Button, Select, Input } from "antd";
import { Div, Span } from "../billing/CardTransactions/styles";
import { Modal } from "react-bootstrap";
import Signature from "../../assets/images/Signature.png";
import { DownOutlined } from "@ant-design/icons";
import template1 from "../../assets/images/template1.png";
import template2 from "../../assets/images/template2.png";
import template3 from "../../assets/images/template3.png";
import template4 from "../../assets/images/template4.png";
import template5 from "../../assets/images/template5.png";
import template6 from "../../assets/images/template6.png";
import template7 from "../../assets/images/template7.png";
import template8 from "../../assets/images/template8.png";
import template9 from "../../assets/images/template9.png";
import template10 from "../../assets/images/template10.png";
import template11 from "../../assets/images/template11.png";
import datachip1 from "../../assets/images/datachip1.png";
import datachip2 from "../../assets/images/datachip2.png";
import datachip3 from "../../assets/images/datachip3.png";
import { Prev } from "react-bootstrap/esm/PageItem";

function EditDocument(
  { ExtraContent, data, fromWhere, Mode, setContentToParent },
  ref
) {
  const [content, setContent] = useState(data || ""); // Initialize with an empty string if `data` is undefined
  const [inputbox, setInputbox] = useState(1);
  const editorRef = useRef(null);
  const [DropDownPopup, setDropDownPopup] = useState(false);
  const [Multiple, setMultiple] = useState(false);

  const { Option } = Select;

  const [options, setOptions] = useState([
    Multiple ? "Please Select" : "Please Select One",
  ]);
  const [newOption, setNewOption] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  //-----------------------------Functions Start------------------------------------------------------ //

  const handleAddOption = () => {
    if (newOption.trim()) {
      setOptions([...options, newOption]);
      setNewOption("");
      setIsModalVisible(false);
    }
    setIsModalVisible(true);
  };
  const handleOkDropdown = () => {
    let test;
    if (Multiple) {
      test = `<Select class="sizedinputmultiselect" disabled multiple value=""  name="${inputbox}" data-input-id="${inputbox}" id="${inputbox}">
      ${options
        .map((option) => `<Option value="${option}">${option}</Option>`)
        .join("")}
    </Select>`;
    } else {
      test = `<Select class="sizedinputselect" disabled value="" name="${inputbox}" data-input-id="${inputbox}" id="${inputbox}">
  ${options
    .map((option) => `<Option value="${option}">${option}</Option>`)
    .join("")}
</Select>`;
    }
    setInputbox((prev) => prev + 1);
    // setContent((prevContent) => (prevContent ? prevContent + test : test));
    setContent((prevContent) => {
      if (!prevContent) {
        return test; // If empty, just add the new input
      }

      // Check if prevContent ends with </p>
      if (prevContent.endsWith("</p>")) {
        return prevContent.replace(/<\/p>$/, test + "</p>");
      }

      return prevContent + test; // If no </p> at the end, just append normally
    });
    setDropDownPopup(false);
    setNewOption("");
    setOptions(
      Multiple ? ["Hold Ctrl for Choose Multiple"] : ["Please Select One"]
    );
  };

  const setEditorData = (e) => {
    if (e === "TextInput") {
      const test = `<input type="text" disabled class="inputbox" data-input-id=${inputbox} value=""></input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    }
    if (e === "smTextInput") {
      const test = `<input type="text" disabled class="sminput" data-input-id=${inputbox} value=""></input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    }
    if (e === "sizedTextInput:120px") {
      const test = `<input type="text" disabled class="sizedinput" data-input-id=${inputbox} value=""></input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    }
    if (e === "DatePicker") {
      const test = `<input type="date" disabled data-input-id="${inputbox}" class="datepicker" value=""></input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    }
    if (e === "Radio button") {
      const test = `<input type="radio" disabled class="radioboxGroup" data-input-id=${inputbox} value="" name="group${inputbox}"></input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    } else if (e === "ynRadioGroup") {
      const test = `<input disabled type="radio" class="radiobox" data-input-id="${inputbox}" value="Yes" name="${inputbox}ynRadioGroup" 
         >Yes</input>
      <input type="radio" disabled class="radiobox" data-input-id="${inputbox}" value="No" name="${inputbox}ynRadioGroup" >No</input>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    } else if (e === "Dropdown") {
      setDropDownPopup(true);
      setMultiple(false);
    } else if (e === "singleSelect") {
      setDropDownPopup(true);
      setMultiple(false);
      setOptions(["Please Select One"]);
    } else if (e === "multiSelect") {
      setDropDownPopup(true);
      setMultiple(true);
      setOptions(["Hold Ctrl for Choose Multiple"]);
    } else if (e === "Signature") {
      const test = `<img disabled  height="24px" width="24px" src=${Signature} class="signature" data-input-id=${inputbox} value=""></img>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    } else if (e === "Linearline") {
      const test = `<hr>`;
      setInputbox((prev) => prev + 1);
      setContent((prevContent) => {
        if (!prevContent) {
          return test; // If empty, just add the new input
        }

        // Check if prevContent ends with </p>
        if (prevContent.endsWith("</p>")) {
          return prevContent.replace(/<\/p>$/, test + "</p>");
        }

        return prevContent + test; // If no </p> at the end, just append normally
      });
    }
  };

  const datachips1 = (
    <Menu>
      <Menu.Item
        key="Past Appointment"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{past_appointment}"
              : "{past_appointment}"
          );
          // setContent((prevContent) => (prevContent ? prevContent + test : test));
        }}
      >
        Past Appointment
      </Menu.Item>
      <Menu.Item
        key="Upcoming Appointment"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{upcoming_appointment}"
              : "{upcoming_appointment}"
          );
        }}
      >
        Upcoming Appointment
      </Menu.Item>
    </Menu>
  );
  const datachips2 = (
    <Menu>
      <Menu.Item
        key="First Name"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{patient_first_name}"
              : "{patient_first_name}"
          );
        }}
      >
        First Name
      </Menu.Item>
      <Menu.Item
        key="Last Name"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{patient_last_name}"
              : "{patient_last_name}"
          );
        }}
      >
        Last Name
      </Menu.Item>
      <Menu.Item
        key="Gender"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{patient_gender}" : "{patient_gender}"
          );
        }}
      >
        Gender
      </Menu.Item>
      <Menu.Item
        key="DOB"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{patient_dob}" : "{patient_dob}"
          );
        }}
      >
        DOB
      </Menu.Item>
      <Menu.Item
        key="Address"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{patient_address}"
              : "{patient_address}"
          );
        }}
      >
        Address
      </Menu.Item>
      <Menu.Item
        key="Email"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{patient_email}" : "{patient_email}"
          );
        }}
      >
        Email
      </Menu.Item>
      <Menu.Item
        key="Phone Number"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{patient_phone_number}"
              : "{patient_phone_number}"
          );
        }}
      >
        Phone Number
      </Menu.Item>
    </Menu>
  );
  const datachips3 = (
    <Menu>
      <Menu.Item
        key="Provider Name"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{provider_name}" : "{provider_name}"
          );
        }}
      >
        Provider Name
      </Menu.Item>
      <Menu.Item
        key="Provider NPI"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{provider_npi}" : "{provider_npi}"
          );
        }}
      >
        Provider NPI
      </Menu.Item>
      <Menu.Item
        key="Address"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{provider_address}"
              : "{provider_address}"
          );
        }}
      >
        Address
      </Menu.Item>
      <Menu.Item
        key="Email"
        onClick={() => {
          setContent((prevContent) =>
            prevContent ? prevContent + "{provider_email}" : "{provider_email}"
          );
        }}
      >
        Email
      </Menu.Item>
      <Menu.Item
        key="Phone Number"
        onClick={() => {
          setContent((prevContent) =>
            prevContent
              ? prevContent + "{provider_phone_number}"
              : "{provider_phone_number}"
          );
        }}
      >
        Phone Number
      </Menu.Item>
    </Menu>
  );

  const mainMenu1 = (
    <Menu>
      <Menu.SubMenu
        key="Appointment"
        title={
          <>
            <img
              className="template_img_dropdown_datachips"
              src={datachip1}
              // alt="datachip icon"
              height="14px"
              width="14px"
            />
            <span> Appointment</span>
          </>
        }
        popupOffset={[0, 0]}
      >
        {datachips1}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="Patient"
        title={
          <>
            <img
              className="template_img_dropdown_datachips"
              src={datachip2}
              // alt="datachip icon"
              height="14px"
              width="14px"
            />
            <span> Patient</span>
          </>
        }
        popupOffset={[0, 0]}
      >
        {datachips2}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="Provider"
        title={
          <>
            <img
              className="template_img_dropdown_datachips"
              src={datachip3}
              // alt="datachip icon"
              height="14px"
              width="14px"
            />
            <span> Provider</span>
          </>
        }
        popupOffset={[0, 0]}
      >
        {datachips3}
      </Menu.SubMenu>
    </Menu>
  );

  const mainMenu = (
    <Menu>
      <Menu.Item
        key="Text Input"
        className="template_dropdown_flex"
        onClick={() => setEditorData("TextInput")}
      >
        <img
          className="template_img_dropdown"
          src={template1}
          height="14px"
          width="14px"
        />
        <span>Text Input</span>
      </Menu.Item>
      <Menu.Item
        key="Short Text Input"
        className="template_dropdown_flex"
        onClick={() => setEditorData("smTextInput")}
      >
        <img
          className="template_img_dropdown"
          src={template2}
          height="14px"
          width="14px"
        />
        <span>Short Text Input</span>
      </Menu.Item>
      <Menu.Item
        key="Long Text Input"
        className="template_dropdown_flex"
        onClick={() => setEditorData("sizedTextInput:120px")}
      >
        <img
          className="template_img_dropdown"
          src={template3}
          height="14px"
          width="14px"
        />
        <span>Long Text Input</span>
      </Menu.Item>
      <Menu.Item
        key="Date picker"
        className="template_dropdown_flex"
        onClick={() => setEditorData("DatePicker")}
      >
        <img
          className="template_img_dropdown"
          src={template4}
          height="14px"
          width="14px"
        />
        <span>Date picker</span>
      </Menu.Item>
      <Menu.Item
        key="Dropdown"
        className="template_dropdown_flex"
        onClick={() => setEditorData("Dropdown")}
      >
        <img
          className="template_img_dropdown"
          src={template5}
          height="14px"
          width="14px"
        />
        <span>Dropdown</span>
      </Menu.Item>
      <Menu.Item
        key="Single Select"
        className="template_dropdown_flex"
        onClick={() => setEditorData("singleSelect")}
      >
        <img
          className="template_img_dropdown"
          src={template6}
          height="14px"
          width="14px"
        />
        <span>Single Select</span>
      </Menu.Item>
      <Menu.Item
        key="Multi Select"
        className="template_dropdown_flex"
        onClick={() => setEditorData("multiSelect")}
      >
        <img
          className="template_img_dropdown"
          src={template7}
          height="14px"
          width="14px"
        />
        <span>Multi Select</span>
      </Menu.Item>
      <Menu.Item
        key="Yes / No"
        className="template_dropdown_flex"
        onClick={() => setEditorData("ynRadioGroup")}
      >
        <img
          className="template_img_dropdown"
          src={template8}
          height="14px"
          width="14px"
        />
        <span>Yes / No</span>
      </Menu.Item>
      <Menu.Item
        key="Radio button"
        className="template_dropdown_flex"
        onClick={() => setEditorData("Radio button")}
      >
        <img
          className="template_img_dropdown"
          src={template9}
          height="14px"
          width="14px"
        />
        <span>Radio button</span>
      </Menu.Item>
      <Menu.Item
        key="Signature"
        className="template_dropdown_flex"
        onClick={() => setEditorData("Signature")}
      >
        <img
          className="template_img_dropdown"
          src={template10}
          height="14px"
          width="14px"
        />
        <span>Signature</span>
      </Menu.Item>
      <Menu.Item
        key="Linear line"
        className="template_dropdown_flex"
        onClick={() => setEditorData("Linearline")}
      >
        <img
          className="template_img_dropdown"
          src={template11}
          height="14px"
          width="14px"
        />
        <span>Linear line</span>
      </Menu.Item>
    </Menu>
  );
  const handleBlur = (newContent) => {
    setContent(newContent);
  };
  useImperativeHandle(
    ref,
    () => ({
      setFromOutside() {
        if (typeof content === "string") {
          return content;
        } else {
          return data;
        }
      },
    }),
    [content]
  );
  const disabledremove = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const inputElements = doc.querySelectorAll("input, select,img");
    inputElements.forEach((input) => {
      input.removeAttribute("disabled");
    });

    return doc.documentElement.outerHTML;
  };

  const addDisabled = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const inputElements = doc.querySelectorAll("input, select, img");

    inputElements.forEach((element) => {
      element.setAttribute("disabled", ""); // Set disabled normally
    });

    // Convert back to string and remove `disabled=""` with regex
    let modifiedHTML = doc.documentElement.outerHTML.replace(
      /\sdisabled=""/g,
      " disabled"
    );

    return modifiedHTML;
  };

  //-----------------------------Functions End------------------------------------------------------ //
  //-----------------------------UseEffect Start--------------------------------------------------- //

  useEffect(() => {
    setContent(data);
  }, [data]);
  useEffect(() => {
    if (ExtraContent !== "") {
      setContent((prevContent) =>
        prevContent ? prevContent + ExtraContent : ExtraContent
      );
    }
  }, [ExtraContent]);
  const handleChange = (newContent) => {};

  //-----------------------------UseEffect End---------------------------------------------------- //

  return (
    <>
      {Mode === "Editor" ? (
        <div className="jodit_buttons" contenteditable="true">
          <div
            className={
              fromWhere === "Appointment"
                ? "for_jodit_inputs"
                : fromWhere === "Notes"
                ? "for_jodit_inputs_notes"
                : "for_jodit_inputs_left"
            }
          >
            <Dropdown
              overlay={mainMenu}
              trigger={["hover"]}
              placement="bottomLeft"
            >
              <Button>
                Input Fields <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown
              overlay={mainMenu1}
              trigger={["hover"]}
              placement="bottomLeft"
            >
              <Button>
                Data Chips <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <JoditEditor
            ref={editorRef}
            value={content}
            config={{
              buttons: [
                "undo",
                "redo",
                "|",

                "bold",
                "italic",
                "underline",
                "|",
                "ul",
                "ol",
                "|",
                "font",
                "fontsize",

                "spellcheck",
                "|",
                "indent",
                "lineHeight",
                "align",
                "|",
                "eraser",
                "fullsize",
                "|",
                // "brush",
                // "insert",
                "image",
                "table",
              ],
              toolbarAdaptive: false, // Disable adaptive toolbar to prevent wrapping
              toolbarSticky: true, // Keeps toolbar fixed at the top
              askBeforePasteHTML: false,
              height: 500,
              width: "100%",
              readonly: false,
            }}
            tabIndex={1}
            onBlur={handleBlur}
            onChange={handleChange} // Pass the handleChange function
          />
        </div>
      ) : (
        <Div padding="20px 20px 20px 30px"></Div>
      )}
      <Modal
        className=" popup_centre forSelect_dropdown_notes"
        centered
        show={DropDownPopup}
        onHide={() => {
          setDropDownPopup(false);
        }}
      >
        <Modal.Body
          className="ed-doc-mod-body "
          id="cnst_frm-mod_bdy"
          style={{ padding: 0 }}
        >
          <Div width={300}>
            <Div margin="0px 0px 0px 0px" dis="flex">
              <Input
                value={newOption}
                width={200}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Enter new option"
              />
              <Button
                className="save_active fortextColor"
                variant="primary"
                onClick={handleAddOption}
              >
                Add
              </Button>
            </Div>
            <Select
              open={isModalVisible}
              style={{ width: "100%", marginTop: "10px" }}
              placeholder="Select an option"
              onClick={() => setIsModalVisible(!isModalVisible)}
              // dropdownRender={(menu) => (
              //   <>
              //     {menu}
              //     <div
              //       style={{
              //         display: "flex",
              //         justifyContent: "space-between",
              //         padding: "8px",
              //         cursor: "pointer",
              //       }}
              //       onClick={() => setIsModalVisible(true)}
              //     >
              //       <span style={{ color: "#1890ff" }}>+ Add Option</span>
              //     </div>
              //   </>
              // )}
            >
              {options.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Div>
        </Modal.Body>

        <Modal.Footer className="for_select_footer_notes">
          <Button
            className="close_active"
            variant="light"
            onClick={() => {
              setDropDownPopup(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleOkDropdown}
            className="save_active fortextColor"
            variant="primary"
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        className=" popup_centre"
        centered
        title="Add New Option"
        show={DropDownPopup}
        onHide={() => {
          setIsModalVisible(false);
        }}
        okText="Add"
      >
        <Div margin="-30px 0px 0px 0px" dis="flex">
          <Input
            value={newOption}
            width={200}
            onChange={(e) => setNewOption(e.target.value)}
            placeholder="Enter new option"
          />
          <Button
            className="save_active"
            variant="primary"
            onClick={handleAddOption}
          >
            Add
          </Button>
        </Div>
      </Modal> */}
    </>
  );
}

export default memo(forwardRef(EditDocument));
